<template>
  <div class="favq">
    <heand :tagnum="tagnum"></heand>
    <div class="c-top" ref="c-top">
      <li
        :class="$route.name == 'favq' ? 'active' : ''"
        @click="$router.push({ name: 'favq' })"
      >
        骨干企业
      </li>
      <li
        :class="$route.name == 'chuangxin' ? 'active' : ''"
        @click="$router.push({ name: 'chuangxin' })"
      >
        创新资源
      </li>
      <li
        :class="$route.name == 'zaiti' ? 'active' : ''"
        @click="$router.push({ name: 'zaiti' })"
      >
        产业载体
      </li>
      <li
        :class="$route.name == 'zhengce' ? 'active' : ''"
        @click="$router.push({ name: 'zhengce' })"
      >
        产业政策
      </li>
    </div>
    <div class="enterprise clearfix">
      <div class="left">
        <navleft v-on:cateid="getcateid" />
      </div>
      <div class="right">
        <div class="ipt">
          <li>
            机构类型：
            <el-select
              class="iipp"
              v-model="valuetype"
              placeholder="高等院校"
              @change="labelchange($event, optionstype, 1)"
            >
              <el-option
                v-for="(item, index) in optionstype"
                :key="index"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
            <el-select
              class="iipp"
              v-model="valueaptitude"
              placeholder="请选择"
              @change="labelchange($event, optionsaptitude, 2)"
              v-if="keyanjigoushow"
            >
              <el-option
                v-for="item in optionsaptitude"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </li>
        </div>
        <div class="tupu">
          <!-- <li>
            <span
              @click="tpshownum = 1"
              :class="tpshownum == 1 ? 'spannth' : ''"
            >
              <img
                v-if="tpshownum == 1"
                src="../../assets/industr/tupu.png"
                alt
              />
              <img
                v-if="tpshownum == 2"
                src="../../assets/industr/jusetupu.png"
                alt
              />图谱模式
            </span>
            <span
              @click="tpshownum = 2"
              v-if="secondshow"
              :class="tpshownum == 2 ? 'spannth' : ''"
            >
              <img
                v-if="tpshownum == 1"
                src="../../assets/industr/lianjie.png"
                alt
              />
              <img
                v-if="tpshownum == 2"
                src="../../assets/industr/baiseliantu.png"
                alt
              />链图模式
            </span> -->
            <!-- <span class="span" v-if="secondshow" @click="clickinducollect">
              <img
                v-if="collect == 2"
                src="../../assets/industr/ubcj.png"
                alt
              />
              <small v-if="collect == 2">收藏</small>
              <img
                v-if="collect == 1"
                src="../../assets/industr/shoucang2.png"
                alt
              />
              <small v-if="collect == 1">取消收藏</small>
            </span> -->
          <!-- </li> -->
          <!-- <div class="t-Center" v-show="tpshownum == 1">
            <div style="width: 100%; height: 100%">
              <RelationGraph
                ref="seeksRelationGraph"
                :options="graphOptions"
                :on-node-click="onNodeClick"
                style="width: 100%; height: 100%"
              />
            </div>
          </div>
          <div class="lian_tubox" v-show="tpshownum == 2">
            <ul class="lian_tu_top">
              <li style="width: 33%">
                <img src="../../assets/industr/arrow-left.png" alt />
                上游（{{ upstream.count }}）
                <img src="../../assets/industr/arrow-right.png" alt />
              </li>
              <li style="width: 33%">
                <img src="../../assets/industr/arrow-left.png" alt />
                中游（{{ midstream.count }}）
                <img src="../../assets/industr/arrow-right.png" alt />
              </li>
              <li style="width: 33%">
                <img src="../../assets/industr/arrow-left.png" alt />
                下游（{{ downstream.count }}）
                <img src="../../assets/industr/arrow-right.png" alt />
              </li>
            </ul>
            <el-collapse accordion>
              <el-collapse-item
                v-for="(item, index) in upstream.subNodeList"
                :key="index"
              >
                <template slot="title"
                  ><div @click="getproduct(item.node)">
                    {{ item.node }}({{ item.count }})
                  </div></template
                >
                <ul>
                  <li
                    v-for="(itemx, indexx) in item.subNodeList"
                    :key="indexx"
                    @click="getproduct(itemx.node)"
                  >
                    {{ itemx.node }}({{ itemx.count }})
                    <i
                      class="el-icon-arrow-right"
                      v-if="itemx.subNodeList.length > 0"
                      :class="subNodeListshow == itemx.node ? 'isactive' : ''"
                    ></i>
                    <ul
                      v-if="
                        itemx.subNodeList.length > 0 &&
                        subNodeListshow == itemx.node
                      "
                    >
                      <li
                        v-for="(items, indess) in itemx.subNodeList"
                        :key="indess"
                        @click.stop="getproductx(items.node)"
                      >
                        {{ items.node }}
                      </li>
                    </ul>
                  </li>
                </ul>
              </el-collapse-item>
            </el-collapse>
            <el-collapse accordion>
              <el-collapse-item
                v-for="(item, index) in midstream.subNodeList"
                :key="index"
              >
                <template slot="title" @click="getproduct(item.node)"
                  ><div @click="getproduct(item.node)">
                    {{ item.node }}({{ item.count }})
                  </div></template
                >
                <ul>
                  <li
                    v-for="(itemx, indexx) in item.subNodeList"
                    :key="indexx"
                    @click="getproduct(itemx.node)"
                  >
                    {{ itemx.node }}({{ itemx.count }})
                    <i
                      class="el-icon-arrow-right"
                      v-if="itemx.subNodeList.length > 0"
                      :class="subNodeListshow == itemx.node ? 'isactive' : ''"
                    ></i>
                    <ul
                      v-if="
                        itemx.subNodeList.length > 0 &&
                        subNodeListshow == itemx.node
                      "
                    >
                      <li
                        v-for="(items, indess) in itemx.subNodeList"
                        :key="indess"
                        @click.stop="getproductx(items.node)"
                      >
                        {{ items.node }}
                      </li>
                    </ul>
                  </li>
                </ul>
              </el-collapse-item>
            </el-collapse>
            <el-collapse accordion>
              <el-collapse-item
                v-for="(item, index) in downstream.subNodeList"
                :key="index"
              >
                <template slot="title" @click="getproduct(item.node)"
                  ><div @click="getproduct(item.node)">
                    {{ item.node }}({{ item.count }})
                  </div></template
                >
                <ul>
                  <li
                    v-for="(itemx, indexx) in item.subNodeList"
                    :key="indexx"
                    @click="getproduct(itemx.node)"
                  >
                    {{ itemx.node }}({{ itemx.count }})
                    <i
                      class="el-icon-arrow-right"
                      v-if="itemx.subNodeList.length > 0"
                      :class="subNodeListshow == itemx.node ? 'isactive' : ''"
                    ></i>
                    <ul
                      v-if="
                        itemx.subNodeList.length > 0 &&
                        subNodeListshow == itemx.node
                      "
                    >
                      <li
                        v-for="(items, indess) in itemx.subNodeList"
                        :key="indess"
                        @click.stop="getproductx(items.node)"
                      >
                        {{ items.node }}
                      </li>
                    </ul>
                  </li>
                </ul>
              </el-collapse-item>
            </el-collapse>
          </div> -->
          <div class="t-bottom">
            <baidu-map
              v-if="showmap"
              class="bm-view"
              :center="center"
              :scroll-wheel-zoom="true"
              :double-click-zoom="double"
              :zoom="zoom"
            >
              <bm-control>
                <button @click="addZoom()">还原</button>
              </bm-control>
              <bm-label
                v-for="(item, index) in district"
                :key="index"
                :content="item.name"
                :position="item.jwd"
                :labelStyle="{ color: 'red', fontSize: '12px' }"
              />
              <bm-marker
                v-for="(item, index) in alilte"
                :key="'one' + index"
                :position="item.jwd"
                :dragging="false"
                @mouseout="out"
                @mouseover="hover(item.id)"
                :title="item.name"
              ></bm-marker>
              <bm-boundary
                v-for="item in district"
                :key="item.id"
                :name="item.name"
                :strokeWeight="1"
                strokeColor="blue"
                fillColor="#1F61F8"
                @click="gotocenter(item.jwd, item.name)"
              ></bm-boundary>
            </baidu-map>

            <baidu-map
              v-if="!showmap"
              class="bm-view"
              :center="center2"
              :zoom="zoom2"
              :scroll-wheel-zoom="true"
            >
              <bm-control>
                <button @click="addZoom()">还原</button>
              </bm-control>
              <bm-marker
                v-for="item in alilte"
                :key="'alilte' + item.id"
                :position="item.jwd"
                :dragging="false"
                @mouseout="out"
                @mouseover="hover(item.id)"
              ></bm-marker>
            </baidu-map>
          </div>
        </div>
        <div class="zhuzhuang">
          <span v-if="false">
            <div class="top">
              <div class="min-box">
                <span>{{ overviewdata.coms }}户</span>
                <span>企业总数</span>
              </div>
              <div class="min-box">
                <span>{{ overviewdata.taking }}万</span>
                <span>营业收入</span>
              </div>
              <div class="min-box">
                <span>{{ overviewdata.pnums }}人</span>
                <span>从业人数</span>
              </div>
              <div class="min-box">
                <span>{{ overviewdata.capital }}万</span>
                <span>注册资本</span>
              </div>
              <div class="min-box">
                <span>{{ overviewdata.profit }}万</span>
                <span>利润总额</span>
              </div>
              <div class="min-box">
                <img src="../../assets/industr/qiye.png" alt />
              </div>
            </div>
            <div class="center">
              <div id="main4" style="width: 100%; height: 100%"></div>
            </div>
          </span>
          <span v-if="true">
            <!-- <resources style="width:100%;" :distributionData="distributionData"></resources> -->
            <div class="enterprise_tub">
              <p>机构类型分布统计图</p>
              <div id="main0" style="width: 100%; height: 300px"></div>
            </div>
          </span>

          <div class="bottom">
            <span class="ttit">
              共为您查找到
              <span style="color: orange">{{ size }}</span
              >家机构
            </span>

            <ul>
              <li>
                <span>序号</span>
                <span>名称</span>
                <span>机构类型</span>
              </li>
              <li
                v-for="(item, index) in currentData"
                @click="bindDetail(item.id)"
                :key="index" :class="hov_active == item.id ? 'hov_active' : ''"
              >
                <span>{{ index + 1 }}</span>
                <span>{{ item.name }}</span>
                <span>{{ item.cate }}</span>
              </li>
            </ul>

            <div class="fenye">
              <el-pagination
                background
                layout="prev, pager, next"
                :page-size="10"
                :total="Number(size)"
                @current-change="handleCurrentChange"
              ></el-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
var alltp = {
  rootId: "",
  links: [],
  nodes: [],
}; // 二级图谱数据
import {
  clickinducollect,
  inducollect,
  enterprise1,
  twotupu,
  threetupu,
  industrynevigate,
  chain,
  innovateOrgs,
  apiInnovateOrgs,
  distribute,
  orgsSdmap,
  garden,
  map,
} from "@/api/index";
import RelationGraph from "relation-graph"; // 图谱
import resources from "./resources.vue"; //创新资源
export default {
  name: "chuangxin",
  data() {
    return {
      tagnum: 1,
      tpshownum: "1",
      secondshow: false, // 收藏
      collect: "", // 收藏状态
      cate: "1", //
      inid: 1, // 导航id
      total: "",
      // 图谱
      graphOptions: {
        backgrounImage: "",
        backgrounImageNoRepeat: false,
        layouts: [
          {
            label: "自动布局",
            layoutName: "force",
            layoutClassName: "seeks-layout-force",
            // label: "中心",
            // layoutName: "center",
            // layoutClassName: "seeks-layout-center"
          },
        ],
        defaultLineMarker: {
          markerWidth: 20,
          markerHeight: 20,
          refX: 3,
          refY: 3,
          data: "M 0 0, V 6, L 4 3, Z",
        },
        allowShowMiniNameFilter: false,
        allowShowMiniToolBar: false,
        isMoveByParentNode: false,
        defaultExpandHolderPosition: "right",
        // defaultNodeColor: "rgba(254, 106, 0, 1)",
        defaultNodeBorderWidth: 0,
        defaultLineColor: "rgba(254, 106, 0, 1)",
        defaultNodeShape: 0,
      },
      upstream: [],
      midstream: [],
      downstream: [],
      college: [],
      creative: [],
      induserve: [],
      optionstype: [],
      optionsaptitude: [],
      scenice: [],
      valueaptitude: null,
      valuetype: null,
      cateone: "",
      catetwo: "",
      page: 1,
      currentData: [],
      currentDataLength: 0,
      size: 0,
      center: { lng: 112.549, lat: 37.957 }, //经纬度
      zoom: 10, //地图展示级别
      color: "blue",
      hov_active:0, //地图markat悬停是变换颜色
      showmap: true,
      district: [],
      center2: "",
      zoom2: "",
      double: false,
      alilte: [],
      strdata: [],
      distributionData: [
        { value: "", name: "" },
        { value: "", name: "" },
        { value: "", name: "" },
        { value: "", name: "" },
      ],
      keyanjigoushow: true,
      product: "",
      ubNodeListshow: false,
    };
  },
  components: { RelationGraph, resources },
  created() {
    // this.getindustrynevigate(1, "1");
    this.getInnovateOrgs();
    this.cate = "1";
    this.inid = 1;
    this.getDistribute();
    this.getmap();
  },
  mounted() {
    // this.picture()
  },
  methods: {
    // 点击链图
    getproduct(name) {
      this.product = name;
      if (this.subNodeListshow == name) {
        this.subNodeListshow = false;
      } else {
        this.subNodeListshow = name;
      }
      this.apiInnovateOrgs();
      this.getzonemap();
    },
    getproductx(name) {
      this.product = name;
      this.apiInnovateOrgs();
      this.getzonemap();
    },
    getcateid(data) {
      this.allrootid = [];
      alltp = {
        rootId: "",
        links: [],
        nodes: [],
      };
      this.cate = data.cate;
      this.inid = data.id;
      if (this.cate != 1) {
        this.product = "";
        this.secondshow = true;
        // this.twotupu(this.inid, this.cate);
      } else {
        this.tpshownum = 1;
        this.product = "";
        this.secondshow = false;
        // this.getindustrynevigate(this.inid, this.cate);
      }
      this.apiInnovateOrgs();
      this.goinducollect();
      this.getDistribute();
      // this.getchain();
      this.getzonemap();
    },
    // 收藏
    async goinducollect() {
      const res = await inducollect({
        id: this.inid,
      });
      this.collect = res.collect;
    },
    async clickinducollect() {
      const res = await clickinducollect({
        id: this.inid,
        collect: this.collect,
      });

      if (res.code == 0) {
        this.collect = res.collect;
      }
    },
    // 机构类型
    async getInnovateOrgs() {
      const res = await innovateOrgs({});
      this.optionstype = res.options.class;
      this.optionsaptitude = res.options.college;
      this.cateone = this.optionstype[0].value;
      this.catetwo = this.optionsaptitude[0].value;
      this.valuetype = this.optionstype[0].value;
      this.valueaptitude = this.optionsaptitude[0].value;
      this.college = res.options.college;
      this.creative = res.options.creative;
      this.induserve = res.options.induserve;
      this.scenice = res.options.scenice;
      this.apiInnovateOrgs();
      this.getzonemap();
    },
    labelchange(e, data, type) {
      if (type === 1) {
        this.cateone = e;
        if (e === 1) {
          this.optionsaptitude = this.college;
          this.keyanjigoushow = true;
          this.apiInnovateOrgs();
          this.getzonemap();
        }
        if (e === 2) {
          this.optionsaptitude = this.scenice;
          this.valueaptitude = "";
          this.keyanjigoushow = false;
          this.apiInnovateOrgs();
          this.getzonemap();
        }
        if (e === 3) {
          this.optionsaptitude = this.creative;
          this.keyanjigoushow = true;
          this.apiInnovateOrgs();
          this.getzonemap();
        }
        if (e === 4) {
          this.optionsaptitude = this.induserve;
          this.keyanjigoushow = true;
          this.apiInnovateOrgs();
          this.getzonemap();
        }
        return false;
      }
      if (type === 2) {
        this.catetwo = e;
        this.apiInnovateOrgs();
        this.getzonemap();
      }
    },
    async apiInnovateOrgs() {
      const res = await apiInnovateOrgs({
        inid: this.inid,
        cate: this.cate,
        cateone: this.cateone,
        catetwo: this.catetwo,
        page: this.page,
        perpage: 10,
        product: this.product,
      });
      this.currentData = res.df;
      this.size = res.size;
      this.currentDataLength = res.df.length;
    },
    // 分页
    handleCurrentChange(val) {
      // if (this.currentDataLength < 10) {
      //   this.$message({ message: "暂无更多数据", type: "warning" });
      //   return false;
      // }
      this.page = val;
      this.apiInnovateOrgs();
      this.getzonemap();
    },
    // 机构类型分布统计图
    async getDistribute(name) {
      const res = await distribute({
        inid: this.inid,
        cate: this.cate,
        district: name,
      });
      // console.log(res);

      for (var i = 0; i < res.length; i++) {
        this.strdata.push(res[i].label);
        // console.log(res[i]);
        this.distributionData[i].value = res[i].value;
        this.distributionData[i].name = res[i].label;
      }
      this.picture();
    },
    // 详情
    bindDetail(id) {
      if (Number(this.cateone) === 1) {
        let href = this.$router.resolve({
          path: "/industrial/chdetsils7",
          query: { id: id, cateone: this.cateone },
        });
        window.open(href.href, "_self");
        return false;
      }
      if (Number(this.cateone) === 2) {
        let href = this.$router.resolve({
          path: "/industrial/chdetsils6",
          query: { id: id, cateone: this.cateone },
        });
        window.open(href.href, "_self");
        return false;
      }
      if (Number(this.cateone) === 3) {
        let href = this.$router.resolve({
          path: "/industrial/chdetsils4",
          query: { id: id, cateone: this.cateone },
        });
        window.open(href.href, "_self");
        return false;
      }
      if (Number(this.cateone) === 4) {
        let href = this.$router.resolve({
          path: "/industrial/chdetsils1",
          query: { id: id, cateone: this.cateone },
        });
        window.open(href.href, "_self");
        return false;
      }
    },
    // // 一级图谱
    // async getindustrynevigate(inid, cate) {
    //   const res = await industrynevigate({
    //     inid: inid,
    //     cate: cate,
    //   });
    //   this.showSeeksGraph(res);
    // },
    // // 二级图谱
    // async twotupu(inid, cate) {
    //   const res = await twotupu({
    //     inid: inid,
    //     cate: cate,
    //   });
    //   alltp.nodes.unshift(res.nodes[0]);
    //   alltp.rootId = res.nodes[0].id;
    //   this.showSeeksGraph(alltp);
    // },
    // // 三级图谱
    // async threetupu(data, name, id) {
    //   const res = await threetupu({
    //     id: id,
    //     name: name,
    //   });
    //   if (res.nodes.length == 0) {
    //     return false;
    //   }
    //   for (var i = 0; i < res.links.length; i++) {
    //     alltp.links.push(res.links[i]);
    //   }
    //   for (var i = 0; i < res.nodes.length; i++) {
    //     alltp.nodes.push(res.nodes[i]);
    //   }
    //   this.showSeeksGraph(alltp);
    // },
    // showSeeksGraph(query) {
    //   var __graph_json_data = query;
    //   this.$refs.seeksRelationGraph.setJsonData(
    //     __graph_json_data,
    //     (seeksRGGraph) => {
    //       // 这些写上当图谱初始化完成后需要执行的代码
    //     }
    //   );
    // },
    // onNodeClick(nodeObject, $event) {
    //   if (!this.secondshow) {
    //     return false;
    //   }
    //   if (this.allrootid.indexOf(nodeObject.id) == -1) {
    //     this.allrootid.push(nodeObject.id);
    //     this.threetupu(
    //       this.nodedata,
    //       nodeObject.text.split("↓")[0],
    //       nodeObject.id
    //     );
    //   } else {
    //     return false;
    //   }
    // },
    // 获取连图数据
    // async getchain(name) {
    //   const res = await chain({
    //     inid: this.inid,
    //     district: name,
    //   });
    //   this.upstream = res.nodeList[0];
    //   this.midstream = res.nodeList[1];
    //   this.downstream = res.nodeList[2];
    // },
    // ditu
    async getmap() {
      const res = await map({
        inid: 1,
        cate: "1",
      });
      this.district = res.df;
    },
    async getzonemap(name) {
      this.alilte = []
      const res = await orgsSdmap({
        inid: this.inid,
        cate: this.cate,
        cateone: this.cateone,
        catetwo: this.catetwo,
        page: this.page,
        perpage: 10,
        product: this.product,
      });
      this.alilte = res;
    },
    gotocenter(index, name) {
      this.showmap = false;
      this.center2 = index;
      this.color = "transparent !important";
      this.zoom2 = 13;
      this.getzonemap(name);
      this.getDistribute(name);
    },
    hover(id) {
      // console.log(this.alilte)
      this.hov_active=id
      console.log("打印")
      console.log(id)
      
      this.show = true;
    },
    out() {
      this.hov_active=0
      console.log("清除")
      this.show = false;
    },
    addZoom() {
      this.showmap = true;
      this.zoom = 10;
      this.getzonemap();
      // this.alilte=[]
    },
    picture() {
      // 用Dom获取上面已经创建的标签，初始化echarts实例
      let myChart = this.$echarts.init(document.getElementById("main0"));
      // 指定图表的配置项和数据
      let option = {
        tooltip: {
          trigger: "item",
          formatter: "{a} <br/>{b}: {c} ({d}%)",
        },
        legend: {
          bottom: 20,
          itemGap: 10,
          itemWidth: 8,
          itemHeight: 8,
          icon: "rect",
          data: this.strdata,
        },

        series: [
          {
            name: "访问来源",
            type: "pie",
            radius: ["40%", "70%"],
            center: ["50%", "45%"],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: "center",
            },
            emphasis: {
              label: {
                show: true,
                fontSize: "16",
                fontWeight: "bold",
              },
            },
            labelLine: {
              show: false,
            },
            itemStyle: {
              normal: {
                color: function (params) {
                  //自定义颜色
                  var colorList = ["#72DCFE", "#0191FE", "#FCD469", "#9D86FE"];
                  return colorList[params.dataIndex];
                },
              },
            },
            data: this.distributionData,
          },
        ],
        backgroundColor: "rgb(243,246,251)",
      };

      // 使用刚指定的配置项和数据显示图表的。
      myChart.setOption(option);
    },
  },
};
</script>

<style lang="less" scoped>
.favq {
  width: 100%;
  min-height: 1410px;
  background: rgb(243, 243, 243);
  padding-bottom: 30px;

  li {
    list-style: none;
  }

  .c-top {
    // width: 1920px;
    height: 72px;
    background: #fff;

    li {
      display: block;
      width: 100px;
      height: 38px;
      background: #eee;
      margin-top: 19px;
      border-radius: 6px;
      margin-right: 19px;
      font-size: 17px;
      font-weight: bold;
      color: #666666;
      text-align: center;
      line-height: 38px;
      float: left;
      cursor: pointer;
      &:nth-child(1) {
        margin-left: 52px;
      }
      &.active {
        background: rgb(254, 147, 34);
        color: #ffffff;
      }
    }
  }
}
.enterprise {
  padding: 0 30px;
}
.left {
  float: left;
  margin-top: 20px;
  background: #fff;
  min-width: 170px;

  .dxhj {
    padding-top: 13px;
    width: 100%;
    height: 38px;

    img {
      float: left;
      width: 38px;
      height: 38px;
      margin-left: 13px;
    }

    span {
      float: left;
      line-height: 31px;
      height: 18px;
      font-size: 18px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #222222;
    }
  }

  /deep/ .el-collapse-item__header {
    height: 40px;
    padding-left: 40px;
    font-size: 14px;
  }

  /deep/ .el-collapse-item__header.is-active {
    color: #fe6a00;
  }
}
.seach {
  display: inline-block;
  width: 54px;
  height: 31px;
  background: #fe6a00;
  border-radius: 4px;
  line-height: 34px;
  font-size: 14px;
  text-align: center;
  font-weight: bold;
  color: #ffffff;
}
.right {
  background: #fff;
  width: calc(100% - 256px - 20px);
  min-height: 1000px;
  float: right;
  padding-bottom: 30px;
  margin-top: 20px;
  margin-left: 10px;
  .ipt {
    width: 70%;
    float: left;
    padding-top: 10px;
    li {
      display: block;
      // width: 16%;
      height: 31px;
      float: left;
      font-size: 14px;
      font-weight: 400;
      color: #333333;
      margin: 1% 2% 0;

      /deep/.el-select {
        width: 164px;
        height: 30px;
        margin-right: 20px;
        input {
          width: 164px;
          height: 30px;
          background: #ffffff;
          border: 1px solid #dddddd;
        }
        /deep/.el-input__icon {
          line-height: 30px !important;
        }
      }
      /deep/.el-input {
        width: 164px;
        height: 30px;
        input {
          width: 164px;
          height: 30px;
          background: #ffffff;
          border: 1px solid #dddddd;
        }
        /deep/.el-icon-circle-close {
          background: url("../../assets/merchantsradar/cuo.png") 50% 50%
            no-repeat;
        }
        .el-icon-circle-close:before {
          content: "";
        }
      }
    }
    .shouc_btn {
      float: right;
      font-size: 15px;
      margin-top: 10px;

      img {
        margin-right: 5px;
        vertical-align: middle;
      }
    }
  }
  .zhanshi {
    height: 40px;
    display: flex;
    align-items: center;
    margin: 0% 2% 0;
    .lilast {
      list-style: none;
      span {
        display: block;
        width: 168px;
        height: 31px;
        float: left;
        font-size: 14px;
        font-weight: 400;
        color: #666666;
        line-height: 31px;
        border: 1px solid #dddddd;
        margin-right: 18px;
        padding-right: 10px;

        dl {
          float: right;
          color: #fe6a00;
          cursor: pointer;
        }
      }

      span:nth-child(2) {
        width: 182px;
      }
    }
  }

  .tupu {
    width: 70%;
    height: 48.3%;
    float: left;
    margin-top: 20px;
    .qh_tit {
      display: block;
      width: 97%;
      height: 40px;
      padding-left: 20px;

      span:nth-child(1) {
        background: #fe6a00;
        color: #fff;
      }

      span {
        width: 114px;
        height: 34px;
        border: 1px solid #fe6a00;
        float: left;
        line-height: 34px;
        font-size: 16px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #fe6a00;
        margin-right: 11px;
        border-radius: 10px;

        img {
          float: left;
          margin-top: 10px;
          margin-left: 10px;
        }
      }

      .span {
        float: right;
        border: none;
        text-align: left;
        line-height: 44px;
        color: #000;
        img {
          margin-right: 5px;
        }
      }
    }
    li {
      display: block;
      width: 97%;
      min-height: 38px;
      padding-left: 41px;
      i {
        transition: all 0.25s;
        &::before {
          display: inline-block;
          content: "\e6e0";
          font-size: 16px;
        }
      }
      ul {
        li {
          font-size: 14px;
          min-height: 24px;
          line-height: 24px;
          margin-left: 20px;
          margin-bottom: 0;
        }
      }
      .spannth {
        background: #fe6a00;
        color: #fff;
      }

      span {
        width: 114px;
        height: 34px;
        border: 1px solid #fe6a00;
        float: left;
        line-height: 34px;
        font-size: 16px;
        font-weight: 400;
        color: #fe6a00;
        margin-right: 11px;
        border-radius: 10px;
        cursor: pointer;
        img {
          float: left;
          margin-top: 10px;
          margin-left: 10px;
        }
      }

      .span {
        float: right;
        border: none;
        text-align: left;
        line-height: 44px;
        color: #000;
        display: flex;
        align-items: center;
        img {
          margin: 0;
          margin-right: 5px;
        }
      }
    }
    .t-Center {
      width: 100%;
      height: 517px;
      // border: 1px solid #ccc;
      margin-top: 30px;
      margin-left: 9px;
      background: url("../../assets/industr/bjt.png") no-repeat;
      background-size: 100% 100%;
    }

    .t-bottom {
      margin-top: 21px;
      margin-left: 9px;
      width: 100%;
      height: 700px;
      img {
        width: 100%;
        height: 100%;
      }
    }

    //链图模式
    .lian_tubox {
      margin-bottom: 25px;
      overflow: hidden;
      zoom: 1;

      .lian_tu_top {
        overflow: hidden;
        zoom: 1;
        margin: 20px 0 20px 20px;

        li {
          padding: 0;
          overflow: hidden;
          zoom: 1;
          float: left;
          height: 63px;
          line-height: 63px;
          vertical-align: top;
          text-align: center;
          font-size: 17px;
          background: linear-gradient(90deg, #22baf7, #37e0f7);
          color: #fff;

          img {
            height: 63px;
            display: inline;
            float: left;
            vertical-align: top;
          }
          img:last-child {
            float: right;
          }
        }
      }
    }
  }

  .zhuzhuang {
    width: 27%;
    height: 86%;
    margin-right: 1%;
    float: right;

    .top {
      width: 92%;
      height: 17%;
      border: 1px solid #ccc;
      margin: 1% 4%;
      background: url("../../assets/industr/-s-qiye.png") no-repeat;
      background-size: 100% 100%;
      border-radius: 10px;

      .min-box {
        position: relative;
        float: left;
        width: 33.2%;
        height: 49.5%;
        font-size: 20px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #ffffff;

        span {
          display: block;
          float: left;
          width: 100%;
          height: 19px;
          text-align: center;
          line-height: 64px;
        }

        span:nth-child(2) {
          margin-top: 11px;
          font-size: 15px;
        }

        img {
          position: absolute;
          top: -46px;
          right: 0;
        }
      }
    }

    .center {
      width: 92%;
      height: 28%;
      margin: 0 17px 28px 17px;
    }

    .bottom {
      margin-top: 25px;
      position: relative;
      height: 500px;
      .ttit {
        display: block;
        width: 100%;
        font-size: 15px;
        text-align: left;
        margin-bottom: 15px;
      }
      
      ul li {
        &.hov_active{
          color:#fe6a00;
        }
        display: block;
        width: 100%;
        height: 40px;
        font-size: 12px;
        line-height: 40px;
        cursor: pointer;
        &:nth-child(1){
          cursor:default !important;
          span:nth-child(1){
            cursor:default !important;
          }
        }
        span {
          display: block;
          width: 20%;
          height: 100%;
          float: left;
          img {
            margin-right: 5px;
          }
        }

        span:nth-child(1) {
          width: 18%;
          text-align: center;
          
        }

        span:nth-child(2) {
          width: 50%;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          text-align: left;
          // cursor: pointer;
        }

        span:nth-child(3) {
          width: 32%;
          text-align: left;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }

        /deep/ .el-rate {
          line-height: 40px;
        }

        /deep/ .el-rate__icon {
          font-size: 12px;
          margin: 0;
        }
      }

      ul li:nth-child(odd) {
        background: #f3f6fb;
      }
      .fenye {
        margin: 20px;
        position: absolute;
        bottom: -70px;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
  .bm-view {
    width: 100%;
    height: 100%;
    /deep/.BMapLabel {
      background-color: transparent !important;
      border: 0 !important;
      color: rgb(70, 17, 17) !important;
    }
  }
  /deep/.rel-map {
    background-color: transparent;
  }
  /deep/.rel-map-canvas {
    width: 100% !important;
    height: 100% !important;
    background-color: transparent;
  }
  /deep/.rel-node-shape-1 {
    padding: 0;
  }

  //下拉部分
  /deep/.el-collapse {
    border: 0;
    background: #c7e3ff;
    padding: 3%;
    margin-left: 2%;
    width: 30%;
    float: left;
    height: 340px;
    overflow-y: auto;
  }

  /deep/.el-collapse:nth-of-type(2) {
    background: #dbedff;
  }

  /deep/.el-collapse:nth-of-type(3) {
    background: #eef7ff;
  }
  ::-webkit-scrollbar {
    width: 5px;
    background-color: #eee;
  }

  /*定义滚动条轨道 内阴影+圆角*/
  ::-webkit-scrollbar-track {
    border-radius: 7px;
    background-color: #eee;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 7px; /*滚动条的圆角*/
    background-color: #9dcaf7; /*滚动条的背景颜色*/
  }

  /deep/.el-collapse-item__header {
    font-size: 18px;
    text-align: left;
    vertical-align: middle;
    background: none;
    font-weight: bold;
    border-bottom: 0;
    margin-bottom: 15px;
    height: 36px;
    line-height: 36px;
    min-width: 180px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    img {
      margin-right: 6px;
    }
  }
  /deep/.el-collapse-item__wrap {
    background: none;
    text-align: left;
    border-bottom: 0;
    font-weight: 400;
    color: #555;
    padding-left: 10px;
    min-width: 180px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  /deep/.el-collapse-item__content {
    padding-bottom: 0;
    li {
      font-size: 16px;
      margin-bottom: 15px;
      text-align: left;
      line-height: 30px;
      padding: 0;
      cursor: pointer;
    }
    .el-collapse {
      li {
        padding: 0;
      }
    }
  }
}
.enterprise_tub {
  text-align: left;
  font-size: 18px;
  font-weight: bold;
  color: #333333;
  p {
    margin-bottom: 15px;
  }
}
.isactive {
  transform: rotate(90deg);
}
</style>

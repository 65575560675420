<template>
  <div class="favq">
    <heand :tagnum="tagnum"></heand>
    <div class="c-top" ref="c-top">
      <li
        :class="$route.name == 'favq' ? 'active' : ''"
        @click="$router.push({ name: 'favq' })"
      >
        骨干企业
      </li>
      <li
        :class="$route.name == 'chuangxin' ? 'active' : ''"
        @click="$router.push({ name: 'chuangxin' })"
      >
        创新资源
      </li>
      <li
        :class="$route.name == 'zaiti' ? 'active' : ''"
        @click="$router.push({ name: 'zaiti' })"
      >
        产业载体
      </li>
      <li
        :class="$route.name == 'zhengce' ? 'active' : ''"
        @click="$router.push({ name: 'zhengce' })"
      >
        产业政策
      </li>
    </div>
    <div v-if="true">
      <enterprise></enterprise>
    </div>
  </div>
</template>

<script>
import enterprise from "./enterprise.vue"; //骨干企业
// import { ceshi } from "@/api/index";
export default {
  name: "favq",
  data() {
    return {
      tagnum: 1,
    };
  },
  components: { enterprise },

  methods: {},
  mounted() {},
  created() {},
  methods: {},
};
</script>

<style lang="less" scoped>
.favq {
  width: 100%;
  // height: 1410px;
  padding-bottom: 30px;
  background: rgb(243, 243, 243);
  li {
    list-style: none;
  }

  .c-top {
    width: 100%;
    height: 72px;
    background: #fff;

    li {
      display: block;
      width: 100px;
      height: 38px;
      background: #eee;
      margin-top: 19px;
      border-radius: 6px;
      margin-right: 19px;
      font-size: 17px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #666666;
      text-align: center;
      line-height: 38px;
      float: left;
      cursor: pointer;
      &:nth-child(1) {
        margin-left: 52px;
      }
      &.active {
        background: rgb(254, 147, 34);
        color: #ffffff;
      }
    }
  }
}

</style>

<template>
  <div>
    <div class="navleft">
      <ul class="nav">
        <li
          :class="$route.name == 'systemglhou'? 'hover':''"
          @click="$router.push({name:'systemglhou'})"
        >
          <img v-if="$route.name == 'systemglhou'" src="@/assets/systemgl/nav1_hover.png" alt />
          <img v-else src="@/assets/systemgl/nav1.png" alt />
          组织架构管理
        </li>
        <li
          :class="$route.name == 'systemglzuzhi'? 'hover':''"
          @click="$router.push({name:'systemglzuzhi'})"
        >
          <img v-if="$route.name == 'systemglzuzhi'" src="@/assets/systemgl/nav2_hover.png" alt />
          <img v-else src="@/assets/systemgl/nav2.png" alt />
          用户信息管理
        </li>
        <li
          :class="$route.name == 'systemgljuese'? 'hover':''"
          @click="$router.push({name:'systemgljuese'})"
        >
          <img v-if="$route.name == 'systemgljuese'" src="@/assets/systemgl/nav3_hover.png" alt />
          <img v-else src="@/assets/systemgl/nav3.png" alt />
          角色权限管理
        </li>
        <li
          :class="$route.name == 'systemglshuju'? 'hover':''"
          @click="$router.push({name:'systemglshuju'})"
        >
          <img v-if="$route.name == 'systemglshuju'" src="@/assets/systemgl/nav4_hover.png" alt />
          <img v-else src="@/assets/systemgl/nav4.png" alt />
          数据字典管理
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import { industryfields } from "@/api/index";
export default {
  name: "navleft",
  data() {
    return {
      defaultProps: {
        children: "children",
        label: "label"
      },
      data: "",
      navleftdata: [],
      treeExpandData: []
    };
  }
};
</script>
<style lang="less" scoped>
.navleft {
  background-color: #fff;
  width: 256px;
  min-height: 88vh;
  float: left;
  position: fixed;
  .nav {
    text-align: left;
    font-size: 16px;
    color: #222;
    margin-bottom: 2px;
    padding-top: 10px;
    img {
      vertical-align: middle;
      margin-right: 10px;
    }
    li {
      height: 74px;
      line-height: 74px;
      padding-left: 30px;
      list-style: none;
      border-bottom: 1px solid #eee;
      cursor: pointer;
    }
    li.hover,
    li:hover {
      color: #fe6a00;
    }
  }
  /deep/.el-tree-node__label {
    font-size: 16px;
    color: #222222;
  }
  /deep/.is-expanded {
    .el-tree-node__content {
      .el-tree-node__label {
        color: #fe6a00;
      }
    }
    .el-tree-node__children {
      .el-tree-node__label {
        color: rgba(102, 102, 102, 1);
      }
    }
  }

  /deep/.el-tree-node__content {
    height: 50px;
    border-bottom: 1px solid #eee;
    .el-icon-caret-right:before {
      content: "";
      width: 9px;
      height: 8px;
      display: block;
      background: url("../assets/merchantsradar/xia.png") no-repeat;
    }
    .expanded:before {
      transform: rotate(-90deg);
      background: url("../assets/merchantsradar/shang.png") no-repeat;
    }
    .el-tree-node__expand-icon.is-leaf:before {
      background-image: unset;
    }
  }
}
</style>
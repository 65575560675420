<template>
  <div>
    <div class="navleft">
      <ul class="nav">
        <li
          :class="$route.name == 'projectgl'? 'hover':''"
          @click="flag == 2?$router.push({ name: 'projectgl' }) : $message('暂无权限')"
        >
          <img v-if="$route.name == 'projectgl'" src="@/assets/zhaoshresource/nav1_hover.png" alt />
          <img v-else src="@/assets/zhaoshresource/nav1.png" alt />招商项目管理
        </li>
        <li
          :class="$route.name == 'Reporttracking'? 'hover':''"
          @click="flag == 2?$router.push({ name: 'Reporttracking' }) : $message('暂无权限')"
        >
          <img
            v-if="$route.name == 'Reporttracking'"
            src="@/assets/zhaoshresource/nav2_hover.png"
            alt
          />
          <img v-else src="@/assets/zhaoshresource/nav2.png" alt />项目上报追踪
        </li>
        <li
          :class="$route.name == 'statistics'? 'hover':''"
          @click="flag == 2?$router.push({ name: 'statistics' }) : $message('暂无权限')"
        >
          <img v-if="$route.name == 'statistics'" src="@/assets/zhaoshresource/nav3_hover.png" alt />
          <img v-else src="@/assets/zhaoshresource/nav3.png" alt />招商进度统计
        </li>
        <li
          :class="$route.name == 'quyugaunliindex'? 'hover':''"
          @click="flag == 2 || flag == 3?$router.push({ name: 'quyugaunliindex' }) : $message('暂无权限')"
        >
          <img
            v-if="$route.name == 'quyugaunliindex'"
            src="@/assets/zhaoshresource/nav4_hover.png"
            alt
          />
          <img v-else src="@/assets/zhaoshresource/nav4.png" alt />区域要素管理
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import { industryfields, getFlag } from "@/api/index";
export default {
  name: "navleft",
  data() {
    return {
      defaultProps: {
        children: "children",
        label: "label",
      },
      data: "",
      navleftdata: [],
      treeExpandData: [],
      flag: "",
    };
  },
  created() {
    console.log(this.$route)
    this.getFlag();
  },
  methods: {
    async getFlag() {
      const res = await getFlag();
      this.flag = res;
      console.log(this.flag);
    },
  },
};
</script>
<style lang="less" scoped>
.navleft {
  background-color: #fff;
  width: 256px;
  min-height: 88vh;
  float: left;
  .nav {
    text-align: left;
    font-size: 16px;
    color: #222;
    margin-bottom: 2px;
    padding-top: 10px;
    img {
      vertical-align: middle;
      margin-right: 10px;
    }
    li {
      height: 74px;
      // line-height: 74px;
      display: flex;
      align-items: center;
      padding-left: 30px;
      list-style: none;
      border-bottom: 1px solid #eee;
      cursor: pointer;
    }
    li.hover,
    li:hover {
      color: #fe6a00;
    }
  }
  /deep/.el-tree-node__label {
    font-size: 16px;
    color: #222222;
  }
  /deep/.is-expanded {
    .el-tree-node__content {
      .el-tree-node__label {
        color: #fe6a00;
      }
    }
    .el-tree-node__children {
      .el-tree-node__label {
        color: rgba(102, 102, 102, 1);
      }
    }
  }

  /deep/.el-tree-node__content {
    height: 50px;
    border-bottom: 1px solid #eee;
    .el-icon-caret-right:before {
      content: "";
      width: 9px;
      height: 8px;
      display: block;
      background: url("../assets/merchantsradar/xia.png") no-repeat;
    }
    .expanded:before {
      transform: rotate(-90deg);
      background: url("../assets/merchantsradar/shang.png") no-repeat;
    }
    .el-tree-node__expand-icon.is-leaf:before {
      background-image: unset;
    }
  }
}
</style>
<template>
  <div class="enterprise clearfix">
    <div class="left">
      <navleft v-on:cateid="getcateid" />
    </div>
    <div class="right">
      <div class="ipt">
        <li>
          企业类型:
          <el-select
            class="iipp"
            v-model="valuetype"
            placeholder="请选择"
            @change="labelchange($event, optionstype, 1)"
          >
            <el-option
              v-for="(item, index) in optionstype"
              :key="index"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </li>
        <li>
          企业资质:
          <el-select
            class="iipp"
            v-model="valueaptitude"
            placeholder="请选择"
            @change="labelchange($event, optionsaptitude, 2)"
          >
            <el-option
              v-for="item in optionsaptitude"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </li>
        <li>
          注册资本：
          <el-select
            class="iipp"
            v-model="valuecapital"
            placeholder="请选择"
            @change="labelchange($event, optionscapital, 3)"
          >
            <el-option
              v-for="item in optionscapital"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </li>
        <li>
          上市状态:
          <el-select
            class="iipp"
            v-model="valuestate"
            placeholder="请选择"
            @change="labelchange($event, optionsstate, 4)"
          >
            <el-option
              v-for="item in optionsstate"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </li>
        <li>
          所在地区:
          <el-select
            class="iipp"
            v-model="valueregion"
            placeholder="请选择"
            @change="labelchange($event, optionsregion, 5)"
          >
            <el-option
              v-for="(item, index) in optionsregion"
              :key="index"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </li>
        <li>
          成立时间:
          <el-select
            class="iipp"
            v-model="valuetime"
            placeholder="请选择"
            @change="labelchange($event, optionstime, 6)"
          >
            <el-option
              v-for="(item, index) in optionstime"
              :key="index"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </li>
        <li>
          融资轮次:
          <el-select
            class="iipp"
            v-model="valuefinanc"
            placeholder="请选择"
            @change="labelchange($event, optionsfinanc, 7)"
          >
            <el-option
              v-for="(item, index) in optionsfinanc"
              :key="index"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </li>
        <li>
          公司名称:
          <el-input
            v-model="company_name"
            placeholder="请输入公司名称"
          ></el-input>
        </li>
        <li>
          排序:
          <el-select
            class="iipp"
            v-model="valuesorts"
            placeholder="请选择"
            @change="labelchange($event, optionssorts, 8)"
          >
            <el-option
              v-for="(item, index) in optionssorts"
              :key="index"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </li>
        <li>
          <span class="seach" @click="querycondition">查询</span>
        </li>
      </div>
      <div class="zhanshi">
        <li class="lilast">
          <span v-if="type">
            {{ type }}
            <dl @click="(type = ''), (valuetype = '')">X</dl>
          </span>
          <span v-if="aptitude">
            {{ aptitude }}
            <dl @click="(aptitude = ''), (valueaptitude = '')">X</dl>
          </span>
          <span v-if="capital">
            {{ capital }}
            <dl @click="(capital = ''), (valuecapital = '')">X</dl>
          </span>
          <span v-if="state">
            {{ state }}
            <dl @click="(state = ''), (valuestate = '')">X</dl>
          </span>
          <span v-if="region">
            {{ region }}
            <dl @click="(region = ''), (valueregion = '')">X</dl>
          </span>
          <span v-if="time">
            {{ time }}
            <dl @click="(time = ''), (valuetime = '')">X</dl>
          </span>

          <span v-if="financ">
            {{ financ }}
            <dl @click="(financ = ''), (valuefinanc = '')">X</dl>
          </span>
          <span v-if="sorts">
            {{ sorts }}
            <dl @click="(sorts = ''), (valuesorts = '')">X</dl>
          </span>
        </li>
      </div>
      <div class="tupu">
        <li>
          <span @click="tpshownum = 1" :class="tpshownum == 1 ? 'spannth' : ''">
            <img
              v-if="tpshownum == 1"
              src="../../assets/industr/tupu.png"
              alt
            />
            <img
              v-if="tpshownum == 2"
              src="../../assets/industr/jusetupu.png"
              alt
            />图谱模式
          </span>
          <span
            @click="tpshownum = 2"
            v-if="secondshow"
            :class="tpshownum == 2 ? 'spannth' : ''"
          >
            <img
              v-if="tpshownum == 1"
              src="../../assets/industr/lianjie.png"
              alt
            />
            <img
              v-if="tpshownum == 2"
              src="../../assets/industr/baiseliantu.png"
              alt
            />链图模式
          </span>
          <span class="span" v-if="secondshow" @click="clickinducollect">
            <img v-if="collect == 2" src="../../assets/industr/ubcj.png" alt />
            <small v-if="collect == 2">收藏</small>
            <img
              v-if="collect == 1"
              src="../../assets/industr/shoucang2.png"
              alt
            />
            <small v-if="collect == 1">取消收藏</small>
          </span>
        </li>
        <div class="t-Center" v-show="tpshownum == 1">
          <div style="width: 100%; height: 100%">
            <RelationGraph
              ref="seeksRelationGraph"
              :options="graphOptions"
              :on-node-click="onNodeClick"
              :on-line-click="onLineClick"
              style="width: 100%; height: 100%"
            />
          </div>
        </div>
        <div class="lian_tubox" v-show="tpshownum == 2">
          <ul class="lian_tu_top">
            <li style="width: 33%">
              <img src="../../assets/industr/arrow-left.png" alt />
              上游
              <!-- （{{ upstream.count }}） -->
              <img src="../../assets/industr/arrow-right.png" alt />
            </li>
            <li style="width: 33%">
              <img src="../../assets/industr/arrow-left.png" alt />
              中游
              <!-- （{{ midstream.count }}） -->
              <img src="../../assets/industr/arrow-right.png" alt />
            </li>
            <li style="width: 33%">
              <img src="../../assets/industr/arrow-left.png" alt />
              下游
              <!-- （{{ downstream.count }}） -->
              <img src="../../assets/industr/arrow-right.png" alt />
            </li>
          </ul>
          <el-collapse accordion>
            <el-collapse-item
              v-for="(item, index) in upstream.subNodeList"
              :key="index"
            >
              <template slot="title"
                ><div class="" @click="getproduct(item.node)">
                  {{ item.node }}
                  <!-- ({{ item.count }}) -->
                </div></template
              >

              <ul>
                <li
                  v-for="(itemx, indexx) in item.subNodeList"
                  :key="indexx"
                  @click="getproduct(itemx.node)"
                >
                  {{ itemx.node }}
                  <!-- ({{ itemx.count }}) -->
                  <i
                    class="el-icon-arrow-right"
                    v-if="itemx.subNodeList.length > 0"
                    :class="subNodeListshow == itemx.node ? 'isactive' : ''"
                  ></i>
                  <ul
                    v-if="
                      itemx.subNodeList.length > 0 &&
                        subNodeListshow == itemx.node
                    "
                  >
                    <li
                      v-for="(items, indess) in itemx.subNodeList"
                      :key="indess"
                      @click.stop="getproductx(items.node)"
                    >
                      {{ items.node }}
                      <!-- ({{ items.count}}) -->
                    </li>
                  </ul>
                </li>
              </ul>
            </el-collapse-item>
          </el-collapse>
          <el-collapse accordion>
            <el-collapse-item
              v-for="(item, index) in midstream.subNodeList"
              :key="index"
            >
              <template slot="title">
                <div class="" @click="getproduct(item.node)">
                  {{ item.node }}
                  <!-- ({{ item.count }}) -->
                </div>
              </template>
              <ul>
                <li
                  v-for="(itemx, indexx) in item.subNodeList"
                  :key="indexx"
                  @click="getproduct(itemx.node)"
                >
                  {{ itemx.node }}
                  <!-- ({{ itemx.count }}) -->
                  <i
                    class="el-icon-arrow-right"
                    v-if="itemx.subNodeList.length > 0"
                    :class="subNodeListshow == itemx.node ? 'isactive' : ''"
                  ></i>
                  <ul
                    v-if="
                      itemx.subNodeList.length > 0 &&
                        subNodeListshow == itemx.node
                    "
                  >
                    <li
                      v-for="(items, indess) in itemx.subNodeList"
                      :key="indess"
                      @click.stop="getproductx(items.node)"
                    >
                      {{ items.node }}
                      <!-- ({{ items.count}}) -->
                    </li>
                  </ul>
                </li>
              </ul>
            </el-collapse-item>
          </el-collapse>
          <el-collapse accordion>
            <el-collapse-item
              v-for="(item, index) in downstream.subNodeList"
              :key="index"
            >
              <template slot="title"
                ><div class="" @click="getproduct(item.node)">
                  {{ item.node }}
                  <!-- ({{ item.count }}) -->
                </div></template
              >
              <ul>
                <li
                  v-for="(itemx, indexx) in item.subNodeList"
                  :key="indexx"
                  @click="getproduct(itemx.node)"
                >
                  {{ itemx.node }}
                  <!-- ({{ itemx.count }}) -->
                  <i
                    class="el-icon-arrow-right"
                    v-if="itemx.subNodeList.length > 0"
                    :class="subNodeListshow == itemx.node ? 'isactive' : ''"
                  ></i>
                  <ul
                    v-if="
                      itemx.subNodeList.length > 0 &&
                        subNodeListshow == itemx.node
                    "
                  >
                    <li
                      v-for="(items, indess) in itemx.subNodeList"
                      :key="indess"
                      @click.stop="getproductx(items.node)"
                    >
                      {{ items.node }}
                      <!-- ({{ items.count}}) -->
                    </li>
                  </ul>
                </li>
              </ul>
            </el-collapse-item>
          </el-collapse>
        </div>
        <div class="t-bottom">
          <div class="map">
            <baidu-map
              v-if="showmap"
              class="bm-view"
              :center="center"
              :double-click-zoom="double"
              :zoom="zoom"
              :scroll-wheel-zoom="true"
            >
              <bm-control>
                <button @click="addZoom()">还原</button>
              </bm-control>
              <bm-marker
                v-for="(item, index) in alilte"
                :key="'one' + index"
                :position="item.jwd"
                :dragging="false"
                @mouseout="out"
                @mouseover="hover"
                :title="item.name"
                :icon="{ url: item.stream, size: { width: 15, height: 15 } }"
              ></bm-marker>
              <bm-boundary
                v-for="item in district"
                :key="item.id"
                :name="item.name"
                :strokeWeight="1"
                strokeColor="blue"
                :fillColor="item.color"
                @click="gotocenter(item.jwd, item.name)"
              ></bm-boundary>
              <bm-label
                v-for="(item, index) in district"
                :key="index"
                :content="item.name"
                :position="item.jwd"
                :labelStyle="{ color: 'red', fontSize: '12px' }"
              />
            </baidu-map>

            <baidu-map
              v-if="!showmap"
              class="bm-view"
              :center="center2"
              :zoom="zoom2"
              :scroll-wheel-zoom="true"
            >
              <bm-control>
                <button @click="addZoom()">还原</button>
              </bm-control>
              <bm-marker
                v-for="item in alilte"
                :key="'alilte' + item.id"
                :position="item.jwd"
                :dragging="false"
                @mouseout="out"
                @mouseover="hover"
                :title="item.name"
                :icon="{ url: item.stream, size: { width: 300, height: 157 } }"
              ></bm-marker>
              <!-- {url: 'http://192.168.1.127:5000/static/legend/red.png'} -->
            </baidu-map>
          </div>
        </div>
      </div>
      <div class="zhuzhuang">
        <span v-if="true">
          <div class="top">
            <div class="min-box">
              <span>{{ overviewdata.coms }}</span>
              <span>企业总数</span>
            </div>
            <!-- <div class="min-box">
              <span>{{ overviewdata.taking }}</span>
              <span>营业收入</span>
            </div> -->
            <div class="min-box">
              <span>{{ overviewdata.pnums }}</span>
              <span>从业人数</span>
            </div>
            <div class="min-box">
              <span>{{ overviewdata.capital }}</span>
              <span>注册资本</span>
            </div>
            <div class="min-box" v-if="overviewdata.profit == 0">
              <span>{{ overviewdata.profit }}</span>
              <span>利润总额</span>
            </div>
            <div class="min-box">
              <img src="../../assets/industr/qiye.png" alt />
            </div>
          </div>
          <div class="centerc">
            <div id="main4" style="width: 100%; height: 100%"></div>
          </div>
        </span>
        <span v-if="false">
          <resources style="width: 100%; height: 43%"></resources>
        </span>

        <div class="bottom">
          <span class="ttit">
            共为您查找到
            <span style="color: orange">{{ total }}</span
            >家企业
          </span>

          <ul>
            <li>
              <span>序号</span>
              <span>企业名称</span>
              <span>企业热度</span>
            </li>
            <li
              v-for="(item, index) in enterprisenum"
              :key="index"
              @click="goDetails(item.id)"
            >
              <span>{{ index + 1 }}</span>
              <span :title="item.company_name">{{ item.company_name }}</span>
              <span>
                <img
                  v-for="item in item.hots"
                  src="../../assets/merchantsradar/zuihuo.png"
                  alt
                />
                <img
                  v-for="item in 5 - item.hots"
                  src="../../assets/merchantsradar/zuihuo2.png"
                  alt
                />
                <!-- {{ item.hots }}星 -->
              </span>
            </li>
          </ul>

          <div class="fenye">
            <el-pagination
              small
              background
              layout="prev, pager, next"
              :pager-count="5"
              :total="Number(total)"
              @current-change="handleCurrentChange"
            ></el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
var alltp = {
  rootId: "",
  links: [],
  nodes: [],
}; // 二级图谱数据
import RelationGraph from "relation-graph"; // 图谱
import resources from "./resources.vue"; //创新资源
import {
  industrynevigate,
  screening,
  enterprise1,
  enterprise2,
  enterprise3,
  inducollect,
  clickinducollect,
  twotupu,
  threetupu,
  map,
  sdmap,
  chain,
} from "@/api/index";
export default {
  name: "enterprise",
  data() {
    return {
      subNodeListshow: false,
      urlicon: "", //小图标
      secondshow: false, // 收藏
      collect: "", // 收藏状态
      hotimg: 1,
      hotimg2: 4,
      activeNames: ["1"],
      input1: "",
      type: "",
      valuetype: "", // 企业类型
      optionstype: [], // 企业类型
      aptitude: "",
      valueaptitude: "", //企业资质
      optionsaptitude: [], //企业资质
      capital: "",
      valuecapital: "", //注册资本
      optionscapital: [], //注册资本
      valuestate: "", //上市状态
      state: "",
      optionsstate: [], //上市状态
      region: "",
      valueregion: "", //所在地区
      optionsregion: [], //所在地区
      time: "",
      valuetime: "", //成立时间
      sorts: "",
      valuesorts: "",
      company_name: "", // 公司名称
      optionstime: [], //成立时间
      optionssorts: [
        { label: "时间降序", value: "1" },
        { label: "热度降序", value: "2" },
      ],
      financ: "",
      valuefinanc: "", //融资轮次
      optionsfinanc: [], //融资轮次
      // 图谱
      graphOptions: {
        backgrounImage: "",
        backgrounImageNoRepeat: false,
        layouts: [
          {
            label: "自动布局",
            layoutName: "force",
            layoutClassName: "seeks-layout-force",
          },
        ],
        defaultLineMarker: {
          markerWidth: 20,
          markerHeight: 20,
          refX: 3,
          refY: 3,
          data: "M 0 0, V 6, L 4 3, Z",
        },
        allowShowMiniNameFilter: false,
        allowShowMiniToolBar: false,
        isMoveByParentNode: false,
        defaultExpandHolderPosition: "right",
        // defaultNodeColor: "rgba(254, 106, 0, 1)",
        defaultNodeBorderWidth: 0,
        defaultLineColor: "rgba(254, 106, 0, 1)",
        defaultNodeShape: 0,
      },
      // 这里可以参考"Graph 图谱"中的参数进行设置
      resdata: "",
      cate: "1", //
      inid: 4, // 导航id
      product: "",
      enterprisenum: "", //查询的企业
      total: "",
      page: 1,
      overviewdata: [], //总览
      sourcedata: [],
      zhudata: [],
      zhedata: [],
      yeardata: [],
      nodedata: {}, // 二级图谱数据
      center: { lng: 112.549, lat: 37.957 }, //经纬度
      zoom: 10, //地图展示级别
      color: "blue",
      num: 130,
      showmap: true,
      district: [],
      show: false,
      center2: "",
      zoom2: "",
      double: true,
      alilte: [],
      allrootid: [],
      tpshownum: 1,
      upstream: [],
      midstream: [],
      downstream: [],
    };
  },
  components: { RelationGraph, resources },
  created() {
    this.getscreening();
    this.getindustrynevigate(4, "1");
    this.inid = Number(localStorage.getItem("inid"));
    this.getmapdata();
    this.allrootid = [];
  },
  mounted() {
    this.querycondition();
  },
  methods: {
    goDetails(id) {
      // let href = this.$router.resolve({
      //   name: "chdetsils9",
      //   query: { id: id, tagnum: 1 },
      // });
      // window.open(href.href, "_blank");
      this.$router.push({
        name: "chdetsils9",
        query: { id: id, tagnum: 1 },
      });
    },
    getcateid(data) {
      this.allrootid = [];
      alltp = {
        rootId: "",
        links: [],
        nodes: [],
      };
      this.cate = data.cate;
      this.inid = data.id;
      if (this.cate != 1) {
        this.secondshow = true;
        this.twotupu(this.inid, this.cate);
        this.getsdmap(name);
      } else {
        this.secondshow = false;
        this.getindustrynevigate(this.inid, this.cate);
      }
      this.product = "";
      this.getchain();
      this.goinducollect();
      this.querycondition();
    },
    //点击链图列表查询数据
    getproduct(name) {
      this.product = name;
      this.querycondition();
      if (this.subNodeListshow == name) {
        this.subNodeListshow = false;
      } else {
        this.subNodeListshow = name;
      }

      this.getsdmap("", name);
    },
    getproductx(name) {
      this.product = name;
      this.querycondition();
      this.getsdmap("", name);
    },
    //获取筛选条件
    async getscreening() {
      const res = await screening();
      this.optionstype = res.options.entype;
      this.optionsaptitude = res.options.qualificat;
      this.optionscapital = res.options.capital;
      this.optionsstate = res.options.quoted;
      this.optionstime = res.options.buildate;
      this.optionsfinanc = res.options.financ;
      this.optionsregion = res.options.district;
    },
    // 点击查询
    querycondition() {
      const data = {
        inid: this.inid,
        cate: this.cate,
        page: this.page,
        perpage: 10,
        entype: this.valuetype, //企业类型
        qualificat: this.valueaptitude, //企业资质
        capital: this.valuecapital, //资本
        quoted: this.valuestate, //上市状态
        district: this.valueregion, // 区县选择
        yearid: this.valuetime, // 时间
        roundid: this.valuefinanc, //融资轮次
        product: this.product, //链图点击
        sorts: this.valuesorts,
        company_name: this.company_name,
      };
      this.getlistdata(data);
      this.enterprise2(data);
      this.enterprise3(data);
    },
    // 公司分页查询
    handleCurrentChange(val) {
      this.page = val;
      this.querycondition();
    },
    // 获取产业图谱企业列表
    async getlistdata(data) {
      console.log();
      const res = await enterprise1(data);
      this.enterprisenum = res.df;
      this.total = res.size;
    },
    //产业发展图谱页太原市行业下企业总览
    async enterprise2(data) {
      const res = await enterprise2(data);
      this.overviewdata = res;
    },
    // 柱状图
    async enterprise3(data) {
      const res = await enterprise3(data);
      this.zhudata = [];
      this.zhedata = [];
      this.yeardata = [];
      for (var i = 0; i < res.cpital.length; i++) {
        this.zhudata.push(res.cpital[i].value);
        this.yeardata.push(res.cpital[i].year);
      }
      for (var i = 0; i < res.register.length; i++) {
        this.zhedata.push(res.register[i].value);
      }
      this.picture();
    },
    // 收藏
    async goinducollect() {
      const res = await inducollect({
        id: this.inid,
      });
      this.collect = res.collect;
    },
    async clickinducollect() {
      const res = await clickinducollect({
        id: this.inid,
        collect: this.collect,
      });

      if (res.code == 0) {
        // this.$message({
        //   message: res.msg,
        //   type: "success"
        // });

        this.collect = res.collect;
      }
    },
    // 下拉框匹配数据
    labelchange(e, data, num) {
      console.log(e, data, num, "9999999");
      let obj = {};
      obj = data.find((item) => {
        return item.value === e; //筛选出匹配数据
      });
      let str = obj.label;
      switch (num) {
        case 1:
          this.type = str;
          break;
        case 2:
          this.aptitude = str;
          break;
        case 3:
          this.capital = str;
          break;
        case 4:
          this.state = str;
          break;
        case 5:
          this.region = str;
          break;
        case 6:
          this.time = str;
          break;
        case 7:
          this.financ = str;
          break;
        case 8:
          this.sorts = str;
          break;
        // default:
        //   默认代码块;
      }
    },
    // 饼图
    picture() {
      // 用Dom获取上面已经创建的标签，初始化echarts实例
      let myChart = this.$echarts.init(document.getElementById("main4"));
      // 指定图表的配置项和数据

      // 使用刚指定的配置项和数据显示图表的。
      let option = {
        title: {
          text: "",
          x: "center",
          y: 0,
          textStyle: {
            color: "#B4B4B4",
            fontSize: 16,
            fontWeight: "normal",
          },
        },
        tooltip: {
          trigger: "axis",
          backgroundColor: "rgba(0,0,0,0.6)",
        },
        legend: {
          // 修改legend的高度宽度
          itemHeight: 5,
          itemWidth: 24,
          top: 90,
          data: [
            {
              name: "注册总数(户)",
              icon: "rect", // legend的icon
            },
            {
              name: "注册资本(万元)",
              icon: "rect",
            },
          ],
          textStyle: {
            color: "#B4B4B4",
          },
          top: "7%",
        },
        grid: {
          x: "10%",
          width: "68%",
          y: "12%",
          top: 70,
        },
        xAxis: [
          {
            // type:'category',
            data: this.yeardata,
            boundaryGap: true,
            axisLine: {
              lineStyle: {
                color: "#B4B4B4",
              },
            },
            axisTick: {
              show: false,
            },
          },
        ],
        yAxis: [
          {
            name: "注册资本(万元)",
            nameLocation: "middle",
            nameTextStyle: {
              padding: [3, 4, 50, 6],
            },
            splitLine: {
              show: true,
              lineStyle: {
                type: "dashed",
                color: "#eee",
              },
            },
            axisLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            axisLabel: {
              textStyle: {
                color: "#333",
              },
              formatter: "{value} ",
            },
          },
          {
            name: "",
            nameLocation: "middle",
            nameTextStyle: {
              padding: [50, 4, 5, 6],
            },
            splitLine: {
              show: false,
            },
            axisLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            axisLabel: {
              textStyle: {
                color: "#333",
              },
              formatter: "{value} ",
            },
          },
        ],
        series: [
          {
            name: "注册总数(户)",
            type: "line",
            smooth: true,
            showSymbol: true,
            symbolSize: 12,
            yAxisIndex: 0,
            areaStyle: {
              normal: {
                color: {
                  type: "linear", //设置线性渐变
                  x: 0,
                  y: 0,
                  x2: 0,
                  y2: 1,
                  colorStops: [
                    {
                      offset: 0,
                      color: "#FF9C25", // 0% 处的颜色
                    },
                    {
                      offset: 1,
                      color: "white", // 100% 处的颜色
                    },
                  ],
                  globalCoord: false, // 缺省为 false
                },
                shadowColor: "#FF9C25",
                shadowBlur: 20,
              },
            },
            itemStyle: {
              normal: {
                color: "#FF8000",
              },
            },
            // data中可以使用对象，value代表相应的值，另外可加入自定义的属性
            data: this.zhedata,
          },
          {
            name: "注册资本(万元)",
            type: "bar",
            barWidth: 30,
            yAxisIndex: 1,
            itemStyle: {
              normal: {
                color: {
                  type: "linear", //设置线性渐变
                  x: 0,
                  y: 0,
                  x2: 0,
                  y2: 1,
                  colorStops: [
                    {
                      offset: 0,
                      color: "#3D72FF", // 0% 处的颜色
                    },
                    {
                      offset: 1,
                      color: "#68B2FF", // 100% 处的颜色
                    },
                  ],
                  globalCoord: false, // 缺省为 false
                },
                //柱状图圆角
                barBorderRadius: [30, 30, 0, 0],
              },
            },

            data: this.zhudata,
          },
        ],
      };
      myChart.setOption(option);
    },
    // 一级图谱
    async getindustrynevigate(inid, cate) {
      console.log(inid, cate, 8989);
      const res = await industrynevigate({
        inid: inid,
        cate: cate,
      });
      this.showSeeksGraph(res);
    },
    // 二级图谱
    async twotupu(inid, cate) {
      const res = await twotupu({
        inid: inid,
        cate: cate,
      });
      alltp.nodes.unshift(res.nodes[0]);
      alltp.rootId = res.nodes[0].id;
      this.showSeeksGraph(alltp);
    },
    // 三级图谱
    async threetupu(data, name, id) {
      const res = await threetupu({
        id: id,
        name: name,
      });
      if (thres.nodes && thres.nodes.leng == 0) {
        return false;
      }
      for (var i = 0; i < res.links.length; i++) {
        alltp.links.push(res.links[i]);
      }
      for (var i = 0; i < res.nodes.length; i++) {
        alltp.nodes.push(res.nodes[i]);
      }
      this.showSeeksGraph(alltp);
    },
    showSeeksGraph(query) {
      var __graph_json_data = query;
      this.$refs.seeksRelationGraph.setJsonData(
        __graph_json_data,
        (seeksRGGraph) => {
          // 这些写上当图谱初始化完成后需要执行的代码
        }
      );
    },
    onNodeClick(nodeObject, $event) {
      if (this.cate == 2 && this.secondshow == true) {
        this.product = nodeObject.text.split("↓")[0];

        this.querycondition();
        this.getsdmap("", this.product);
      } else {
        this.product = "";
        this.cate = nodeObject.cate;
        this.inid = nodeObject.id;
        this.querycondition();
        this.getsdmap("", "");
      }

      if (!this.secondshow) {
        return false;
      }
      if (this.allrootid.indexOf(nodeObject.id) == -1) {
        this.allrootid.push(nodeObject.id);
        this.threetupu(
          this.nodedata,
          nodeObject.text.split("↓")[0],
          nodeObject.id
        );
      } else {
        return false;
      }
    },
    onLineClick(lineObject, $event) {
      console.log(lineObject);
    },
    loadNextLevel2Data(index) {},
    // 地图
    async getmapdata() {
      const res = await map({
        inid: this.inid,
        cate: this.cate,
      });
      this.district = res.df;
    },
    async getsdmap(name, product) {
      const res = await sdmap({
        inid: this.inid,
        cate: this.cate,
        district: name,
        product: product,
      });
      this.alilte = res;
    },
    gotocenter(index, name) {
      this.showmap = false;
      this.center2 = index;
      this.color = "transparent !important";
      this.zoom2 = 13;
      this.getsdmap(name);
      this.getchain(name);
    },
    hover() {
      this.show = true;
    },
    out() {
      this.show = false;
    },
    addZoom() {
      this.showmap = true;
      this.zoom = 10;
      this.alilte = [];
    },
    // 获取连图数据
    async getchain(name) {
      const res = await chain({
        inid: this.inid,
        district: name,
      });
      this.upstream = res.nodeList[0];
      this.midstream = res.nodeList[1];
      this.downstream = res.nodeList[2];
    },
  },
};
</script>

<style lang="less" scoped>
.enterprise {
  padding: 0 30px;
}
.left {
  float: left;
  margin-top: 20px;
  background: #fff;
  min-width: 170px;

  .dxhj {
    padding-top: 13px;
    width: 100%;
    height: 38px;
    color: #0052d6;
    img {
      float: left;
      width: 38px;
      height: 38px;
      margin-left: 13px;
    }

    span {
      float: left;
      line-height: 31px;
      height: 18px;
      font-size: 18px;
      font-weight: bold;
      color: #222222;
    }
  }

  /deep/ .el-collapse-item__header {
    height: 40px;
    padding-left: 40px;
    font-size: 14px;
  }

  /deep/ .el-collapse-item__header.is-active {
    color: #fe6a00;
  }
}
.seach {
  display: inline-block;
  width: 54px;
  height: 31px;
  background: #fe6a00;
  border-radius: 4px;
  line-height: 34px;
  font-size: 14px;
  text-align: center;
  font-weight: bold;
  color: #ffffff;
  cursor: pointer;
}
.right {
  background: #fff;
  width: calc(100% - 256px - 20px);
  min-height: 1196px;
  float: right;
  margin-top: 20px;
  margin-left: 10px;
  .ipt {
    width: 100%;
    min-height: 140px;
    li {
      display: block;
      // width: 16%;
      height: 31px;
      float: left;
      font-size: 14px;
      font-weight: 400;
      color: #333333;
      margin: 1% 2% 0;

      /deep/.el-select {
        width: 164px;
        height: 30px;
        input {
          width: 164px;
          height: 30px;
          background: #ffffff;
          border: 1px solid #dddddd;
        }
        /deep/.el-input__icon {
          line-height: 30px !important;
        }
      }
      /deep/.el-input {
        width: 146px;
        height: 30px;
        input {
          width: 146px;
          height: 30px;
          background: #ffffff;
          border: 1px solid #dddddd;
        }
        /deep/.el-icon-circle-close {
          background: url("../../assets/merchantsradar/cuo.png") 50% 50%
            no-repeat;
        }
        .el-icon-circle-close:before {
          content: "";
        }
      }
    }
  }
  .zhanshi {
    height: 40px;
    display: flex;
    align-items: center;
    margin: 0% 2% 0;
    .lilast {
      list-style: none;
      span {
        display: block;
        width: 168px;
        height: 31px;
        float: left;
        font-size: 14px;
        font-weight: 400;
        color: #666666;
        line-height: 31px;
        border: 1px solid #dddddd;
        margin-right: 18px;
        padding-right: 10px;

        dl {
          float: right;
          color: #fe6a00;
          cursor: pointer;
        }
      }

      span:nth-child(2) {
        width: 182px;
      }
    }
  }

  .tupu {
    width: 70%;
    height: 48.3%;
    float: left;
    margin-top: 20px;
    li {
      display: block;
      width: 97%;
      min-height: 38px;
      // padding-left: 41px;
      i {
        transition: all 0.25s;
        &::before {
          display: inline-block;
          content: "\e6e0";
          font-size: 16px;
        }
      }
      ul {
        li {
          font-size: 14px;
          min-height: 24px;
          line-height: 24px;
          margin-left: 20px;
          margin-bottom: 0;
        }
      }
      .spannth {
        background: #fe6a00;
        color: #fff;
      }

      span {
        width: 114px;
        height: 34px;
        border: 1px solid #fe6a00;
        float: left;
        line-height: 34px;
        font-size: 16px;
        font-weight: 400;
        color: #fe6a00;
        border-radius: 10px;
        cursor: pointer;
        margin-left: 20px;
        img {
          float: left;
          margin-top: 10px;
          margin-left: 10px;
        }
      }

      .span {
        float: right;
        border: none;
        text-align: left;
        line-height: 44px;
        color: #000;
        display: flex;
        align-items: center;
        img {
          margin: 0;
          margin-right: 5px;
        }
      }
    }

    .t-Center {
      width: 100%;
      height: 517px;
      // border: 1px solid #ccc;
      margin-top: 30px;
      margin-left: 9px;
      background: url("../../assets/industr/bjt.png") no-repeat;
      background-size: 100% 100%;
    }

    .t-bottom {
      margin-top: 21px;
      margin-left: 9px;
      width: 100%;
      height: 600px;
      padding-bottom: 30px;
      // border: 1px solid #ccc;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  //链图模式
  .lian_tubox {
    margin-bottom: 25px;
    overflow: hidden;
    zoom: 1;

    .lian_tu_top {
      overflow: hidden;
      zoom: 1;
      margin: 20px 0 20px 20px;

      li {
        padding: 0;
        overflow: hidden;
        zoom: 1;
        float: left;
        height: 63px;
        line-height: 63px;
        vertical-align: top;
        text-align: center;
        font-size: 17px;
        background: linear-gradient(90deg, #22baf7, #37e0f7);
        color: #fff;

        img {
          height: 63px;
          display: inline;
          float: left;
          vertical-align: top;
        }
        img:last-child {
          float: right;
        }
      }
    }
  }

  .zhuzhuang {
    width: 27%;
    height: 86%;
    margin-right: 1%;
    float: right;

    .top {
      width: 100%;
      height: 133px;
      // border: 1px solid #ccc;
      margin: 1% 4%;
      background: url("../../assets/industr/-s-qiye.png") no-repeat;
      background-size: 100% 100%;
      border-radius: 10px;

      .min-box {
        position: relative;
        float: left;
        width: 33.2%;
        height: 49.5%;
        font-size: 20px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #ffffff;

        span {
          display: block;
          float: left;
          width: 100%;
          height: 19px;
          text-align: center;
          line-height: 64px;
        }

        span:nth-child(2) {
          margin-top: 11px;
          font-size: 15px;
        }

        img {
          position: absolute;
          top: -46px;
          right: 0;
        }
      }
    }

    .centerc {
      width: 92%;
      height: 280px;
      margin: 0 17px 28px 17px;
    }

    .bottom {
      width: 92%;
      height: 50%;
      // border: 1px solid #ccc;
      margin: 0 17px 0 17px;
      position: relative;
      .ttit {
        display: block;
        width: 100%;
        text-align: left;
        margin-bottom: 15px;
      }

      ul li {
        display: block;
        width: 100%;
        height: 40px;
        font-size: 12px;
        line-height: 40px;

        span {
          display: block;
          width: 20%;
          height: 100%;
          float: left;
          img {
            margin-right: 5px;
          }
        }

        span:nth-child(1) {
          width: 13%;
          text-align: center;
        }

        span:nth-child(2) {
          width: 54%;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          text-align: left;
          cursor: pointer;
        }

        span:nth-child(3) {
          width: 33%;
        }

        /deep/ .el-rate {
          line-height: 40px;
        }

        /deep/ .el-rate__icon {
          font-size: 12px;
          margin: 0;
        }
      }

      ul li:nth-child(odd) {
        background: #f3f6fb;
      }
      .fenye {
        position: absolute;
        bottom: -50px;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
  /deep/.rel-map {
    background-color: transparent;
  }
  /deep/.rel-map-canvas {
    width: 100% !important;
    height: 100% !important;
    background-color: transparent;
  }
  /deep/.rel-node-shape-1 {
    padding: 0;
  }
}
.map {
  width: 100%;
  height: 100%;
}
.bm-view {
  width: 100%;
  height: 100%;
  /deep/.BMapLabel {
    background-color: transparent !important;
    border: 0 !important;
    color: rgb(121, 22, 22) !important;
  }
}
/deep/.rel-map {
  background-color: transparent;
}
/deep/.rel-map-canvas {
  width: 100% !important;
  height: 100% !important;
  background-color: transparent;
}
/deep/.rel-node-shape-1 {
  padding: 0;
}

//下拉部分
/deep/.el-collapse {
  border: 0;
  background: #c7e3ff;
  padding: 3%;
  margin-left: 2%;
  width: 30%;
  float: left;
  height: 340px;
  overflow-y: auto;
}

/deep/.el-collapse:nth-of-type(2) {
  background: #dbedff;
}

/deep/.el-collapse:nth-of-type(3) {
  background: #eef7ff;
}
::-webkit-scrollbar {
  width: 5px;
  background-color: #eee;
}

/*定义滚动条轨道 内阴影+圆角*/
::-webkit-scrollbar-track {
  border-radius: 7px;
  background-color: #eee;
}

::-webkit-scrollbar-thumb {
  border-radius: 7px; /*滚动条的圆角*/
  background-color: #9dcaf7; /*滚动条的背景颜色*/
}

/deep/.el-collapse-item__header {
  font-size: 18px;
  text-align: left;
  vertical-align: middle;
  background: none;
  font-weight: bold;
  border-bottom: 0;
  margin-bottom: 5px;
  // height: 36px;
  height: auto;
  line-height: 30px;
  min-width: 180px;
  // overflow: hidden;
  // text-overflow: ellipsis;
  // white-space: nowrap;
  img {
    margin-right: 6px;
  }
}
/deep/.el-collapse-item__wrap {
  background: none;
  text-align: left;
  border-bottom: 0;
  font-weight: 400;
  color: #555;
  padding-left: 10px;
}
/deep/.el-collapse-item__content {
  padding-bottom: 0;
  li {
    font-size: 16px;
    margin-bottom: 15px;
    text-align: left;
    line-height: 30px;
    cursor: pointer;
  }
}
/deep/.BMap_Marker {
  width: 25px !important;
  height: 15px !important;
  div {
    width: 25px !important;
    height: 15px !important;
    img {
      margin: 0 !important;
      // width: 15px !important;
      height: 15px !important;
    }
  }
}
.isactive {
  transform: rotate(90deg);
}
</style>

<template>
  <div class="home">
    <img class="logo" src="@/assets/home/logo.png" alt />
    <div class="dabox">
      <div class="dabox-box">
        <img id="centerimg" src="@/assets/home/zhaoshangjiashi (1).png" alt />
        <img
          class="zhong"
          @click="loginshow = true"
          src="@/assets/home/jiashi.png"
          alt
        />
        <img
          class="s360"
          @click="loginshow = true"
          src="@/assets/home/360.png"
          alt
        />
        <img
          class="ali"
          @click="loginshow = true"
          src="@/assets/home/map.png"
          alt
        />
        <img
          class="gmli"
          @click="loginshow = true"
          src="@/assets/home/gmli.png"
          alt
        />
        <img
          class="leida"
          @click="loginshow = true"
          src="@/assets/home/leida.png"
          alt
        />
        <!-- <img
          class="map"
          @click="loginshow = true"
          src="@/assets/home/zbh.png"
          alt
        /> -->
        <img
          class="pygu"
          @click="loginshow = true"
          src="@/assets/home/pygu.png"
          alt
        />
        <img
          class="tupu"
          @click="loginshow = true"
          src="@/assets/home/tupu.png"
          alt
        />
      </div>
      <div class="cc0" @click="loginshow = true">
        注册登录
      </div>
      <div
        class="cc0 cc1"
        @click="(loginshow2 = true), (loginshow = !loginshow)"
      >
        管理员登录
      </div>
    </div>
    <div class="wfbox" v-if="loginshow">
      <img
        class="close"
        @click="loginshow = false"
        src="@/assets/home/close.png"
        alt
      />
      <img class="left-t" src="../../assets/login/zot.png" alt />
      <div v-if="ddlu1" class="right-log">
        <p>
          <span @click="vmhm(0)" style="color: #fe6a00">访客</span>
          <span @click="vmhm(1)">内部</span>
        </p>

        <div class="ipt1">
          <span class="yiba">+86</span>
          <input
            placeholder="手机号码"
            v-model="mobile"
            style="padding-left: 80px; width: 295px"
          />
        </div>
        <div class="ipt2">
          <span class="yzm" v-if="timeshow == 0" @click="getyzm"
            >获取验证码</span
          >
          <span class="yzm" v-if="timeshow == 1" @click="getyzm"
            >{{ time }}秒后可发送</span
          >
          <span class="yzm" v-if="timeshow == 2" @click="getyzm">重新发送</span>
          <input v-model="smsCode" placeholder="请输入验证码" />
        </div>
        <el-button class="btn" @click="gotologin">登录</el-button>
        <div class="wxfg">
          <img @click="gotoewm" src="../../assets/login/weixin.png" />
          <img src="../../assets/login/dingding.png" />
        </div>
      </div>
      <div v-if="ddlu4" class="right-log">
        <p>
          <span style="margin-left: 165px">绑定手机号</span>
        </p>

        <div class="ipt1">
          <span class="yiba">+186</span>
          <input
            placeholder="手机号码"
            v-model="mobile"
            style="padding-left: 80px; width: 295px"
          />
        </div>
        <div class="ipt2">
          <span class="yzm" v-if="timeshow == 0" @click="getyzm"
            >获取验证码</span
          >
          <span class="yzm" v-if="timeshow == 1" @click="getyzm"
            >{{ time }}秒后可发送</span
          >
          <span class="yzm" v-if="timeshow == 2" @click="getyzm">重新发送</span>
          <input v-model="smsCode" placeholder="请输入验证码" />
        </div>
        <el-button class="btn" @click="gotologin">登录</el-button>
      </div>
      <div v-if="ddlu2" class="right-log">
        <p>
          <span @click="vmhm(0)">访客</span>
          <span style="color: #fe6a00" @click="vmhm(1)">内部</span>
        </p>

        <div class="ipt1">
          <input v-model="pwdmobile" placeholder="请输入手机号" />
        </div>
        <div class="ipt2">
          <input v-model="password" type="password" placeholder="请输入密码" />
        </div>
        <div
          class="tips"
          v-if="tips.length > 1"
          style="margin-bottom:-20px;color:red"
        >
          {{ tips }}
        </div>
        <el-button class="btn" @click="passlogin">登录</el-button>
        <div class="wuzh">
          暂无账号 ?
          <span
            style="color: #fe6a00"
            @click="$router.push({ name: 'registered' })"
          >
            <strong>立即注册</strong>
          </span>
        </div>
      </div>
    </div>
    <div class="wfbox" v-if="loginshow2">
      <img
        class="close"
        @click="(loginshow2 = false), (loginshow = false)"
        src="@/assets/home/close.png"
        alt
      />
      <img class="left-t" src="../../assets/login/zot.png" alt />
      <div class="right-log">
        <p>
          <span style="margin-left: 165px">管理员登录</span>
        </p>
        <div class="ipt1">
          <input v-model="name" placeholder="请输入用户名" />
        </div>
        <div class="ipt2">
          <input
            v-model="passwordgld"
            type="password"
            placeholder="请输入密码"
          />
        </div>
        <el-button class="btn" @click="glylogin">登录</el-button>
      </div>
    </div>
    <el-dialog
      title="请绑定手机号"
      :visible.sync="dialogFormVisible"
      center
      width="50%"
    >
      <div class="ipt1">
        <input v-model="codemobile" placeholder="请输入手机号" />
      </div>
      <div class="ipt2">
        <span class="yzm" v-if="timeshow == 0" @click="getyzm2"
          >获取验证码</span
        >
        <span class="yzm" v-if="timeshow == 1" @click="getyzm2"
          >{{ time }}秒后可发送</span
        >
        <span class="yzm" v-if="timeshow == 2" @click="getyzm2">重新发送</span>
        <input v-model="ccode" placeholder="请输入验证码" />
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="codelogin">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import store from "../../store";
import {
  getyzm,
  gotologin,
  bypwdlogin,
  administratorlogin,
  login_byvx,
  binding,
} from "@/api/index";
export default {
  name: "home",
  data() {
    return {
      dialogFormVisible: false,
      ddlu1: true,
      ddlu2: false,
      ddlu3: false,
      ddlu4: false,
      loginshow: false,
      time: "60",
      timeshow: 0,
      mobile: "",
      smsCode: "",
      pwdmobile: "",
      password: "",
      passwordgld: "",
      name: "",
      loginshow2: false,
      code: "",
      openid: "",
      unionid: "",
      mobile: "",
      ses_code: "",
      ccode: "",
      codemobile: "",
      tips: "",
      timer: null,
    };
  },
  created() {
    localStorage.setItem("login", "");
    var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
    this.code = window.location.search.split("=")[1];
    console.log(this.code);
    if (this.code == undefined) {
      // console.log("not find code");
    } else {
      this.login_byvx();
    }
    // this.countDown();
  },
  mounted() {
    window.onpopstate = () => {
      if (!localStorage.getItem("login")) {
        this.$router.push({ path: "/" });
      }
    };
  },
  methods: {
    countDown(time) {
      this.timer == null ? "" : clearInterval(this.timer);
      this.timer = setInterval(() => {
        let m = this.Rounding(Math.floor(time / 60));
        let s = this.Rounding(time % 60);
        time -= 1;
        this.tips = "您的账户已锁定,距解锁还剩 " + m + " 分 " + s + " 秒";
        console.log(this.tips);
        if (time <= 0) {
          clearInterval(this.timer);
          this.tips = "";
        }
      }, 1000);
    },
    Rounding(value) {
      return value < 10 ? "0" + value : value;
    },

    gito() {},
    creatCalss(index) {
      let _class = "ball_" + (index + 1);
      return _class;
    },
    vmhm(a) {
      this.loginshow = true;
      if (a == 0) {
        (this.ddlu1 = true), (this.ddlu2 = false), (this.ddlu3 = false);
      }
      if (a == 1) {
        (this.ddlu1 = false), (this.ddlu2 = true), (this.ddlu3 = false);
      }
      if (a == 2) {
        (this.ddlu1 = false), (this.ddlu2 = false), (this.ddlu3 = true);
      }
    },
    // 获取验证码
    async getyzm() {
      this.time = 60;
      this.timeshow = 0;
      if (this.mobile == "") {
        this.$message({
          message: "请输入手机号",
          type: "error",
        });
      }
      const res = await getyzm({
        mobile: this.mobile,
      });
      if (res.code == 4900) {
        this.$message.error(res.msg);
        this.timeshow = 0;
      } else if (res.code == 0) {
        this.$message({
          message: "发送成功",
          type: "success",
        });
        this.timeshow = 1;
        this.countdown();
      }
      // console.log(res);
    },
    // 获取验证码
    async getyzm2() {
      this.time = 60;
      this.timeshow = 0;
      if (this.codemobile == "") {
        this.$message({
          message: "请输入手机号",
          type: "error",
        });
      }
      const res = await getyzm({
        mobile: this.codemobile,
      });
      if (res.code == 4900) {
        this.$message.error(res.msg);
        this.timeshow = 0;
      } else if (res.code == 0) {
        this.$message({
          message: "发送成功",
          type: "success",
        });
        this.timeshow = 1;
        this.countdown();
      }
    },
    // 验证码登录
    async gotologin() {
      if (this.smsCode == "") {
        this.$message({
          message: "请输入验证码",
          type: "error",
        });
      }
      const res = await gotologin({
        mobile: this.mobile,
        sms_code: this.smsCode,
      });
      if (res.code == 4004) {
        this.$message.error(res.msg);
      } else if (res.code == 0) {
        this.$message({
          message: "登录成功",
          type: "success",
          duration: 1000,
          showClose: true,
        });
        localStorage.setItem("login", res.token);
        this.$store.commit("setFlag", res.flag);
        this.$router.push({ name: "Thecockpit" });
      }
    },
    gotoewm() {
      let encodeurl = encodeURI("http://sem.industrychain.online");
      window.location.href =
        "https://open.weixin.qq.com/connect/qrconnect?appid=wxb44559f2cbe1efcf&redirect_uri=http%3a%2f%2fsem.industrychain.online&response_type=code&scope=snsapi_login&state=STATE#wechat_redirect";
    },
    // 18230104816
    // 密码登录
    async passlogin() {
      if (this.pwdmobile == "") {
        this.$message({
          message: "请输入手机号",
          type: "error",
        });
        return false;
      }
      if (this.password == "") {
        this.$message({
          message: "请输入密码",
          type: "error",
        });
        return false;
      }
      const res = await bypwdlogin({
        mobile: this.pwdmobile,
        password: this.password,
      });
      if (res.code == 4004) {
        this.$message.error(res.msg);
      } else if (res.code == 4005) {
        this.countDown(res.lock_time);
        this.$message.error(res.msg);
      } else if (res.code == 4201) {
        this.countDown(res.time);
        this.$message.error(res.msg);
      } else if (res.code == 0) {
        this.$message({
          message: "登录成功",
          type: "success",
          duration: 1000,
          showClose: true,
        });
        localStorage.setItem("login", res.token);
        this.$store.commit("setFlag", res.flag);
        this.$router.push({ name: "Thecockpit" });
      } else {
        this.$message.error(res.msg);
      }
    },
    // 管理员
    async glylogin() {
      if (this.name == "") {
        this.$message({
          message: "请输入用户名",
          type: "error",
        });
        return false;
      }
      if (this.passwordgld == "") {
        this.$message({
          message: "请输入密码",
          type: "error",
        });
        return false;
      }
      const res = await administratorlogin({
        name: this.name,
        password: this.passwordgld,
      });
      if (res.code == 4004) {
        this.$message.error(res.msg);
      } else if (res.code == 0) {
        this.$message({
          message: "登录成功",
          type: "success",
          duration: 1000,
          showClose: true,
        });
        localStorage.setItem("login", res.token);
        this.$store.commit("setFlag", res.flag);
        this.$router.push({ name: "systemglzuzhi" });
      } else {
        this.$message.error(res.msg);
      }
    },
    countdown() {
      var that = this;
      setInterval(function() {
        if (that.time > 0) {
          that.time--;
        } else {
          that.timeshow = 2;
        }
      }, 1000);
    },
    async login_byvx() {
      const res = await login_byvx({
        code: this.code,
      });
      // console.log(res);
      if (res.code == 4104) {
        this.dialogFormVisible = true;
        this.openid = res.user_info.openid;
        this.unionid = res.user_info.unionid;
      } else if (res.code == 0) {
        this.$message({
          message: "登录成功",
          type: "success",
          duration: 1000,
          showClose: true,
        });
        localStorage.setItem("login", res.token);
        this.$store.commit("setFlag", res.flag);
        this.$router.push({ name: "Thecockpit" });
      } else {
        this.$message.error(res.msg);
      }
    },
    async codelogin() {
      const res = await binding({
        openid: this.openid,
        unionid: this.unionid,
        mobile: this.codemobile,
        sms_code: this.ccode,
      });
      if (res.code == 0) {
        this.$message({
          message: "登录成功",
          type: "success",
          duration: 1000,
          showClose: true,
        });
        localStorage.setItem("login", res.token);
        this.$store.commit("setFlag", res.flag);
        this.$router.push({ name: "Thecockpit" });
      } else {
        this.$message.error(res.msg);
      }
    },
  },
};
</script>

<style lang="less" scoped>
@import "../../style/home";

.home {
  background: url("../../assets/home/beijing.png") no-repeat;
  width: 100%;
  height: 100%;
  background-size: 100% 100%;
  position: absolute;
  filter: progid:DXImageTransform.Microsoft.AlphaImageLoader(src='background-size:100% 100%',sizingMethod='scale');
  .logo {
    width: 1300px;
    height: 113px;
  }
  .dabox {
    #centerimg {
      width: 460px;
      position: absolute;
      left: 49%;
      top: 64%;
      transform: translate(-50%, -50%);
    }
    img {
      position: absolute;
    }

    .zhong {
      width: 196px !important;
      top: 12%;
      left: 39%;
    }

    .xian {
      top: 24%;
      left: 21%;
      width: 55%;
    }

    .dabox-box {
      width: 1050px;
      height: 470px;
      margin: 0 auto;
      position: relative;
      background: url("../../assets/home/xian.png") no-repeat;
      background-size: cover;
      margin-top: 100px;
      img {
        width: 108px;
      }

      .s360 {
        top: 8%;
        left: 93%;
      }

      .tupu {
        top: 11%;
        left: 23%;
      }

      .leida {
        top: 57%;
        left: -3%;
      }

      .map {
        top: 43%;
        left: 20%;
      }

      .gmli {
        top: 81%;
        left: 35%;
      }

      .pygu {
        top: 46%;
        left: 80%;
      }

      .ali {
        top: -13%;
        left: 57%;
      }
    }
    .login {
      display: block;
      width: 7%;
      line-height: 35px;
      padding: 3px;
      border: 1px solid #ccc;
      font-size: 18px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #ffffff;
      position: absolute;
      right: 12%;
      top: 2%;
    }
  }

  #center,
  .con_bg {
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    // background: url(../../src/assets/universe_background.png) no-repeat;
    background-size: 100% 100%;
  }
}
.wfbox {
  margin: auto;
  width: 908px;
  height: 434px;
  background: #ffffff;
  border-radius: 10px;
  position: fixed;
  top: 30%;
  left: 50%;
  transform: translateX(-50%);
  .close {
    position: absolute;
    right: 30px;
    top: 10px;
    cursor: pointer;
  }
  .left-t {
    width: 389px;
    height: 226px;
    float: left;
    margin: 104px 0 0 47px;
  }

  .right-log {
    float: right;
    width: 427px;
    height: 100%;

    p {
      margin-top: 51px;
      position: absolute;
      z-index: 100;

      span {
        float: left;
        font-size: 18px;
        color: #666666;
        font-weight: bold;
        line-height: 16px;
        margin-left: 121px;
        margin-bottom: 35px;
        cursor: pointer;
      }
    }

    .ipt1 {
      position: relative;
      margin-top: 104px;

      .yiba {
        position: absolute;
        top: 18px;
        left: 52px;
        font-size: 14px;
        font-weight: 400;
        color: #333333;
        z-index: 10;
      }
    }

    .ipt2 {
      position: relative;

      .yzm {
        position: absolute;
        top: 18px;
        right: 52px;
        font-size: 14px;
        font-weight: 400;
        color: #fe6a00;
        cursor: pointer;
      }
    }

    input {
      width: 349px !important;
      height: 50px;
      background: #f0f8ff;
      box-shadow: 2px 3px 7px 0px rgba(0, 132, 255, 0.05);
      border-radius: 25px;
      outline: none;
      border: none;
      padding-left: 26px;
      margin-bottom: 18px;
    }

    .btn {
      margin-top: 26px;
      width: 375px;
      height: 50px;
      background: #fe6a00;
      border-radius: 25px;
      outline: none;
      border: none;
      font-size: 14px;
      font-weight: bold;
      color: #ffffff;
    }

    .wxfg {
      margin-top: 42px;

      img {
        width: 39px;
        height: 39px;
        margin-left: 25px;
        margin-right: 25px;
      }
    }

    .wuzh {
      margin-top: 36px;
      font-size: 14px;
      font-weight: 400;
      color: #999999;
      span {
        cursor: pointer;
      }
    }
  }
}
.cc0 {
  position: absolute;
  top: 40px;
  right: 100px;
  display: block;
  width: 151px;
  height: 50px;
  background: url("../../assets/home/guanliyuandenglu.png") no-repeat;
  background-size: 100% 100%;
  cursor: pointer;
  font-size: 18px;
  font-weight: 400;
  color: #ffffff;
  line-height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.cc1 {
  width: 100px;
  height: 30px;
  font-size: 16px;
  top: unset;
  bottom: 20px;
}
.bjt {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -100;
}
/deep/.el-dialog__body {
  .ipt1 {
    position: relative;
    text-align: center;
    .yiba {
      position: absolute;
      top: 18px;
      left: 52px;
      font-size: 14px;
      font-weight: 400;
      color: #333333;
      z-index: 10;
    }
  }

  .ipt2 {
    position: relative;
    text-align: center;
    .yzm {
      position: absolute;
      top: 18px;
      right: 290px;
      font-size: 14px;
      font-weight: 400;
      color: #fe6a00;
      cursor: pointer;
    }
  }

  input {
    width: 349px !important;
    height: 50px;
    background: #f0f8ff;
    box-shadow: 2px 3px 7px 0px rgba(0, 132, 255, 0.05);
    border-radius: 25px;
    outline: none;
    border: none;
    padding-left: 26px;
    margin-bottom: 18px;
  }
}
</style>

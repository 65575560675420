import axios from 'axios'
import JSONbig from 'json-bigint'
import router from '../router'


// const request = axios.create({
//     baseURL: 'http://tyzhaoshanggraph.industrychain.online/'
// })
const request = axios.create({
    baseURL: 'http://touzi.cjwltzj.taiyuan.gov.cn/'
})
/*
 处理后端返回数据中数字超出 JavaScript 安全整数范围
 */
request.defaults.transformResponse = [function (data) {
    try {
        return JSONbig.parse(data)
        // 如果是 json 格式字符串，那就转换并返回给后续使用
    } catch (err) {
        // 报错就意味着 data 不是 json 格式字符串，这里就直接原样返回给后续使用
        return data
    }
}]

// Add a request interceptor
request.interceptors.request.use(function (config) {
    // dataType: 'jsonp',
    // axios.defaults.withCredentials = true;
    // let login =
    config.headers = {
        token: localStorage.getItem('login')
    }
    if (config.url == "/api/user/login_bypwd") {
        return config;
    } else { // 在此设置请求头统一携带token
        config.headers.token = localStorage.getItem('login')
        return config;
    }
}, function (error) {
    // Do something with request error
    return Promise.reject(error)
})

// Add a response interceptor
request.interceptors.response.use(function (response) {
    if (response.data.code == '4101') {
        const url = window.location.href;
        localStorage.setItem("path", url);
        localStorage.removeItem("token")
        setTimeout(function () {
            router.replace({
                path: '/'
            });
        }, 1000)
    }
    return response.data.data || response.data
    // return response
}, function (error) {
    // Do something with response error
    return Promise.reject(error)
})

export default request

<template>
  <div>
    <div class="head">
      <div class="bnti">太原市重点产业招商图谱平台</div>
      <div class="right">
        <div @click="exitLogin">
          <img src="../assets/login/tkdd.png" alt />
          <span>退出登录</span>
        </div>
      </div>
    </div>
    <div class="head2"></div>
  </div>
</template>

<script>
export default {
  name: "heandtop",
  data() {
    return {};
  },
  created() {},
  methods: {
    exitLogin() {
      localStorage.setItem("login", "");
      // console.log(this.$store);
      this.$store.commit("setFlag", 0);
      this.$router.replace({ path: "/" });
    },
  },
};
</script>

<style lang="less" scoped>
.head2 {
  height: 100px;
}
.head {
  position: fixed;
  z-index: 1999;
  width: 100%;
  height: 100px;
  min-width: 1290px;
  background: #fe6a00;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .bnti {
    font-size: 24px;
    color: #ffffff;
    width: 230px;
    flex-wrap: nowrap;
    white-space: nowrap;
    margin-left: 30px;
  }

  .center {
    // width: 1000px;
    height: 100%;
    ul {
      height: 100%;
      display: flex;
      align-items: center;
    }

    li {
      display: block;
      width: 110px;
      list-style-type: none;
      float: left;
      cursor: default;
      div {
        width: 100%;
        height: 50px;
        display: flex;
        align-items: flex-end;
        justify-content: center;
      }
      img {
        // width: 20px;
        // height: 20px;
        // padding-left: 39px;
        // margin-right: 3px;
      }

      span {
        display: block;
        width: 100%;
        height: 35px;
        color: #ffefe4;
        font-size: 16px;
        margin-top: 10px;
      }
    }
    li.active {
      span {
        font-weight: bold;
      }
      &:after {
        display: block;
        content: "";
        width: 28px;
        height: 4px;
        background: #ffffff;
        border-radius: 2px;
        margin: 0 auto;
        // margin-top: 10px;
      }
    }
  }
  .right {
    margin-right: 30px;
    width: 120px;
    height: 42px;
    background: rgb(254, 121, 26);
    flex-wrap: nowrap;
    flex-shrink: 0;
    display: flex;
    cursor: pointer;
    div {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    img {
      width: 20px;
      height: 20px;
      // line-height: 42px;
      margin-right: 3px;
    }
    span {
      line-height: 42px;
      font-size: 15px;
      font-weight: 400;
      color: #ffffff;
      padding-right: 10px;
      white-space: nowrap;
    }
  }
}
</style>

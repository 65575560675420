<template>
  <div>
    <div class="navleft">
      <div class="nav">
        <img src="@/assets/merchantsradar/daohang.png" alt />产业导航
      </div>
      <el-tree
        :data="navleftdata"
        :props="defaultProps"
        ref="MyTree"
        accordion
        node-key="id"
        @node-click="handleNodeClick"
        :default-expanded-keys="[4]"
        @data-change="handlechange"
      ></el-tree>
    </div>
  </div>
</template>
<script>
import { industryfields } from "@/api/index";
export default {
  name: "navleft",
  data() {
    return {
      defaultProps: {
        children: "children",
        label: "label",
      },
      navleftdata: [],
    };
  },
  props: ["tagnumx"],
  mounted() {},
  created() {
    this.getnavdata();
  },
  methods: {
    handleNodeClick(data) {
      this.$emit("cateid", data);
    },
    handlechange() {
      console.log(1111111111111);
      this.$nextTick(() => {
        this.$refs.MyTree.setCurrentKey(32);
        console.log(this.$refs.MyTree.getCurrentKey());
      });
    },
    async getnavdata() {
      const res = await industryfields();
      this.navleftdata = res.navleftdata;
      this.$nextTick(() => {
        if(this.tagnumx == 2 || this.tagnumx == 3){
          this.$refs.MyTree.setCurrentKey(32);
        }
      });
      localStorage.setItem("inid", this.navleftdata[0].id);
      localStorage.setItem("inidchild", this.navleftdata[0].children[0].id);
    },
  },
};
</script>
<style lang="less" scoped>
.navleft {
  background-color: #fff;
  width: 256px;
  min-height: 380px;
  float: left;
  // padding: 0 15px;
  .nav {
    text-align: left;
    font-size: 18px;
    font-weight: bold;
    color: #222222;
    margin-bottom: 2px;
    height: 40px;
    padding-top: 10px;
    display: flex;
    align-items: center;
    img {
      margin-top: 5px;
    }
  }
  /deep/.el-tree-node__label {
    font-size: 16px;
    color: #222222;
  }
  /deep/.el-tree > div > div {
    background-repeat: no-repeat;
    background-size: 16px 16px;
    background-position: 90% center;
  }
  /deep/.el-tree > div:first-child > div:first-child {
    background-image: url("../assets/merchantsradar/nav1.png");
  }
  /deep/.el-tree > div:nth-child(2) > div:first-child {
    background-image: url("../assets/merchantsradar/nav3.png");
  }
  /deep/.el-tree > div:nth-child(3) > div:first-child {
    background-image: url("../assets/merchantsradar/nav2.png");
  }
  /deep/.el-tree > div:nth-child(4) > div:first-child {
    background-image: url("../assets/merchantsradar/nav4.png");
  }
  /deep/.el-tree > div:nth-child(5) > div:first-child {
    background-image: url("../assets/merchantsradar/nav5.png");
  }
  /deep/.el-tree > div:nth-child(6) > div:first-child {
    background-image: url("../assets/merchantsradar/nav6.png");
  }
  // /deep/.el-tree>div>.el-tree-node__content:nth-child(7){
  //   background: url("../assets/merchantsradar/nav1.png");
  // }

  /deep/.is-expanded {
    .el-tree-node__content {
      .el-tree-node__label {
        color: #fe6a00;
      }
    }
    .el-tree-node__children {
      .el-tree-node__label {
        color: rgba(102, 102, 102, 1);
      }
    }
  }
  /deep/.el-tree-node__content {
    height: 50px;
    border-bottom: 1px solid #eee;
    position: relative;

    .el-icon-caret-right:before {
      content: "";
      width: 9px;
      height: 8px;
      display: block;
      background: url("../assets/merchantsradar/xia.png") no-repeat;
    }
    // .el-icon-caret-right
    .expanded:before {
      transform: rotate(-90deg);
      background: url("../assets/merchantsradar/shang.png") no-repeat;
    }
    .el-tree-node__expand-icon.is-leaf:before {
      background-image: unset;
    }
  }
  /deep/.is-current > .el-tree-node__content > .el-tree-node__label {
    color: #fe6a00 !important;
  }
  /deep/.is-current > .el-tree-node__content {
    background-color: #f5f7fa;
  }
  /deep/.addcalss
    .el-tree-node:nth-child(1).is-expanded
    > .el-tree-node__children
    .el-tree-node:nth-child(1)
    .el-tree-node__content
    .el-tree-node__label {
    color: #fe6a00 !important;
  }
}
</style>

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import scroll from 'vue-seamless-scroll'
Vue.use(scroll)
import echarts from 'echarts'
Vue.prototype.$echarts = echarts
import './style/style.css'
Vue.config.productionTip = false


Vue.use(ElementUI)
import ViewUI from 'view-design';
Vue.use(ViewUI);
import 'view-design/dist/styles/iview.css';

// 公用组件
import heand from './components/heandtop'
Vue.component('heand', heand)
import headtopsystemgl from './components/headtopsystemgl'
Vue.component('headtopsystemgl', headtopsystemgl)
import merchantsradarleft from './components/merchantsradarleft'
Vue.component('navleft', merchantsradarleft)
import ziyuannavleft from './components/ziyuanguanli'
Vue.component('ziyuannavleft', ziyuannavleft)
import systemglleft from './components/systemglleft'
Vue.component('systemglleft', systemglleft)

// 百度地图
import BaiduMap from 'vue-baidu-map'
Vue.use(BaiduMap, {
  // ak 是在百度地图开发者平台申请的密钥 详见 http://lbsyun.baidu.com/apiconsole/key */
  ak: 'YjnbzlnCqjvgj4PR49dSr8nK3NaQxBYe'
})
// import '@babel/polyfill';
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
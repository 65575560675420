<template>
  <div id="app">
    <keep-alive
      include="projectgl,dynamic,favq,investmentmap,selectionaddpinggu,portrait,Thecockpit"
    >
      <router-view />
    </keep-alive>
  </div>
</template>

<script>
export default {
  name: "App",
  data() {
    return {
      lastTime: new Date().getTime(),
      currentTime: new Date().getTime(),
      timeOut: 900000, //设置超时时间： 15分
      msg: null,
    };
  },
  created() {
    document.onmouseover = (e) => {
      this.lastTime = new Date().getTime();
    };
    window.setInterval(this.toLoginPage, 1000);
  },
  methods: {
    toLoginPage() {
      this.currentTime = new Date().getTime(); //更新当前时间
      if (this.currentTime - this.lastTime > this.timeOut) {
        let login = localStorage.getItem("login");
        if (login != "") {
          this.msg = this.$message({
            message: "15分钟内无操作，已自动退出登录",
            type: "warning",
            showClose: true,
            duration: 0,
          });
          console.log("------------");
          console.log(this.msg);
          console.log("------------");
          localStorage.setItem("login", "");
          this.$store.commit("setFlag", 0);
          if (this.$route.name != "home") {
            this.$router.replace({ path: "/" });
          }
        }
      }
      if (this.msg != null && this.$route.name != "home") {
        this.msg.close();
      }
    },
  },
};
</script>

<style>
* {
  margin: 0;
  padding: 0;
}
body,
html {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
}
#app {
  height: 100%;
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  min-width: 1340px;
  /* overflow-x: hidden; */
}
.rel-node-checked {
  -moz-transform: none !important;
}
</style>

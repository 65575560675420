import request from '@/utils/requist'
import CryptoJS from '../utils/aes_util'



export const industry = ({
    district
}) => {
    return request({
        method: 'POST',
        url: '/api/attract/industry/propol',
        data: {
            district
        }
    })
}
export const complist = ({
    classify, type, district, page, perpage
}) => {
    return request({
        method: 'POST',
        url: '/api/attract/industry/showList',
        data: {
            classify, type, district, page, perpage
        }
    })
}
export const industrymenu = ({
    district
}) => {
    return request({
        method: 'post',
        url: '/api/attract/industry/menu',
        data: {
            district
        }
    })
}
export const industrynevigate = ({
    inid,
    cate
}) => {
    return request({
        method: 'post',
        url: '/api/atlas/industry/nevigate',
        data: {
            inid,
            cate
        }
    })
}
export const twotupu = ({
    inid,
    cate
}) => {
    return request({
        method: 'post',
        url: '/api/atlas/ej/zero',
        data: {
            inid,
            cate
        }
    })
}
export const industryvideo = ({
    district
}) => {
    return request({
        method: 'post',
        url: '/api/attract/industry/video',
        data: {
            district
        }
    })
}
export const threetupu = ({
    id,
    name
}) => {
    return request({
        method: 'post',
        url: '/api/atlas/ej/next',
        data: {
            id,
            name
        }
    })
}
// /api/atlas/ej/next
// 产业导航
export const industryfields = () => {
    return request({
        method: 'get',
        url: '/api/atlas/get_fields',
    })
}
// 产业发展图谱页筛选条件获取
export const screening = () => {
    return request({
        method: 'get',
        url: '/api/atlas/industry/field',
    })
}
// 产业发展图谱页太原市企业列表
export const enterprise1 = ({
    inid,
    cate,
    page,
    perpage,
    entype,
    qualificat,
    capital,
    quoted,
    district,
    yearid,
    roundid,
    product,
    sorts,
    company_name

}) => {
    return request({
        method: 'post',
        url: '/api/atlas/industry/enterprise',
        data: {
            inid,
            cate,
            page,
            perpage,
            entype,
            qualificat,
            capital,
            quoted,
            district,
            yearid,
            roundid,
            product,
            sorts,
            company_name
        }
    })
}
// 产业发展图谱页太原市行业下企业总览
export const enterprise2 = ({
    inid,
    cate,
    page,
    perpage,
    entype,
    qualificat,
    capital,
    quoted,
    district,
    yearid,
    roundid,
    product,
    sorts,
    company_name
}) => {
    return request({
        method: 'post',
        url: '/api/atlas/industry/overview',
        data: {
            inid,
            cate,
            page,
            perpage,
            entype,
            qualificat,
            capital,
            quoted,
            district,
            yearid,
            roundid,
            product,
            sorts,
            company_name
        }
    })
}
// 太原市企业年度柱状图
export const enterprise3 = ({
    inid,
    cate,
    page,
    perpage,
    entype,
    qualificat,
    capital,
    quoted,
    district,
    yearid,
    roundid,
    product,
    sorts,
    company_name
}) => {
    return request({
        method: 'post',
        url: '/api/atlas/industry/statistics',
        data: {
            inid,
            cate,
            page,
            perpage,
            entype,
            qualificat,
            capital,
            quoted,
            district,
            yearid,
            roundid,
            product,
            sorts,
            company_name
        }
    })
}
// export const ceshi = () => {
//     return request({
//         method: 'get',
//         url: '/api/atlas/get_fields',
//     })
// }
export const industrygdp = () => {
    return request({
        method: 'get',
        url: '/api/attract/industry/gdpRank',
    })
}
// export const industrygdp = () => {
//     return request({
//         method: 'get',
//         url: '/api/attract/industry/table',
//     })
// }
// 获取验证码
export const getyzm = ({
    mobile
}) => {
    return request({
        method: 'post',
        url: '/api/user/send_mobile_code',
        data: {
            mobile
        }
    })
}
// 验证码登录
export const gotologin = ({
    mobile,
    sms_code
}) => {
    return request({
        method: 'post',
        url: '/api/user/login_bysms',
        data: {
            mobile,
            sms_code
        }
    })
}
// CryptoJS.AES_Encrypt("qwe")
// CryptoJS.AES_Decrypt("XRFg9838WQN5oCbxY8rJKw==")
// 密码登录
export const bypwdlogin = ({
    mobile,
    password
}) => {
    let param = CryptoJS.AES_Encrypt(JSON.stringify({
        mobile,
        password
    }))
    return request({
        method: 'post',
        url: '/api/user/login_bypwd',
        data: {
            param,
        }
    })
}

// 管理员登录
export const administratorlogin = ({
    name,
    password
}) => {
    let param = CryptoJS.AES_Encrypt(JSON.stringify({
        name,
        password
    }))
    return request({
        method: 'post',
        url: '/api/user/backLogin',
        data: {
            param,
        }
    })
}
//注册 单位
export const getorgs = () => {
    return request({
        method: 'get',
        url: '/api/user/orgs',
    })
}
// 注册
export const getregister = (form) => {
    return request({
        method: 'post',
        url: '/api/user/register',
        data: form
    })
}
// 是否收藏
export const inducollect = ({
    id
}) => {
    return request({
        method: 'get',
        url: 'api/atlas/inducollect/' + id,

    })
}
// 点击收藏
export const clickinducollect = ({
    id,
    collect
}) => {
    return request({
        method: 'post',
        url: '/api/atlas/industry/collect',
        data: {
            collect,
            id
        }
    })
}
// 园区列表 
export const garden = ({
    inid,
    cate,
    keyword,
    level,
    page,
    perpage

}) => {
    return request({
        method: 'post',
        url: '/api/atlas/carrier/garden',
        data: {
            inid,
            cate,
            keyword,
            level,
            page,
            perpage
        }
    })
}
// 园区列表 修改 添加行政区和园林分类
export const garden2 = ({
    inid,
    cate,
    keyword,
    level,
    page,
    perpage,
    clasify

}) => {
    return request({
        method: 'post',
        url: '/api/atlas/carrier/gardens',
        data: {
            inid,
            cate,
            keyword,
            level,
            page,
            perpage,
            clasify
        }
    })
}
// 政策
export const policy = ({
    inid,
    page,
    perpage,
    category,
    org,
    year,
    keyword,
    level
}) => {
    return request({
        method: 'post',
        url: '/api/atlas/industry/policyList',
        data: {
            inid,
            page,
            perpage,
            category,
            org,
            year,
            keyword,
            level
        }
    })
}
// 政策
export const policydetail = ({
    id
}) => {
    return request({
        method: 'post',
        url: '/api/atlas/indupolicy/detail',
        data: {
            id
        }
    })
}


// 载体详情
export const Carrierdetails = ({
    id
}) => {
    return request({
        method: 'post',
        url: '/api/atlas/carrier/detail',
        data: {
            id
        }
    })
}
// 高校详情
export const orgsdetail = ({
    id,
    cateone
}) => {
    return request({
        method: 'post',
        url: '/api/orgs/detail',
        data: {
            id,
            cateone
        }
    })
}
// 地图
export const map = ({
    inid,
    cate
}) => {
    return request({
        method: 'post',
        url: '/api/atlas/enterprise/piecemap',
        data: {
            inid,
            cate
        }
    })
}

export const sdmap = ({
    inid,
    cate,
    district,
    product
}) => {
    return request({
        method: 'post',
        url: '/api/atlas/enterprise/sdmap',
        data: {
            inid,
            cate,
            district,
            product
        }
    })
}
// 园区散点图
export const zonemap = ({
    inid,
    cate,
    keyword,
    level,
    page,
    perpage,
    clasify
}) => {
    return request({
        method: 'post',
        url: '/api/atlas/zone/sdmap',
        data: {
            inid,
            cate,
            keyword,
            level,
            page,
            perpage,
            clasify
        }
    })
}
// 链图
export const chain = ({
    inid,
    district
}) => {
    return request({
        method: 'post',
        url: '/api/atlas/industry/chain',
        data: {
            inid,
            district
        }
    })
}
// 骨干企业类型
export const backbonedetail = ({
    id
}) => {
    return request({
        method: 'post',
        url: '/api/atlas/enterprise/detail',
        data: {
            id
        }
    })
}
export const project = ({
    district
}) => {
    return request({
        method: 'post',
        url: '/api/attract/industry/project',
        data: {
            district
        }
    });
};
// 
export const getInfo = ({
    district
}) => {
    return request({
        method: 'post',
        url: '/api/attract/industry/getInfo',
        data: {
            district
        }
    });
};
// 轮播
export const indexlunbo = ({
    district
}) => {
    return request({
        method: 'post',
        url: '/api/attract/industry/lunbo',
        data: {
            district
        }
    });
};

//
// 重点招商政策
export const keyProject = ({
    id
}) => {
    return request({
        method: 'post',
        url: '/api/attract/industry/keyProject',
        data: {
            id
        }
    });
};
// 骨干企业详情收藏
export const backbonecollect = ({
    id,
    collect,
    choice
}) => {
    return request({
        method: 'post',
        url: '/api/atlas/enterprise/collect',
        data: {
            id,
            collect,
            choice
        }
    })
}
// 创新详情收藏
export const catongid = ({
    id,
    cateone
}) => {
    return request({
        method: 'post',
        url: '/api/atlas/orgs/detail',
        data: {
            id,
            cateone
        }
    })
}
// 机构类型分布统计图
export const distribute = ({
    inid,
    cate,
    district
}) => {
    return request({
        method: 'post',
        url: '/api/atlas/innovate/distribute',
        data: {
            inid,
            cate,
            district
        }
    });
};
// 机构类型
export const innovateOrgs = () => {
    return request({
        method: 'get',
        url: '/api/atlas/innovate/fields',
    });
};
// 
// 共为您查找到 家企业
export const apiInnovateOrgs = ({
    inid,
    cate,
    cateone,
    catetwo,
    page,
    perpage,
    product
}) => {
    return request({
        method: 'post',
        url: '/api/atlas/innovate/orgs',
        data: {
            inid,
            cate,
            cateone,
            catetwo,
            page,
            perpage,
            product
        }
    });
};
// 详情图谱
export const detailsAbout = ({
    company_name
}) => {
    return request({
        method: 'post',
        url: '/api/atlas/find_zero',
        data: {
            company_name
        }
    });
};
// 详情图谱
export const detailsAboutfind = ({
    name,
    category
}) => {
    return request({
        method: 'post',
        url: '/api/atlas/find_next',
        data: {
            name,
            category
        }
    });
};
// 详情图谱
export const getsemicmap = ({
    company_name
}) => {
    return request({
        method: 'post',
        url: '/api/atlas/get_semic_map',
        data: {
            company_name
        }
    });
};
// 创新地图
export const orgsSdmap = ({
    inid,
    cate,
    cateone,
    catetwo,
    page,
    perpage,
    product,
}) => {
    return request({
        method: 'post',
        url: '/api/atlas/orgs/sdmap',
        data: {
            inid,
            cate,
            cateone,
            catetwo,
            page,
            perpage,
            product,
        }
    });
};

//发布机构
export const certificate = () => {
    return request({
        method: 'get',
        url: '/api/atlas/industry/category',
    })
}

//360企业柱状图
export const histogram360 = () => {
    return request({
        method: 'get',
        url: '/api/portraits/industry/table',
    })
}
// 筛选条件
export const field = () => {
    return request({
        method: 'get',
        url: '/api/portraits/industry/field '
    })
}
// 筛选条件-地址
export const getRegion = () => {
    return request({
        method: 'get',
        url: '/api/portraits/industry/getRegion'
    })
}
// 360搜索
export const search360 = ({
    qualificat,
    entypeid,
    area,
    capital_id,
    public_id,
    yearid,
    company,
    page,
    per_page,
    financ_id
}) => {
    return request({
        method: 'post',
        url: '/api/portraits/industry/search',
        data: {
            qualificat,
            entypeid,
            area,
            capital_id,
            public_id,
            yearid,
            company,
            page,
            per_page,
            financ_id
        }
    })
}

// 360企业画像 详情
export const details360 = ({
    id
}) => {
    return request({
        method: 'post',
        url: "/api/portraits/industry/detail",
        data: {
            id
        }
    })
}
// cang企业画像 详情
export const companyDetail = ({
    id
}) => {
    return request({
        method: 'post',
        url: "/api/attract/industry/companyDetail",
        data: {
            id
        }
    })
}
//招商政策列表详情
export const getpolicyDetailsdis = ({
    id
}) => {
    return request({
        method: 'post',
        url: "/api/attract/industry/policyDetails",
        data: {
            id
        }
    })
}
// 智能选址评估
export const smart = ({
    company,
    industry,
    revenue,
    tax,
    product,
    budget,
    address,
    preference
}) => {
    return request({
        method: 'post',
        url: "/api/address/industry/advice",
        data: {
            company,
            industry,
            revenue,
            tax,
            product,
            budget,
            address,
            preference
        }
    })
}
// 智能选址评估地图
export const smartmap = ({
    district
}) => {
    return request({
        method: 'post',
        url: "/api/address/industry/map",
        data: {
            district
        }
    })
}
// 获取省、市、区县、开发区，筛选条件
export const selectArea = () => {
    return request({
        method: 'get',
        url: "/api/atlas/selectArea",
    })
}
// 根据省市区开发区获取对应的机构
export const areaCategory = ({
    vau
}) => {
    return request({
        method: 'post',
        url: "/api/atlas/areaCategory",
        data: {
            vau
        }
    })
}
// 招商地图筛选条件/api/att/attract/field
export const condition = () => {
    return request({
        method: 'get',
        url: "/api/att/attract/field"
    })
}
// 链图 /api/att/attract/chain
export const chaingraph = ({
    inid, // 二级行业id
    area, //省市区【省，市，区】
}) => {
    return request({
        method: 'post',
        url: "/api/att/attract/chain",
        data: {
            inid,
            area
        }
    })
}
// 全国企业列表 /api/att/attract/enterprise
export const firmlist = ({
    inid, // 行业id
    page,
    perpage,
    entype, //   企业类型id
    qualificat, //  企业资质id
    capital, //      注册资本id
    quoted, //   上市板块
    area, // 省市区【省市区】
    yearid, //成立时间年限
    roundid, // 融资轮次id
    product, //  产业产品选择（链图上的产品名）
    sorts,
    company_name
}) => {
    return request({
        method: 'post',
        url: "/api/att/attract/enterprise",
        data: {
            inid, // 行业id
            page,
            perpage,
            entype, //   企业类型id
            qualificat, //  企业资质id
            capital, //      注册资本id
            quoted, //   上市板块
            area, // 省市区【省市区】
            yearid, //成立时间年限
            roundid, // 融资轮次id
            product, //  产业产品选择（链图上的产品名）
            sorts,
            company_name
        }
    })
}
// 全国色块图及右侧柱状图 
export const nationalbar = ({
    inid, // 行业id
    page, //
    perpage,
    entype, //企业类型id
    qualificat, // 企业资质id
    capital, //注册资本id
    quoted, //上市板块
    area, // 省市区【 省市区】
    yearid, // 成立时间年限
    roundid, // 融资轮次id
    product, // 产业产品选择（ 链图上的产品名
    sorts,
    company_name
}) => {
    return request({
        method: 'post',
        url: "/api/att/attract/map",
        data: {
            inid, // 行业id
            page,
            perpage,
            entype, //   企业类型id
            qualificat, //  企业资质id
            capital, //      注册资本id
            quoted, //   上市板块
            area, // 省市区【省市区】
            yearid, //成立时间年限
            roundid, // 融资轮次id
            product, //  产业产品选择（链图上的产品名）
            sorts,
            company_name
        }
    })
}
// 右侧柱状图 
export const nationalbartop5 = ({
    inid, // 行业id
    page, //
    perpage,
    entype, //企业类型id
    qualificat, // 企业资质id
    capital, //注册资本id
    quoted, //上市板块
    area, // 省市区【 省市区】
    yearid, // 成立时间年限
    roundid, // 融资轮次id
    product, // 产业产品选择（ 链图上的产品名
    sorts,
    company_name
}) => {
    return request({
        method: 'post',
        url: "/api/att/attract/cnums",
        data: {
            inid, // 行业id
            page,
            perpage,
            entype, //   企业类型id
            qualificat, //  企业资质id
            capital, //      注册资本id
            quoted, //   上市板块
            area, // 省市区【省市区】
            yearid, //成立时间年限
            roundid, // 融资轮次id
            product, //  产业产品选择（链图上的产品名）
            sorts,
            company_name
        }
    })
}
//区域后的色块图 /api/att/attract/attract/sdmap
export const diagramregion = ({
    inid, // 行业id
    page, //
    perpage,
    entype, //企业类型id
    qualificat, // 企业资质id
    capital, //注册资本id
    quoted, //上市板块
    area, // 省市区【 省市区】
    yearid, // 成立时间年限
    roundid, // 融资轮次id
    product, // 产业产品选择（ 链图上的产品名
}) => {
    return request({
        method: 'post',
        url: "/api/att/attract/sdmap",
        data: {
            inid, // 行业id
            page,
            perpage,
            entype, //   企业类型id
            qualificat, //  企业资质id
            capital, //      注册资本id
            quoted, //   上市板块
            area, // 省市区【省市区】
            yearid, //成立时间年限
            roundid, // 融资轮次id
            product, //  产业产品选择（链图上的产品名）
        }
    })
}
// 招商地图详情
export const zsdetail = ({
    id
}) => {
    return request({
        method: 'post',
        url: '/api/att/enterprise/detail',
        data: {
            id
        }
    })
}
// 招商地图详情
export const getRole = ({
    id
}) => {
    return request({
        method: 'post',
        url: '/api/power/getRole',
        data: {
            id
        }
    })
}

// l雷达页面 搜索 公司企业详情 api/radar/enterList
export const enterList = ({
    inid, //行业id
    area, //[省， 市， 区]
    product, // 企业产品（ 链图）
    page,
    perpage,
    select,
    name
}) => {
    return request({
        method: 'post',
        url: '/api/radar/enterList',
        data: {
            name,
            select,
            inid, //行业id
            area, //[省， 市， 区]
            product, // 企业产品（ 链图）   
            page,
            perpage
        }
    })
}

// l雷达页面 搜索 /api/radar/radar/enums
export const radarsearch = ({
    inid, //行业id
    area, //[省， 市， 区]
    product, // 企业产品（ 链图）
}) => {
    return request({
        method: 'post',
        url: '/api/radar/radar/enums',
        data: {
            inid, //行业id
            area, //[省， 市， 区]
            product, // 企业产品（ 链图）   
        }
    })
}
//2.雷达监测页企业数量趋势统计http://192.168.1.127:5000/api/radar/radar/tendency

export const radartendency = ({
    inid, //行业id
    area, //[省， 市， 区]
    product, // 企业产品（ 链图）
    sone, //      # 选择1，1企业个数，2注册资本
    stwo, //    # 选择2， 1年度，2季度，3月度
    sthr, //    # 选择3， 1新增量，2累积量
}) => {
    return request({
        method: 'post',
        url: '/api/radar/radar/tendency',
        data: {
            inid, //行业id
            area, //[省， 市， 区]
            product, // 企业产品（ 链图）  
            sone, //      # 选择1，1企业个数，2注册资本
            stwo, //    # 选择2， 1年度，2季度，3月度
            sthr, //    # 选择3， 1新增量，2累积量 
        }
    })
}
//2.雷达监测页企业数量趋势统计/api/radar/radar/distribute

export const radardistribute = ({
    inid, //行业id
    area, //[省， 市， 区]
    product, // 企业产品（ 链图
}) => {
    return request({
        method: 'post',
        url: '/api/radar/radar/distribute',
        data: {
            inid, //行业id
            area, //[省， 市， 区]
            product, // 企业产品（ 链图） 
        }
    })
}
// /api/radar/radar/topt
export const top10 = ({
    inid, //行业id
    area, //[省， 市， 区]
    product, // 企业产品（ 链图
    select
}) => {
    return request({
        method: 'post',
        url: '/api/radar/radar/topt',
        data: {
            inid, //行业id
            area, //[省， 市， 区]
            product, // 企业产品（ 链图）
            select
        }
    })
}

// 雷达评估/api/radar/radar/evaluation

export const evaluation = ({
    inid, // 二级行业id（ 1 级行业不做选项， 只做展开）
    area, //省市区【 省， 市， 区】
}) => {
    return request({
        method: 'post',
        url: '/api/radar/radar/evaluation',
        data: {
            inid, // 二级行业id（ 1 级行业不做选项， 只做展开）
            area, //省市区【 省， 市， 区】
        }
    })
}


//雷达评估页行业地区优势薄弱缺失三环节数据 列表查询 
export const selquery = () => {
    return request({
        method: 'get',
        url: '/api/radar/randerSelect'
    })
}

//雷达评估页行业地区优势薄弱缺失三环节数据

export const radarprolink = ({
    inid, // 二级行业id（1级行业不做选项，只做展开）
    area, // 省市区【省，市，区】
    link_type, //   # 缺失环节1，薄弱环节2，优势环节3
    page, //
    perpage,
}) => {
    return request({
        method: 'post',
        url: '/api/radar/radar/prolink',
        data: {
            inid, // 二级行业id（1级行业不做选项，只做展开）
            area, // 省市区【省，市，区】
            link_type, //   # 缺失环节1，薄弱环节2，优势环节3
            page, //
            perpage,
        }
    })
}
//产品描述及企业推荐

export const radarrecommond = ({
    name, //  # 产品名
    page,
    perpage,
    select
}) => {
    return request({
        method: 'post',
        url: '/api/radar/recommond',
        data: {
            name, //  # 产品名
            page,
            perpage,
            select
        }
    })
}
//招商资源管理 /api/manage/listAddItem

export const listAddItem = () => {
    return request({
        method: 'get',
        url: '/api/manage/listAddItem'
    })
}
//sahnchu

export const apidelete = ({
    id
}) => {
    return request({
        method: 'post',
        url: '/api/manage/deleteItemFile',
        data: {
            id
        }
    })
}
//招商资源管理 /api/manage/listAddItem

export const addItem = ({
    build_date, //   		# 公司成立时间
    company_name, //   		# 项目企业
    admin, //  			# 联系人
    mobile, //   			# 联系电话
    email, //			# 联系邮箱
    name, //			# 项目名称
    store, // 			# 入库时间
    product, //  			# 生产产品
    industry, //  			# 行业
    indu_id, // 			# 所在行业id
    invest, // 			# 投资额(亿元)
    area, //   			# 占地面积（亩）
    value, //   			# 预计产值(亿元)
    tax, //  			# 预计税收(亿元)
    link, //   			# 对接人
    link_mobile, //  		# 对接人的联系方式
    level, //   			# 项目的级别
    level_id, //  			# 项目级别ID
    stage, //  			# 项目的进度
    stage_id, //  			# 项目进度id
    main_introduce, //  		# 项目主体介绍
    invest_introduce, //		# 投资项目介绍
    build_introduce, // 		# 项目建设内容
    encomy, // 			# 项目的经济效益
    policy, // 			# 项目政策需求
    question, //   			# 项目存在的问题
}) => {
    return request({
        method: 'post',
        url: '/api/manage/addItem',
        data: {
            build_date, //   		# 公司成立时间
            company_name, //   		# 项目企业
            admin, //  			# 联系人
            mobile, //   			# 联系电话
            email, //			# 联系邮箱
            name, //			# 项目名称
            store, // 			# 入库时间
            product, //  			# 生产产品
            industry, //  			# 行业
            indu_id, // 			# 所在行业id
            invest, // 			# 投资额(亿元)
            area, //   			# 占地面积（亩）
            value, //   			# 预计产值(亿元)
            tax, //  			# 预计税收(亿元)
            link, //   			# 对接人
            link_mobile, //  		# 对接人的联系方式
            level, //   			# 项目的级别
            level_id, //  			# 项目级别ID
            stage, //  			# 项目的进度
            stage_id, //  			# 项目进度id
            main_introduce, //  		# 项目主体介绍
            invest_introduce, //		# 投资项目介绍
            build_introduce, // 		# 项目建设内容
            encomy, // 			# 项目的经济效益
            policy, // 			# 项目政策需求
            question, //   			# 项目存在的问题
        }
    })
}

// 项目管理
export const ManageItem = ({
    indu_id, //--“行业id”,
    invest_id, //--投资额ID，
    section, //--部门，
    search, //--搜索栏的值
    page, //-- 页数，
    perpage, // -- 每页个数，
    stage_id, //项目进展的ID，
    order, //--  投资额排序  1为顺序，2为倒序
}) => {
    return request({
        method: 'post',
        url: '/api/manage/getManageItem',
        data: {
            indu_id, //--“行业id”,
            invest_id, //--投资额ID，
            section, //--部门，
            search, //--搜索栏的值
            page, //-- 页数，
            perpage, // -- 每页个数，
            stage_id, //项目进展的ID，
            order, //--  投资额排序  1为顺序，2为倒序
        }
    })
}
// 获取所有的局
export const listGetUnit = () => {
    return request({
        method: 'get',
        url: '/api/manage/listGetUnit',
    })
}
// 获取所有的局
export const getSectionPeople = ({
    government_id
}) => {
    return request({
        method: 'post',
        url: '/api/manage/getSectionPeople',
        data: {
            government_id
        }
    })
}
// diaodu
export const getdispatch = ({
    item_id,
    user_id
}) => {
    return request({
        method: 'post',
        url: '/api/manage/controlItem',
        data: {
            item_id,
            user_id
        }
    })
}
// 机构id
export const getupItem = ({
    item_id,
    unit_id
}) => {
    return request({
        method: 'post',
        url: '/api/manage/upItem',
        data: {
            item_id,
            unit_id
        }
    })
}
// 删除
export const getdeleteReport = ({
    item_id,
}) => {
    return request({
        method: 'post',
        url: '/api/manage/deleteItem',
        data: {
            item_id,
        }
    })
}
// 共享
export const getSectionShare = () => {
    return request({
        method: 'get',
        url: '/api/manage/getSectionShare'
    })
}
export const getshareItem = ({
    user_id, //需要分享的人的ID
    item_id, // 项目id
}) => {
    return request({
        method: 'post',
        url: '/api/manage/shareItem',
        data: {
            user_id, //需要分享的人的ID
            item_id, // 项目id 
        }
    })
}
// 批量下载 /api/manage/downManageItem
export const downManageItem = ({
    item_id
}) => {
    return request({
        method: 'post',
        url: '/api/manage/downManageItem',
        data: {
            item_id
        }
    })
}

// 查看/api/manage/detailsItem
export const detailsItem = ({
    item_id
}) => {
    return request({
        method: 'post',
        url: '/api/manage/detailsItem',
        data: {
            item_id
        }
    })
}
// 编辑/api/manage/updateItem
export const updateItem = ({
    item_id,
    build_date, //   		# 公司成立时间
    company_name, //   		# 项目企业
    admin, //  			# 联系人
    mobile, //   			# 联系电话
    email, //			# 联系邮箱
    name, //			# 项目名称
    store, // 			# 入库时间
    product, //  			# 生产产品
    industry, //  			# 行业
    indu_id, // 			# 所在行业id
    invest, // 			# 投资额(亿元)
    area, //   			# 占地面积（亩）
    value, //   			# 预计产值(亿元)
    tax, //  			# 预计税收(亿元)
    link, //   			# 对接人
    link_mobile, //  		# 对接人的联系方式
    level, //   			# 项目的级别
    level_id, //  			# 项目级别ID
    stage, //  			# 项目的进度
    stage_id, //  			# 项目进度id
    main_introduce, //  		# 项目主体介绍
    invest_introduce, //		# 投资项目介绍
    build_introduce, // 		# 项目建设内容
    encomy, // 			# 项目的经济效益
    policy, // 			# 项目政策需求
    question, //   			# 项目存在的问题
}) => {
    return request({
        method: 'post',
        url: '/api/manage/updateItem',
        data: {
            item_id,
            build_date, //   		# 公司成立时间
            company_name, //   		# 项目企业
            admin, //  			# 联系人
            mobile, //   			# 联系电话
            email, //			# 联系邮箱
            name, //			# 项目名称
            store, // 			# 入库时间
            product, //  			# 生产产品
            industry, //  			# 行业
            indu_id, // 			# 所在行业id
            invest, // 			# 投资额(亿元)
            area, //   			# 占地面积（亩）
            value, //   			# 预计产值(亿元)
            tax, //  			# 预计税收(亿元)
            link, //   			# 对接人
            link_mobile, //  		# 对接人的联系方式
            level, //   			# 项目的级别
            level_id, //  			# 项目级别ID
            stage, //  			# 项目的进度
            stage_id, //  			# 项目进度id
            main_introduce, //  		# 项目主体介绍
            invest_introduce, //		# 投资项目介绍
            build_introduce, // 		# 项目建设内容
            encomy, // 			# 项目的经济效益
            policy, // 			# 项目政策需求
            question, //   			# 项目存在的问题
        }
    })
}
// 下载 /api/manage/downManageItem
export const downItemFile = ({
    file_id
}) => {
    return request({
        method: 'post',
        url: '/api/manage/downItemFile',
        data: {
            file_id
        }
    })
}
// 下载 /api/manage/downManageItem
export const listReportItem = () => {
    return request({
        method: 'get',
        url: '/api/manage/listReportItem',
    })
}
// 上报追踪 /api/manage/reportItem
export const reportItem = ({
    indu_id, //行业id,
    invest_id, // 投资额id "stage_id": 项目进度id,
    stage_id, //项目进度id,
    unit, // 报送单位id,
    way_id, // 查看方式id,
    search, // 搜索框的内容,
    order, // 排序方式1 - 正序， 2 为倒序
    page,
    perpage
}) => {
    return request({
        method: 'post',
        url: '/api/manage/reportItem',
        data: {
            stage_id, //项目进度id,
            indu_id, //行业id,
            invest_id, // 投资额id "stage_id": 项目进度id,
            unit, // 报送单位id,
            way_id, // 查看方式id,
            search, // 搜索框的内容,
            order, // 排序方式1 - 正序， 2 为倒序
            page,
            perpage
        }
    })
}
// /api/manage/auditItem
export const auditItem = ({
    item_id, // -- 项目id,
    status, // : 4-通过，3-驳回
}) => {
    return request({
        method: 'post',
        url: '/api/manage/auditItem',
        data: {
            item_id, // -- 项目id,
            status, // : 4-通过，3-驳回
        }
    })
}
// 上报删除/api/manage/deleteReport
export const deleteReport = ({
    item_id, // -- 项目id,
}) => {
    return request({
        method: 'post',
        url: '/api/manage/deleteReport',
        data: {
            item_id, // -- 项目id,
        }
    })
}

// 下载/api/manage/downReport 
export const downReport = ({
    data,
}) => {
    return request({
        method: 'post',
        url: '/api/manage/downReport ',
        data: {
            data,
        }
    })
}

//  /api/manage/UnitUnit
export const UnitUnit = () => {
    return request({
        method: 'get',
        url: '/api/manage/UnitUnit',
    })
}
//  /api/manage/UnitUnit
export const getSec = () => {
    return request({
        method: 'get',
        url: '/api/manage/getSec',
    })
}
//  /api/manage/getSecpeople
export const getSecpeople = ({
    section
}) => {
    return request({
        method: 'post',
        url: '/api/manage/getSecpeople',
        data: {
            section
        }
    })
}
// 机构内部统计接口/api/manage/stageInTable
export const stageInTable = ({
    year_begin,
    month_begin,
    year_end,
    month_end,
    stage_id,
    indu_id,
    section,
    user_id
}) => {
    return request({
        method: 'post',
        url: '/api/manage/stageInTable',
        data: {
            year_begin,
            month_begin,
            year_end,
            month_end,
            stage_id,
            indu_id,
            section,
            user_id
        }
    })
}
export const stageInList = ({
    year_begin,
    month_begin,
    year_end,
    month_end,
    stage_id,
    indu_id,
    section,
    user_id
}) => {
    return request({
        method: 'post',
        url: '/api/manage/stageInList',
        data: {
            year_begin,
            month_begin,
            year_end,
            month_end,
            stage_id,
            indu_id,
            section,
            user_id
        }
    })
}
// 下载 /api/manage/stageInDown
export const stageInDown = ({
    year_begin,
    month_begin,
    year_end,
    month_end,
    stage_id,
    indu_id,
    section,
    user_id,
    type
}) => {
    return request({
        method: 'post',
        url: '/api/manage/stageInDown',
        data: {
            year_begin,
            month_begin,
            year_end,
            month_end,
            stage_id,
            indu_id,
            section,
            user_id,
            type
        }
    })
}
// 统筹机构统计
export const stageOutTable = ({
    year_begin,
    month_begin,
    year_end,
    month_end,
    stage_id,
    indu_id,
    unit
}) => {
    return request({
        method: 'post',
        url: '/api/manage/stageOutTable',
        data: {
            year_begin,
            month_begin,
            year_end,
            month_end,
            stage_id,
            indu_id,
            unit
        }
    })
}
export const stageOutList = ({
    year_begin,
    month_begin,
    year_end,
    month_end,
    stage_id,
    indu_id,
    unit
}) => {
    return request({
        method: 'post',
        url: '/api/manage/stageOutList',
        data: {
            year_begin,
            month_begin,
            year_end,
            month_end,
            stage_id,
            indu_id,
            unit
        }
    })
}
export const stageOutDown = ({
    year_begin,
    month_begin,
    year_end,
    month_end,
    stage_id,
    indu_id,
    unit,
    type
}) => {
    return request({
        method: 'post',
        url: '/api/manage/stageOutDown',
        data: {
            year_begin,
            month_begin,
            year_end,
            month_end,
            stage_id,
            indu_id,
            unit,
            type
        }
    })
}
// 区域 /api/manage/getFiled
export const getFiled = () => {
    return request({
        method: 'get',
        url: '/api/manage/getFiled',
    })
}
// 园区
export const addGarden = ({
    name,
    level, //  园区级别
    charge, //  园区负责人
    phone, //  联系电话
    cate, //  园区类型
    address, //  所在地
    cluster, //  产业集群
    area, //  占地面积
    acreage, //  规划面积
    gdp, //  gdp
    land_code, //   土地位置/编号
    land_property, //   土地性质
    land_range, //   土地范围
    land_size, //   土地面积/平方公里
    indu_appeal, //   产业诉求
    build_name, //   楼宇名称
    build_add, //   楼宇地址
    build_size, //   楼宇面积/平方公里
    build_appeal, //  楼宇诉求
    plant_add, //   厂房位置
    plant_size, //   厂房面积
    plant_condition, //   厂房条件
    resident_ele_one, //居民用电（一档）（元/度）
    resident_ele_two, //居民用电（二档）（元/度）
    resident_ele_thr, //居民用电（三档）（元/度）
    comm_ele_one, //商业用电（峰段）（元/度）
    comm_ele_two, //商业用电（平段）（元/度）
    comm_ele_thr, //商业用电（谷段）（元/度）
    indu_ele_one, //工业用电（峰段）（元/度）
    indu_ele_two, //工业用电（平段）（元/度）
    indu_ele_thr, //工业用电（谷段）（元/度）
    resident_water_one, //居民用水（一档）（元/吨）
    resident_water_two, //居民用水（二档）（元/吨）
    resident_water_thr, //居民用水（三档）（元/吨）
    comm_water, //商业用水（元/吨）
    indu_water, //工业用水（元/吨）
    special_water, //特种用水（元/吨）
    resident_natgas_one, //居民用气（一档）（元/m³）
    resident_natgas_two, //居民用气（二档）（元/m³）
    resident_natgas_thr, //居民用气（三档）（元/m³）
    sewage, //污水处理（元/吨）
    wagelevel, //最低工资标准（元/月）
    worker, //普通员工（元/月）
    middlemag, //中级管理人员（元/月）
    highmag, //高层管理人员（元/月）
    dis_freight, //距离货运站距离
    dis_rail, //距离高铁站
    dis_port, //距离港口距离
    dis_air, //距离机场距离
    road_trans, //公路运输
    rail_trans, //铁路运输
    live_facility, //园区生活配套
    market, //百货商场
    hotel_bus, //酒店商务
    medical, //  医疗机构
    education, //  学校教育
    // 产业发展
    induenterprise, //  规模以上企业
    innovate, //  # 科研机构
    base, //  # 双创基地
    carrier, //  # 产业载体
}) => {
    return request({
        method: 'post',
        url: '/api/manage/addGarden',
        data: {
            name,
            level, //  园区级别
            charge, //  园区负责人
            phone, //  联系电话
            cate, //  园区类型
            address, //  所在地
            cluster, //  产业集群
            area, //  占地面积
            acreage, //  规划面积
            gdp, //  gdp
            land_code, //   土地位置/编号
            land_property, //   土地性质
            land_range, //   土地范围
            land_size, //   土地面积/平方公里
            indu_appeal, //   产业诉求
            build_name, //   楼宇名称
            build_add, //   楼宇地址
            build_size, //   楼宇面积/平方公里
            build_appeal, //  楼宇诉求
            plant_add, //   厂房位置
            plant_size, //   厂房面积
            plant_condition, //   厂房条件
            resident_ele_one, //居民用电（一档）（元/度）
            resident_ele_two, //居民用电（二档）（元/度）
            resident_ele_thr, //居民用电（三档）（元/度）
            comm_ele_one, //商业用电（峰段）（元/度）
            comm_ele_two, //商业用电（平段）（元/度）
            comm_ele_thr, //商业用电（谷段）（元/度）
            indu_ele_one, //工业用电（峰段）（元/度）
            indu_ele_two, //工业用电（平段）（元/度）
            indu_ele_thr, //工业用电（谷段）（元/度）
            resident_water_one, //居民用水（一档）（元/吨）
            resident_water_two, //居民用水（二档）（元/吨）
            resident_water_thr, //居民用水（三档）（元/吨）
            comm_water, //商业用水（元/吨）
            indu_water, //工业用水（元/吨）
            special_water, //特种用水（元/吨）
            resident_natgas_one, //居民用气（一档）（元/m³）
            resident_natgas_two, //居民用气（二档）（元/m³）
            resident_natgas_thr, //居民用气（三档）（元/m³）
            sewage, //污水处理（元/吨）
            wagelevel, //最低工资标准（元/月）
            worker, //普通员工（元/月）
            middlemag, //中级管理人员（元/月）
            highmag, //高层管理人员（元/月）
            dis_freight, //距离货运站距离
            dis_rail, //距离高铁站
            dis_port, //距离港口距离
            dis_air, //距离机场距离
            road_trans, //公路运输
            rail_trans, //铁路运输
            live_facility, //园区生活配套
            market, //百货商场
            hotel_bus, //酒店商务
            medical, //  医疗机构
            education, //  学校教育
            // 产业发展
            induenterprise, //  规模以上企业
            innovate, //  # 科研机构
            base, //  # 双创基地
            carrier, //  # 产业载体
        }
    })
}
// 模板/api/manage/downModel

export const downModel = () => {
    return request({
        method: 'get',
        url: '/api/manage/downModel',
    })
}
// 上传 /api/manage/checkFile
export const checkFile = ({
    id
}) => {
    return request({
        method: 'post',
        url: '/api/manage/checkFile',
        data: {
            id
        }
    })
}
// 上传 /api/manage/checkFile
export const deleteDoc = ({
    id
}) => {
    return request({
        method: 'post',
        url: '/api/manage/deleteDoc',
        data: {
            id
        }
    })
}
// 上传 /api/manage/checkFile
export const downFile = ({
    id
}) => {
    return request({
        method: 'post',
        url: '/api/manage/downFile',
        data: {
            id
        }
    })
}
// 园区列表 /api/manage/listOfGarden
export const listOfGarden = ({
    key,
    indu,
    level,
    cate,
    status,
    page,
    perpage,
}) => {
    return request({
        method: 'post',
        url: '/api/manage/listOfGarden',
        data: {
            key,
            indu,
            level,
            cate,
            status,
            page,
            perpage,
        }
    })
}
// 园区上报/api/manage/listGetUnit  get请求
export const getlistGetUnit = () => {
    return request({
        method: 'get',
        url: '/api/manage/listGetUnit',
    })
}
// 上报确定/api/manage/sendGarden   
export const getsendGarden = ({
    id,
    org,
}) => {
    return request({
        method: 'post',
        url: '/api/manage/sendGarden',
        data: {
            id,
            org,
        }
    })
}
// 上报确定/api/manage/passGarden  
export const passGarden = ({
    id,
    advice
}) => {
    return request({
        method: 'post',
        url: '/api/manage/passGarden',
        data: {
            id,
            advice
        }
    })
}
// 查看 /api/manage/detailGarden  
export const detailGarden = ({
    id,
}) => {
    return request({
        method: 'post',
        url: '/api/manage/detailGarden',
        data: {
            id,
        }
    })
}
export const listDoc = ({
    id,
}) => {
    return request({
        method: 'post',
        url: '/api/manage/listDoc',
        data: {
            id,
        }
    })
}
export const listImage = ({
    id,
}) => {
    return request({
        method: 'post',
        url: '/api/manage/listImage',
        data: {
            id,
        }
    })
}
export const downDoc = ({
    id,
}) => {
    return request({
        method: 'post',
        url: '/api/manage/downDoc',
        data: {
            id,
        }
    })
}
export const editGarden = ({
    id,
    name,
    level, //  园区级别
    charge, //  园区负责人
    phone, //  联系电话
    cate, //  园区类型
    address, //  所在地
    cluster, //  产业集群
    area, //  占地面积
    acreage, //  规划面积
    gdp, //  gdp
    land_code, //   土地位置/编号
    land_property, //   土地性质
    land_range, //   土地范围
    land_size, //   土地面积/平方公里
    indu_appeal, //   产业诉求
    build_name, //   楼宇名称
    build_add, //   楼宇地址
    build_size, //   楼宇面积/平方公里
    build_appeal, //  楼宇诉求
    plant_add, //   厂房位置
    plant_size, //   厂房面积
    plant_condition, //   厂房条件
    resident_ele_one, //居民用电（一档）（元/度）
    resident_ele_two, //居民用电（二档）（元/度）
    resident_ele_thr, //居民用电（三档）（元/度）
    comm_ele_one, //商业用电（峰段）（元/度）
    comm_ele_two, //商业用电（平段）（元/度）
    comm_ele_thr, //商业用电（谷段）（元/度）
    indu_ele_one, //工业用电（峰段）（元/度）
    indu_ele_two, //工业用电（平段）（元/度）
    indu_ele_thr, //工业用电（谷段）（元/度）
    resident_water_one, //居民用水（一档）（元/吨）
    resident_water_two, //居民用水（二档）（元/吨）
    resident_water_thr, //居民用水（三档）（元/吨）
    comm_water, //商业用水（元/吨）
    indu_water, //工业用水（元/吨）
    special_water, //特种用水（元/吨）
    resident_natgas_one, //居民用气（一档）（元/m³）
    resident_natgas_two, //居民用气（二档）（元/m³）
    resident_natgas_thr, //居民用气（三档）（元/m³）
    sewage, //污水处理（元/吨）
    wagelevel, //最低工资标准（元/月）
    worker, //普通员工（元/月）
    middlemag, //中级管理人员（元/月）
    highmag, //高层管理人员（元/月）
    dis_freight, //距离货运站距离
    dis_rail, //距离高铁站
    dis_port, //距离港口距离
    dis_air, //距离机场距离
    road_trans, //公路运输
    rail_trans, //铁路运输
    live_facility, //园区生活配套
    market, //百货商场
    hotel_bus, //酒店商务
    medical, //  医疗机构
    education, //  学校教育
    // 产业发展
    induenterprise, //  规模以上企业
    innovate, //  # 科研机构
    base, //  # 双创基地
    carrier, //  # 产业载体
}) => {
    return request({
        method: 'post',
        url: '/api/manage/editGarden',
        data: {
            id,
            name,
            level, //  园区级别
            charge, //  园区负责人
            phone, //  联系电话
            cate, //  园区类型
            address, //  所在地
            cluster, //  产业集群
            area, //  占地面积
            acreage, //  规划面积
            gdp, //  gdp
            land_code, //   土地位置/编号
            land_property, //   土地性质
            land_range, //   土地范围
            land_size, //   土地面积/平方公里
            indu_appeal, //   产业诉求
            build_name, //   楼宇名称
            build_add, //   楼宇地址
            build_size, //   楼宇面积/平方公里
            build_appeal, //  楼宇诉求
            plant_add, //   厂房位置
            plant_size, //   厂房面积
            plant_condition, //   厂房条件
            resident_ele_one, //居民用电（一档）（元/度）
            resident_ele_two, //居民用电（二档）（元/度）
            resident_ele_thr, //居民用电（三档）（元/度）
            comm_ele_one, //商业用电（峰段）（元/度）
            comm_ele_two, //商业用电（平段）（元/度）
            comm_ele_thr, //商业用电（谷段）（元/度）
            indu_ele_one, //工业用电（峰段）（元/度）
            indu_ele_two, //工业用电（平段）（元/度）
            indu_ele_thr, //工业用电（谷段）（元/度）
            resident_water_one, //居民用水（一档）（元/吨）
            resident_water_two, //居民用水（二档）（元/吨）
            resident_water_thr, //居民用水（三档）（元/吨）
            comm_water, //商业用水（元/吨）
            indu_water, //工业用水（元/吨）
            special_water, //特种用水（元/吨）
            resident_natgas_one, //居民用气（一档）（元/m³）
            resident_natgas_two, //居民用气（二档）（元/m³）
            resident_natgas_thr, //居民用气（三档）（元/m³）
            sewage, //污水处理（元/吨）
            wagelevel, //最低工资标准（元/月）
            worker, //普通员工（元/月）
            middlemag, //中级管理人员（元/月）
            highmag, //高层管理人员（元/月）
            dis_freight, //距离货运站距离
            dis_rail, //距离高铁站
            dis_port, //距离港口距离
            dis_air, //距离机场距离
            road_trans, //公路运输
            rail_trans, //铁路运输
            live_facility, //园区生活配套
            market, //百货商场
            hotel_bus, //酒店商务
            medical, //  医疗机构
            education, //  学校教育
            // 产业发展
            induenterprise, //  规模以上企业
            innovate, //  # 科研机构
            base, //  # 双创基地
            carrier, //  # 产业载体
        }
    })
}
// 个人中心
export const userdetail = () => {
    return request({
        method: 'get',
        url: '/api/user/detail'
    })
}
//后台 用户外部列表
export const listOfExternal = ({
    page,
    perpage,
    key
}) => {
    return request({
        method: 'post',
        url: '/api/power/userListOfExternal',
        data: {
            page,
            perpage,
            key
        }
    })
}
//后台外部列表返回是否禁用
export const liststatus = ({
    id,
    status
}) => {
    return request({
        method: 'post',
        url: '/api/power/updateStatus',
        data: {
            id,
            status
        }
    })
}
// 局内用户列表上的条件筛选
export const fields = ({ }) => {
    return request({
        method: 'get',
        url: '/api/power/manage/fields',
        data: {}
    })
}
export const fieldt = ({
    id
}) => {
    return request({
        method: 'post',
        url: '/api/power/manage/fieldt',
        data: {
            id
        }
    })
}

// 局内用户列表
export const sousuo = ({
    page,
    perpage,
    key,
    status,
    number,
    unit,
    section
}) => {
    return request({
        method: 'post',
        url: '/api/power/userListOfInside',
        data: {
            page,
            perpage,
            key,
            status,
            number,
            unit,
            section
        }
    })
}
//用户 获取职级
export const listgetRole = () => {
    return request({
        method: 'get',
        url: '/api/power/getRole',
    })
}
//用户是否可看
export const getFlag = () => {
    return request({
        method: 'post',
        url: '/api/manage/getFlag',
    })
}
//获取code值
export const login_byvx = ({ code }) => {
    return request({
        method: 'post',
        url: '/api/user/login_byvx',
        data: {
            code
        }
    })
}
//获取code值
export const binding = ({ openid,
    unionid,
    mobile,
    sms_code }) => {
        
    return request({
        method: 'post',
        url: 'api/user/binding',
        data: {
            openid,
            unionid,
            mobile,
            sms_code
        }
    })
}

//用户新增用户
export const addUser = ({
    real_name,
    position,
    level,
    unit,
    section,
    leader,
    mobile,
    sex,
    age,
    email,
    name,
    password

}) => {
    return request({
        method: 'post',
        url: '/api/power/addUser',
        data: {
            real_name,
            position,
            level,
            unit,
            section,
            leader,
            mobile,
            sex,
            age,
            email,
            name,
            password
        }
    })
}





//用户编辑提交
export const updateUser = ({
    id,
    real_name,
    position,
    level,
    unit,
    section,
    leader,
    mobile,
    sex,
    age,
    email,
    name,
    password
}) => {
    return request({
        method: 'post',
        url: '/api/power/updateUser',
        data: {
            id,
            real_name,
            position,
            level,
            unit,
            section,
            leader,
            mobile,
            sex,
            age,
            email,
            name,
            password
        }
    })
}



// 用户获取单个用户
export const userInsideInfo = ({
    id
}) => {
    return request({
        method: 'post',
        url: '/api/power/userInsideInfo',
        data: {
            id
        }
    })
}

//用户状态编辑

export const updateAccount = ({
    id,
    status
}) => {
    return request({
        method: 'post',
        url: '/api/power/updateAccount',
        data: {
            id,
            status
        }
    })
}

//用户删除
export const deleteUser = ({
    id
}) => {
    return request({
        method: 'post',
        url: '/api/power/deleteUser',
        data: {
            id
        }
    })
}



//组织新增下拉列表
export const uadOrg = () => {
    return request({
        method: 'get',
        url: '/api/power/uadOrg',
    })
}
//组织新增下拉列表-统筹机构
export const downOrg = ({
    id
}) => {
    return request({
        method: 'post',
        url: '/api/power/downOrg',
        data: {
            id
        }
    })
}



//新增组织机构
export const addOrg = ({
    name,
    forg,
    charge,
    charge_phone,
    sublist,

}) => {
    return request({
        method: 'post',
        url: '/api/power/addOrg',
        data: {
            name,
            forg,
            charge,
            charge_phone,
            sublist,
        }
    })
}

//组织架构 /api/power/organization

export const organization = ({
    page,
    perpage
}) => {
    return request({
        method: 'post',
        url: '/api/power/organization',
        data: {
            page,
            perpage
        }
    })
}
// 二级 /api/power/sectionGroup

export const sectionGroup = ({
    id, //   当前数据的id
    cate, //  # 当前数据的cate
}) => {
    return request({
        method: 'post',
        url: '/api/power/sectionGroup',
        data: {
            id, //   当前数据的id
            cate, //  # 当前数据的cate
        }
    })
}
// 3-新增下级部门 http://192.168.1.127:5000/api/power/addSub
export const addSub = ({
    id, //   当前数据的id
    cate, //  # 当前数据的cate
    name, // # 新增机构或者部门名称
    pnums, //   # 机构人数
    charge, //  # 机构负责人
    charge_phone, // # 负责人电话
}) => {
    return request({
        method: 'post',
        url: '/api/power/addSub',
        data: {
            id, //   当前数据的id
            cate, //  # 当前数据的cate
            name, // # 新增机构或者部门名称
            pnums, //   # 机构人数
            charge, //  # 机构负责人
            charge_phone, // # 负责人电话
        }
    })
}

// 删除/api/power/deleteOrg
export const deleteOrg = ({
    id, //   当前信息的id
    cate
}) => {
    return request({
        method: 'post',
        url: '/api/power/deleteOrg',
        data: {
            id, //   当前信息的id
            cate
        }
    })
}
//收藏

export const collect = ({
    page,
    per_page,
    classify,
    choice
}) => {
    return request({
        method: 'post',
        url: '/api/user/collect ',
        data: {
            page,
            per_page,
            classify,
            choice
        }
    })
}

// 取消收藏
export const ddelete = ({
    id,
    classify,
    choice
}) => {
    return request({
        method: 'post',
        url: '/api/user/delete ',
        data: {
            id,
            classify,
            choice
        }
    })
}
// 6-获取机构，部门或者组详情()
export const orgDetail = ({
    id, //   当前信息的id
    cate, //  # 1机构，2部门，3组
}) => {
    return request({
        method: 'post',
        url: '/api/power/orgDetail',
        data: {
            id, //   当前信息的id
            cate, //  # 1机构，2部门，3组
        }
    })
}
// 6-7-获取机构，部门或者组的人员列表
export const orgStaff = ({
    id, //   当前信息的id
    cate, //  # 1机构，2部门，3组
}) => {
    return request({
        method: 'post',
        url: '/api/power/orgStaff',
        data: {
            id, //   当前信息的id
            cate, //  # 1机构，2部门，3组
        }
    })
}
// -机构部门或这组的人员 删除（人员失去组，机构项目）
export const deleteStaffOrg = ({
    id, //   当前信息的id
    cate, //  # 1机构，2部门，3组
    uid, //   当前人员id
}) => {
    return request({
        method: 'post',
        url: '/api/power/deleteStaffOrg',
        data: {
            id, //   当前信息的id
            cate, //  # 1机构，2部门，3组
            uid, //   当前人员id
        }
    })
}
//新增addStaffOrg
export const addStaffOrg = ({
    id, //   当前信息的id
    cate, //  # 1机构，2部门，3组
    uid, //   当前人员id
    level,
    position
}) => {
    return request({
        method: 'post',
        url: '/api/power/addStaffOrg',
        data: {
            id, //   当前信息的id
            cate, //  # 1机构，2部门，3组
            uid, //   当前人员id
            level,
            position
        }
    })
}
// 10- 带增人员列表listOfStaff
export const listOfStaff = ({
    id, //   当前信息的id
    cate, //  # 1机构，2部门，3组
}) => {
    return request({
        method: 'post',
        url: '/api/power/listOfStaff',
        data: {
            id, //   当前信息的id
            cate, //  # 1机构，2部门，3组
        }
    })
}
// 11-获取统筹机构得列表
export const orgCtrl = ({
    id, //   当前信息的id
    cate, //  # 1机构，2部门，3组
}) => {
    return request({
        method: 'post',
        url: '/api/power/orgCtrl',
        data: {
            id, //   当前信息的id
            cate, //  # 1机构，2部门，3组
        }
    })
}
// 11-获取统筹机构得列表
export const getCtrl = ({
    id, //   当前信息的id
}) => {
    return request({
        method: 'post',
        url: '/api/power/getCtrl',
        data: {
            id, //   当前信息的id
        }
    })
}
//机构详情页添加统筹机构
export const addBeCtrl = ({
    id, //   当前机构id
    ids, //   待新增机构的id数组
}) => {
    return request({
        method: 'post',
        url: '/api/power/addBeCtrl',
        data: {
            id, //   当前机构id
            ids, //   待新增机构的id数组
        }
    })
}
//机构详情页删除统筹机构
export const deleteCtrl = ({
    id, //   当前机构id
    bectrl_id //被删除机构的id
}) => {
    return request({
        method: 'post',
        url: '/api/power/deleteCtrl',
        data: {
            id, //   当前机构id
            bectrl_id // 被删除机构的id
        }
    })
}
//  editDetail
export const editDetail = ({
    id, //")  # 部门id
    cate, //")  # 1机构，2部门，3组
    name, //")  # 1机构，2部门，3组  的名称
    charge, //")  # 局内领导
    charge_phone, //")  # 联系方式
    func, //")  # 部门职能
    pnums, //")  # 人数
    secnums, //")  # 部门组人数
}) => {
    return request({
        method: 'post',
        url: '/api/power/editDetail',
        data: {
            id, //")  # 部门id
            cate, //")  # 1机构，2部门，3组
            name, //")  # 1机构，2部门，3组  的名称
            charge, //")  # 局内领导
            charge_phone, //")  # 联系方式
            func, //")  # 部门职能
            pnums, //")  # 人数
            secnums, //")  # 部门组人数
        }
    })
}
// 手机号验证码
export const ser_code = ({
    mobile
}) => {
    return request({
        method: 'post',
        url: '/api/user/user_code ',
        data: {
            mobile
        }
    })
}

// 更改手机号
export const phoneChange = ({
    mobile,
    sms_code
}) => {
    return request({
        method: 'post',
        url: '/api/user/phoneChange ',
        data: {
            mobile,
            sms_code
        }
    })
}


// 邮箱验证码
export const email_code = ({
    email
}) => {
    return request({
        method: 'post',
        url: '/api/user/email_code ',
        data: {
            email
        }
    })
}

// 更改邮箱确定
export const changeEmail = ({
    email,
    email_code
}) => {
    return request({
        method: 'post',
        url: '/api/user/changeEmail ',
        data: {
            email,
            email_code
        }
    })
}


// 更改密码
export const changepassword = ({
    password,
    repassword,
    newpass
}) => {
    return request({
        method: 'post',
        url: '/api/user/changepassword ',
        data: {
            password,
            repassword,
            newpass
        }
    })
}

// 提交
export const personal = ({
    real_name,
    age,
    sex,
    name
}) => {
    return request({
        method: 'post',
        url: '/api/user/personal ',
        data: {
            real_name,
            age,
            sex,
            name
        }
    })
}


// 2-获取角色列表get
export const roles = () => {
    return request({
        method: 'get',
        url: '/api/power/roles',
    })
}
// 1-角色新增
export const addRole = ({
    role_name, // 角色名
    rolev, //   权值
    info, //  角色描述
}) => {
    return request({
        method: 'post',
        url: '/api/power/addRole',
        data: {
            role_name, // 角色名
            rolev, //   权值
            info, //  角色描述
        }
    })
}
// 1-角色新增
export const updateRole = ({
    id,
    role_name, // 角色名
    rolev, //   权值
    info, //  角色描述
}) => {
    return request({
        method: 'post',
        url: '/api/power/updateRole',
        data: {
            id,
            role_name, // 角色名
            rolev, //   权值
            info, //  角色描述
        }
    })
}
// 1-角色新增
export const deleteRole = ({
    id
}) => {
    return request({
        method: 'post',
        url: '/api/power/deleteRole',
        data: {
            id
        }
    })
}

//gly
export const addManager = ({
    account, //")  # 账户名-字母开头
    password, //")  # 密码
    role, //")  # 角色 1超级管理员，2系统运维员
}) => {
    return request({
        method: 'post',
        url: '/api/power/addManager',
        data: {
            account, //")  # 账户名-字母开头
            password, //")  # 密码
            role, //")  # 角色 1超级管理员，2系统运维员
        }
    })
}
// 获取列表1
export const listField = ({ }) => {
    return request({
        method: 'get',
        url: '/api/power/listField ',
        data: {}
    })
}

// 改变状态1
export const fieldStatu = ({
    id,
    statu
}) => {
    return request({
        method: 'post',
        url: '/api/power/fieldStatu ',
        data: {
            id,
            statu
        }
    })
}

// 修改标题1
export const modifyFields = ({
    id,
    newname
}) => {
    return request({
        method: 'post',
        url: '/api/power/modifyFields ',
        data: {
            id,
            newname
        }
    })
}

// 获取列表2
export const listPropertyty = ({ }) => {
    return request({
        method: 'get',
        url: '/api/power/listPropertyty ',
        data: {}
    })
}

// 改变状态2
export const propertytyStatu = ({
    id,
    statu
}) => {
    return request({
        method: 'post',
        url: '/api/power/propertytyStatu ',
        data: {
            id,
            statu
        }
    })
}

// 修改标题2
export const modifyPropertyty = ({
    id,
    newname
}) => {
    return request({
        method: 'post',
        url: '/api/power/modifyPropertyty ',
        data: {
            id,
            newname
        }
    })
}


// 获取列表3
export const listProperty = ({ }) => {
    return request({
        method: 'get',
        url: '/api/power/listProperty ',
        data: {}
    })
}

// 改变状态3
export const propertyStatu = ({
    id,
    statu
}) => {
    return request({
        method: 'post',
        url: '/api/power/propertyStatu ',
        data: {
            id,
            statu
        }
    })
}

// 修改标题3
export const modifyProperty = ({
    id,
    newname
}) => {
    return request({
        method: 'post',
        url: '/api/power/modifyProperty ',
        data: {
            id,
            newname
        }
    })
}



// 获取列表4
export const listAttract = ({ }) => {
    return request({
        method: 'get',
        url: '/api/power/listAttract ',
        data: {}
    })
}

// 改变状态4
export const attractStatu = ({
    id,
    statu
}) => {
    return request({
        method: 'post',
        url: '/api/power/attractStatu ',
        data: {
            id,
            statu
        }
    })
}

// 修改标题4
export const modifyAttract = ({
    id,
    newname
}) => {
    return request({
        method: 'post',
        url: '/api/power/modifyAttract ',
        data: {
            id,
            newname
        }
    })
}





// 获取列表5
export const listZone = ({ }) => {
    return request({
        method: 'get',
        url: '/api/power/listZone ',
        data: {}
    })
}

// 改变状态5
export const zoneStatu = ({
    id,
    statu
}) => {
    return request({
        method: 'post',
        url: '/api/power/zoneStatu ',
        data: {
            id,
            statu
        }
    })
}

// 修改标题5
export const modifyZone = ({
    id,
    newname
}) => {
    return request({
        method: 'post',
        url: '/api/power/modifyZone ',
        data: {
            id,
            newname
        }
    })
}
// 招商地图散点图图
export const attractsdmap = ({
    inid, // 行业id
    entype,// 企业类型id
    qualificat,//  企业资质id
    capital,  // 注册资本id
    quoted,  // 上市板块id
    area,  // [省，市，区]
    yearid,   // 成立时间年限id
    roundid,  //  融资轮次id
    product,  //  链图的产品词
}) => {
    return request({
        method: 'post',
        url: '/api/att/attract/sdmap',
        data: {
            inid, // 行业id
            entype,// 企业类型id
            qualificat,//  企业资质id
            capital,  // 注册资本id
            quoted,  // 上市板块id
            area,  // [省，市，区]
            yearid,   // 成立时间年限id
            roundid,  //  融资轮次id
            product,  //  链图的产品词
        }
    })
}
// /api/address/industry/downList
export const downList = () => {
    return request({
        method: 'get',
        url: '/api/address/industry/downList'
    })
}
//搜索历史
export const seachhistory = () => {
    return request({
        method: 'get',
        url: '/api/portraits/industry/searchList',
    })
}

import Vue from 'vue'
import VueRouter from 'vue-router'
// import HelloWorld from '@/components/HelloWorld'
import login from "../views/log/login"
import home from "../views/home/home"
import registered from "../views/log/registered"
import yhhuvhxs from '../views/yhhu/yhhuvhxs'
import Thecockpit from '../views/cockpit/index'
import favq from '../views/industrial/favq'
import chuangxin from '../views/industrial/chuangxin'
import zaiti from '../views/industrial/zaiti'
import zaitiDetail from '../views/industrial/zaitiDetail'
import zhengce from '../views/industrial/zhengce'
import store from '../store'
import { Message } from 'element-ui';
Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push

VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
const routes = [{
  path: '/favq',
  name: 'favq',
  meta: {
    keepAlive: true  // 需要缓存
  },
  component: favq,
},
{
  path: '/',
  name: 'home',
  component: home
},
{
  path: '/chuangxin',
  name: 'chuangxin',
  component: chuangxin
},
{
  path: '/zaiti',
  name: 'zaiti',
  component: zaiti
},
{
  path: '/zaiti/detail',
  name: 'zaitiDetail',
  component: zaitiDetail
},
{
  path: '/zhengce',
  name: 'zhengce',
  component: zhengce
},
{
  path: '/Thecockpit',
  name: 'Thecockpit',
  meta: {
    keepAlive: true  // 需要缓存
  },
  component: Thecockpit,

},
{
  path: '/cockpit/video',
  name: 'cockpit',
  component: () => import('@/views/cockpit/video')
},
// {
//   path: '/',
//   name: 'login',
//   component: login
// },
{
  path: '/yhhuvhxs',
  name: 'yhhuvhxs',
  component: yhhuvhxs
},
{
  path: '/registered',
  name: 'registered',
  component: registered
},
{
  path: '/merchantsradar',
  name: 'merchantsradar',
  component: () => import('@/views/merchantsradar')
},
{
  path: '/merchantsradar/dynamic',
  name: 'dynamic',
  meta: {
    keepAlive: true  // 需要缓存
  },
  component: () => import('@/views/merchantsradar/dynamic')
},
{
  path: '/industrial/map',
  name: 'mpa',
  component: () => import('@/views/industrial/map')
},
{
  path: '/industrial/zhecedetails',
  name: 'zhecedetails',
  component: () => import('@/views/industrial/zhecedetails')
},
{
  path: '/industrial/chdetsils7',
  name: 'chdetsils7',
  component: () => import('@/views/industrial/chdetsils7')
},
{
  path: '/industrial/chdetsils6',
  name: 'chdetsils6',
  component: () => import('@/views/industrial/chdetsils6')
},
{
  path: '/industrial/chdetsils9',
  name: 'chdetsils9',
  component: () => import('@/views/industrial/chdetsils9')
},
{
  path: '/industrial/chdetsils2',
  name: 'chdetsils7',
  component: () => import('@/views/industrial/chdetsils2')
}, {
  path: '/industrial/chdetsils1',
  name: 'chdetsils7',
  component: () => import('@/views/industrial/chdetsils1')
},
{
  path: '/industrial/chdetsils4',
  name: 'chdetsils4',
  component: () => import('@/views/industrial/chdetsils4')
},
{
  path: '/selectionadd/selectionaddjiany',
  name: 'selectionaddjiany',
  component: () => import('@/views/selectionadd/selectionaddjiany')
},
{
  path: '/selectionadd/selectionaddpinggu',
  name: 'selectionaddpinggu',
  meta: {
    keepAlive: true  // 需要缓存
  },
  component: () => import('@/views/selectionadd/selectionaddpinggu')
},
{
  path: '/zhaoshresource/projectgl',
  name: 'projectgl',
  meta: {
    keepAlive: true  // 需要缓存
  },
  component: () => import('@/views/zhaoshresource/projectgl')
},
{
  path: '/zhaoshresource/projectgldetsils',
  name: 'projectgldetsils',
  component: () => import('@/views/zhaoshresource/projectgldetsils')
},
{
  path: '/zhaoshresource/projectgldetsilsbianj',
  name: 'projectgldetsilsbianj',
  component: () => import('@/views/zhaoshresource/projectgldetsilsbianj')
},
{
  path: '/zhaoshresource/projectNew',
  name: 'projectNew',
  component: () => import('@/views/zhaoshresource/projectNew')
},
{
  path: '/zhaoshresource/Reporttracking',
  name: 'Reporttracking',
  component: () => import('@/views/zhaoshresource/Reporttracking')
},
{
  path: '/zhaoshresource/statistics',
  name: 'statistics',
  component: () => import('@/views/zhaoshresource/statistics')
},
{
  path: '/zhaoshresource/quyugl',
  name: 'quyugl',
  component: () => import('@/views/zhaoshresource/quyugl')
},
{
  path: '/zhaoshresource/quluglook',
  name: 'quluglook',
  component: () => import('@/views/zhaoshresource/quluglook')
},
{
  path: '/zhaoshresource/yuanqugledit',
  name: 'yuanqugledit',
  component: () => import('@/views/zhaoshresource/yuanqugledit')
},
{
  path: '/zhaoshresource/quyugaunliindex',
  name: 'quyugaunliindex',
  component: () => import('@/views/zhaoshresource/quyugaunliindex')
},
{
  path: '/360portrait/360portrait',
  name: 'portrait',
  meta: {
    keepAlive: true  // 需要缓存
  },
  component: () => import('@/views/360portrait/360portrait')
},
{
  path: '/comp/comp',
  name: 'comp',
  component: () => import('@/views/comp/comp')
},
{
  path: '/ldcomp/ldcomp',
  name: 'ldcomp',
  component: () => import('@/views/ldcomp/ldcomp')
},
{
  path: '/zsmap/investmentmap',
  name: 'investmentmap',
  meta: {
    keepAlive: true  // 需要缓存
  },
  component: () => import('@/views/zsmap/investmentmap')
},
{
  path: '/systemgl/systemglzuzhi',
  name: 'systemglzuzhi',
  component: () => import('@/views/systemgl/systemglzuzhi')
},
{
  path: '/systemgl/systemglhou',
  name: 'systemglhou',
  component: () => import('@/views/systemgl/systemglhou')
},
{
  path: '/systemgl/systemgljuese',
  name: 'systemgljuese',
  component: () => import('@/views/systemgl/systemgljuese')
},
{
  path: '/systemgl/systemglzuzhibianj',
  name: 'systemglzuzhibianj',
  component: () => import('@/views/systemgl/systemglzuzhibianj')
},
{
  path: '/systemgl/systemglzuzhibianj2',
  name: 'systemglzuzhibianj2',
  component: () => import('@/views/systemgl/systemglzuzhibianj2')
},
{
  path: '/systemgl/systemglshuju',
  name: 'systemglshuju',
  component: () => import('@/views/systemgl/systemglshuju')
},
{
  path: '/alifuneng',
  name: 'alifuneng',
  component: () => import('@/views/alifuneng')
},
]



const router = new VueRouter({
  routes,
})
router.beforeEach((to, from, next) => {
  if (to.path === '/' || to.path === '/registered') {
    next();
  } else {
    let token = localStorage.getItem('login');
    if (!token) {
      next('/');
    } else {
      let flag = store.state.flag
      console.log(111);
      console.log(flag);
      console.log(111);
      let routerList = []
      if (flag == 3) {
        routerList = [
          'systemglzuzhi',
          'systemglhou',
          'systemgljuese',
          'systemglzuzhibianj',
          'systemglzuzhibianj2',
          'systemglshuju'
        ]
        console.log(to.name);
        console.log(routerList.includes(to.name));
        if (routerList.includes(to.name)) {
          next()
        } else {
          Message('暂无权限')
        }
      } else if (flag == 1) {
        routerList = [
          "Thecockpit",
          "comp",
          "zhecedetails",
          "chdetsils7",
          "chdetsils6",
          "chdetsils9",
          "chdetsils4",
          "yhhuvhxs"
        ]
        if (routerList.includes(to.name)) {
          next()
        } else {
          Message('暂无权限')
        }
      } else if (flag == 2) {
        routerList = [
          'systemglzuzhi',
          'systemglhou',
          'systemgljuese',
          'systemglzuzhibianj',
          'systemglzuzhibianj2',
          'systemglshuju'
        ]
        if (routerList.includes(to.name)) {
          Message('暂无权限')
        } else {
          next()
        }
      } else if (flag == 0) {
        Message('请登录')
        next('/');
      }
    }
  }
});
export default router
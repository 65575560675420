<template>
  <div class="registered">
    <div class="dabox">太原市招商图谱平台</div>
    <div class="zhubox">
      <form ref="form" :model="form">
        <li>
          <span>姓名</span>
          <el-input
            v-model="form.real_name"
            placeholder="请输入姓名"
          ></el-input>
        </li>
        <li>
          <span>年龄</span>
          <el-input v-model="form.age" placeholder="请输入年龄" />
        </li>
        <li>
          <span>性别</span>

          <el-select v-model="form.sex" slot="prepend" placeholder="请选择">
            <el-option label="男" value="1"></el-option>
            <el-option label="女" value="2"></el-option>
          </el-select>
        </li>
        <li>
          <span>手机号</span>
          <el-input
            v-model="form.mobile"
            placeholder="请输入手机"
            type="text"
          />
        </li>
        <li>
          <span>验证码</span>
          <span class="yzm" @click="getyzm()">获取验证码</span>
          <el-input
            v-model="form.sms_code"
            placeholder="请输入验证码"
            type="text"
          />
        </li>
        <li>
          <span>密码</span>
          <el-input
            v-model="form.password"
            placeholder="请输入密码"
            type="password"
          />
        </li>
        <li>
          <span>确认密码</span>
          <el-input
            v-model="form.repassword"
            placeholder="再次输入密码"
            type="password"
          />
        </li>
        <li>
          <span>用户名</span>
          <el-input
            v-model="form.name"
            placeholder="请输入用户名"
            type="text"
          />
        </li>
        <li>
          <span>职务</span>
          <el-input
            v-model="form.position"
            placeholder="请输入职务"
            type="text"
          />
        </li>
        <li>
          <span>单位</span>
          <el-select
            v-model="form.unit"
            slot="prepend"
            placeholder="请选择"
            @change="labelchange($event)"
          >
            <el-option
              v-for="item in monadoptions"
              :key="item.value"
              :label="item.label"
              :value="item.label"
            ></el-option>
          </el-select>
        </li>
        <li v-if="branchshow">
          <span>所属部门</span>
          <el-select v-model="form.section" slot="prepend" placeholder="请选择">
            <el-option
              v-for="item in branchoptions"
              :key="item.value"
              :label="item.label"
              :value="item.label"
            ></el-option>
          </el-select>
        </li>
      </form>
      <div class="bgji">
        <span @click="$router.push({ name: 'home' })">返回</span>
        <span @click="onSubmit">确认</span>
      </div>
    </div>
  </div>
</template>

<script>
import { getorgs, getyzm, getregister } from "@/api/index";
export default {
  name: "registered",
  data() {
    return {
      input1: "",
      select: "",
      form: {
        real_name: "",
        age: "",
        sex: "",
        mobile: "",
        sms_code: "",
        password: "",
        repassword: "",
        position: "",
        unit: "",
        section: "",
        name: "",
      },
      time: "60",
      monadoptions: [],
      branchoptions: [],
      branchshow: false,
    };
  },
  created() {
    this.getorgsdata();
  },
  methods: {
    async getorgsdata() {
      const res = await getorgs();
      this.monadoptions = res.orgs.data;
      this.branchoptions = res.orgs.section;
    },

    allSame(value) {
      let data = value.split("");
      let chartCode = []; // 存放ASCII码
      let same = true;
      for (let i in data) {
        chartCode.push(value.charCodeAt(i));
        if (chartCode.length === data.length) {
          for (let i = 1; i <= chartCode.length; i++) {
            if (Math.abs(Number(chartCode[i]) - Number(chartCode[i - 1])) > 1) {
              same = false;
              break;
            }
          }
        }
      }
      return same;
    },
    passMessage(s) {
      var i = /[0-9]+/;
      var str = /[A-Za-z]/;
      var spec = /[,.<>{}~!@#$%^&*]/;
      if (!str.test(s)) {
        return "必须包含字母";
      }
      if (!i.test(s)) {
        return "必须包含数字";
      }
      if (!spec.test(s)) {
        return "必须包含特殊字符。例如：!@#$%^&* 其中一个或多个";
      }
    },
    async onSubmit() {
      let msg = this.passMessage(this.form.password);
      if (msg) {
        this.$message({
          message: msg,
          type: "error",
        });
        return false;
      }
      if (this.form.password.length < 8) {
        this.$message({
          message: "密码长度应8位以上",
          type: "error",
        });
        return false;
      }
      if (this.allSame(this.form.password)) {
        this.$message({
          message: "不能使用连续性字母，数字作为密码",
          type: "error",
        });
        return false;
      }
      if (this.form.password != this.form.repassword) {
        this.$message({
          message: "两次输入的密码不一致",
          type: "error",
        });
        return false;
      }
      if (this.form.real_name == "") {
        this.$message({
          message: "请输入姓名",
          type: "error",
        });
        return false;
      } else if (this.form.age == "") {
        this.$message({
          message: "请输入年龄",
          type: "error",
        });
        return false;
      } else if (this.form.sex == "") {
        this.$message({
          message: "请选择性别",
          type: "error",
        });
        return false;
      } else if (this.form.sms_code == "") {
        this.$message({
          message: "请输入验证码",
          type: "error",
        });
      } else if (this.form.position == "") {
        this.$message({
          message: "请输入职务",
          type: "error",
        });
        return false;
      } else if (this.form.unit == "") {
        this.$message({
          message: "请选择单位",
          type: "error",
        });
        return false;
      } else if (this.form.name == "") {
        this.$message({
          message: "请输入用户名",
          type: "error",
        });
        return false;
      }
      const res = await getregister(this.form);
      if (res.code == 0) {
        this.$message({
          message: res.msg,
          type: "success",
        });
      } else {
        this.$message({
          message: res.msg,
          type: "error",
        });
      }
    },
    // 获取验证码
    async getyzm() {
      this.time = 60;
      this.countdown();
      this.timeshow = 1;
      console.log(this.form.mobile);
      if (this.form.mobile == "") {
        this.$message({
          message: "请输入手机号",
          type: "error",
        });
      }
      const res = await getyzm({
        mobile: this.form.mobile,
      });
      if (res.code == 4900) {
        this.$message.error(res.msg);
      } else if (res.code == 0) {
        this.$message({
          message: "发送成功",
          type: "success",
        });
        this.timeshow = 1;
      }
    },
    countdown() {
      var that = this;
      setInterval(function() {
        if (that.time > 0) {
          that.time--;
        } else {
          that.timeshow = 2;
        }
      }, 1000);
    },
    labelchange(index) {
      let obj = {};
      obj = this.monadoptions.find((item) => {
        return item.label === index; //筛选出匹配数据
      });
      if (obj.value === 1) {
        this.branchshow = true;
      } else {
        this.branchshow = false;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.registered {
  width: 100%;
  min-height: 1200px;
  background: rgb(243, 243, 243);
  // position: relative;
  padding-bottom: 40px;
  .dabox {
    width: 100%;
    height: 430px;
    background: #fe6a00;
    font-size: 36px;
    font-weight: bold;
    color: #ffffff;
    line-height: 172px;
  }

  .zhubox {
    width: 1056px;
    min-height: 850px;
    background: #ffffff;
    border-radius: 10px;
    position: absolute;
    top: 184px;
    left: 50%;
    padding-top: 4%;
    padding-bottom: 30px;
    transform: translateX(-50%);
    li {
      position: relative;
      list-style-type: none;
      width: 500px;
      height: 50px;
      margin-bottom: 25px;
      margin-left: 250px;
      .yzm {
        position: absolute;
        top: 0px;
        right: 8px;
        width: 148px;
        font-size: 14px;
        font-weight: 400;
        color: #fe6a00;
        cursor: pointer;
        z-index: 10;
      }
      span {
        display: block;
        float: left;
        width: 125px;
        height: 50px;
        font-size: 14px;
        line-height: 50px;
        padding-left: 65px;
        text-align: left;
      }

      /deep/ .el-input {
        width: 375px;
        height: 50px;
        line-height: 50px;
        background: #ffffff;
      }

      /deep/ .el-input__inner {
        border-radius: 25px;
      }
    }

    .bgji {
      margin-left: 359px;
      span {
        display: block;
        width: 160px;
        height: 50px;
        border: 1px solid #fe6a00;
        border-radius: 25px;
        background: #fe6a00;
        line-height: 50px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #ffffff;
        float: left;
        margin-right: 18px;
        cursor: pointer;
      }
    }
  }
}
</style>

<template>
  <div class="Thecockpit">
    <div class="head">
      <div class="bnti">
        <img src="../../assets/cockpit/logos.png" alt />
      </div>

      <ul class="centers">
        <li class="hover">
          <img src="../../assets/cockpit/t1.png" alt />
          <span>招商驾驶舱</span>
        </li>
        <li
          :class="$route.name == 'favq' ? 'active' : ''"
          @click="$router.push({ name: 'favq' })"
        >
          <img src="../../assets/cockpit/t2.png" alt />
          <span>产业现状图谱</span>
        </li>
        <li @click="$router.push({ name: 'dynamic' })">
          <img src="../../assets/cockpit/t3.png" alt />
          <span>产业招商雷达</span>
        </li>
        <li @click="$router.push({ name: 'investmentmap' })">
          <img src="../../assets/cockpit/t4.png" alt />
          <span>产业招商地图</span>
        </li>
        <li @click="$router.push({ name: 'selectionaddpinggu' })">
          <img src="../../assets/cockpit/t5.png" alt />
          <span>智能选址评估</span>
        </li>
        <li @click="$router.push({ name: 'portrait' })">
          <img src="../../assets/cockpit/t6.png" alt />
          <span>360企业画像</span>
        </li>
        <li @click="$router.push({ name: 'projectgl' })">
          <img src="../../assets/cockpit/t8.png" alt />
          <span>招商资源管理</span>
        </li>
        <!-- <li @click="flag == 2 || flag == 3?jumpnewwww():quexian()">
          <img src="../../assets/cockpit/t7.png" alt />
          <span>中博会</span>
        </li> -->
      </ul>
      <div class="right">
        <span @click="$router.push({ name: 'yhhuvhxs' })">
          <img src="../../assets/login/yhhu.png" alt />用户中心
        </span>
        <span @click="exitlogin()">
          <img src="../../assets/login/tkdd.png" alt />退出登录
        </span>
      </div>
    </div>
    <!-- <p style="background: #02044a;text-align:left;color:#fff;font-size:18px;position: absolute;
    left: 52px;top: 73px;">投资服务热线：96301转0351</p> -->
    <div class="img-top">招商驾驶舱</div>
    <div class="zzon clearfix">
      <div class="center-top">
        <div class="c-left">
          <div class="top">
            <img class="jiao1" src="../../assets/cockpit/zuojiao.png" alt />
            <img class="jiao2" src="../../assets/cockpit/youjiao.png" alt />
            <img class="jiao3" src="../../assets/cockpit/xiazuojiao.png" alt />
            <img class="jiao4" src="../../assets/cockpit/xiayoujiao.png" alt />
            <div class="sijn">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
            <div class="nc-box">
              <ul class="clearfix" style="border: 1px solid #1d2f69">
                <li>
                  <span data-classify="1" data-type="1" @click="comp_detail"
                    >{{ tabledata[0].name }}: {{ tabledata[0].nums }}家</span
                  >
                  <span style="cursor:default"
                    >{{ tabledata[1].name }}: {{ tabledata[1].nums }}</span
                  >
                </li>
                <li>
                  <span data-classify="1" data-type="2" @click="comp_detail"
                    >{{ tabledata[2].name }}: {{ tabledata[2].nums }}家</span
                  >
                  <span data-classify="1" data-type="3" @click="comp_detail"
                    >{{ tabledata[3].name }}: {{ tabledata[3].nums }}家</span
                  >
                </li>
                <li>
                  <span data-classify="1" data-type="4" @click="comp_detail"
                    >{{ tabledata[4].name }}: {{ tabledata[4].nums }}家</span
                  >
                  <span data-classify="1" data-type="5" @click="comp_detail"
                    >{{ tabledata[5].name }}: {{ tabledata[5].nums }}家</span
                  >
                </li>
                <li>
                  <span data-classify="1" data-type="6" @click="comp_detail"
                    >{{ tabledata[6].name }}: {{ tabledata[6].nums }}家</span
                  >
                  <span data-classify="1" data-type="7" @click="comp_detail"
                    >{{ tabledata[7].name }}: {{ tabledata[7].nums }}家</span
                  >
                </li>
                <li>
                  <span data-classify="2" data-type="1" @click="comp_detail"
                    >{{ tabledata[8].name }}: {{ tabledata[8].nums }}所</span
                  >
                  <span data-classify="2" data-type="2" @click="comp_detail"
                    >{{ tabledata[9].name }}: {{ tabledata[9].nums }}所</span
                  >
                </li>
                <li>
                  <span data-classify="3" data-type="1" @click="comp_detail"
                    >{{ tabledata[10].name }}: {{ tabledata[10].nums }}所</span
                  >
                  <span data-classify="4" data-type="1" @click="comp_detail"
                    >{{ tabledata[11].name }}: {{ tabledata[11].nums }}所</span
                  >
                </li>
              </ul>
            </div>
          </div>
          <div class="bottom">
            <img class="jiao1" src="../../assets/cockpit/zuojiao.png" alt />
            <img class="jiao2" src="../../assets/cockpit/youjiao.png" alt />
            <img class="jiao3" src="../../assets/cockpit/xiazuojiao.png" alt />
            <img class="jiao4" src="../../assets/cockpit/xiayoujiao.png" alt />
            <div class="sixbox">
              <h1>
                <span>产业结构分布统计</span>
              </h1>
              <div id="two"></div>
              <div class="one-orinet">
                <ul>
                  <li v-for="(item, index) in oneData" :key="index">
                    <i :style="item.color"></i>
                    {{ item.name }} {{ item.value }}%
                  </li>
                </ul>
              </div>
            </div>
            <div class="sijn">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        </div>
        <div class="c-center">
          <img class="jiao1" src="../../assets/cockpit/zuojiao.png" alt />
          <img class="jiao2" src="../../assets/cockpit/youjiao.png" alt />
          <img class="jiao3" src="../../assets/cockpit/xiazuojiao.png" alt />
          <img class="jiao4" src="../../assets/cockpit/xiayoujiao.png" alt />
          <div class="bnnti clearfix">
            <el-carousel
              autoplay
              :initial-index="indexcurrent"
              indicator-position="outside"
              height="118px"
              style="width:100%;"
            >
              <el-carousel-item v-for="(item, index) of lunbodata" :key="index">
                <div>
                  <div
                    style=" text-align:left;padding-left:20px;margin-left:20px;background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAICAYAAADED76LAAAAcklEQVQoU4XPwQnCYBBE4W/vXmzCGqxA75ahHWgB6cEekgIE75ZgF4J6NNnwB4IgxOxteA9mJyAPVtmphE3J0iVapzi7xwDTDcsBfu8RYR3dXo3dDxxjU4QXFhPCe054/q0Imvknx5lSlWzR4hofxzKzBy8WKgh01CzJAAAAAElFTkSuQmCC) no-repeat left center;
    font-size: 14px;
    color: #fe6a00;
    "
                  >
                    {{ item.desc }}
                  </div>
                  <ul
                    style="display:flex;align-items:center;flex-wrap:wrap;padding:5px 10px;"
                  >
                    <li
                      v-for="(its, i) of item.list"
                      :key="i"
                      style="width:25%"
                    >
                      <span style="width: auto !important;margin-bottom:2px">
                        {{ its.nums }}
                      </span>
                      <span style="width: auto !important;margin-bottom:4px;">{{
                        its.name
                      }}</span>
                    </li>
                  </ul>
                </div>
              </el-carousel-item>
            </el-carousel>
          </div>
          <!-- <div class="bnnti clearfix" style="background-color:red;" >
             
            <li v-for="(item, index) in citydata" :key="index" style="width:25%">
              <span style="width: auto !important;margin-bottom:2px">
                {{ item.nums }}
              </span>
              <span style="width: auto !important;margin-bottom:4px;">{{ item.name }}</span>
            </li>
          </div> -->
          <div
            style="height: calc(100% - 150px); position: relative;width:78%;margin:auto;"
          >
            <div>
              <!-- <div style="background-color:red;width:20px;height:20px;top:0;left:0;">sdfs</div> -->
              <!-- <p
                style="position:absolute;top:35px;right:40px;color:#fff;background-color:#17A5FE;border-radius:5px;padding:5px 10px;"
              >{{district}}</p> -->
            </div>
            <div
              style="
                width: 100%;
                height: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                position:relative;
              "
            >
              <div
                style="width:100%;height:100%;position:absolute;left:0;top:0;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                opacity:0;"
              >
                <img
                  style="width: 90%;"
                  src="@/assets/login/ditu.png"
                  usemap="#image-map"
                  ref="image_map"
                />

                <map style="cursor:pointer;" name="image-map" id="image-map">
                  <area
                    v-for="item in ListData"
                    :key="item.id"
                    @click="bindTitle(item.name, item.id)"
                    target=""
                    :alt="item.name"
                    :title="item.name"
                    :coords="item.coords.join(',')"
                    shape="poly"
                  />

                  <div style="">
                    水电
                  </div>
                </map>
                <p
                  class="note"
                  style="
                  color: #fff;
                  font-size: 14px;
                  padding: 0 20px;
                  margin-top: 20px;
                  position:absolute;
                  bottom:30px;
                "
                >
                  {{ note }}
                </p>
              </div>

              <img
                style="width: 90%"
                v-if="titleIndex == 1"
                src="@/assets/login/ditu.png"
                alt
              />

              <img
                style="width: 90%"
                v-if="titleIndex == 6"
                src="@/assets/map/xd (2).png"
                alt
              />
              <img
                style="width: 90%"
                v-if="titleIndex == 5"
                src="@/assets/map/yz (2).png"
                alt
              />
              <img
                style="width: 90%"
                v-if="titleIndex == 4"
                src="@/assets/map/xhl (2).png"
                alt
              />
              <img
                style="width: 90%"
                v-if="titleIndex == 2"
                src="@/assets/map/jcp (2).png"
                alt
              />
              <img
                style="width: 90%"
                v-if="titleIndex == 3"
                src="@/assets/map/wbl (2).png"
                alt
              />
              <img
                style="width: 90%"
                v-if="titleIndex == 7"
                src="@/assets/map/jy (2).png"
                alt
              />
              <img
                style="width: 90%"
                v-if="titleIndex == 11"
                src="@/assets/map/gj (2).png"
                alt
              />
              <img
                style="width: 90%"
                v-if="titleIndex == 8"
                src="@/assets/map/qx (2).png"
                alt
              />
              <img
                style="width: 90%"
                v-if="titleIndex == 10"
                src="@/assets/map/yq (2).png"
                alt
              />
              <img
                style="width: 90%"
                v-if="titleIndex == 9"
                src="@/assets/map/lf (2).png"
                alt
              />

              <div
                style="width: 560px; position: relative"
                v-if="titleIndex == 12"
              >
                <img
                  style="width: 25px; position: absolute; top: 68%; left: 65%"
                  src="@/assets/cockpit/map_icon12.png"
                  alt
                />
                <img
                  style="width: 25px; position: absolute; top: 80%; left: 60%"
                  src="@/assets/cockpit/map_icon12.png"
                  alt
                />
                <img
                  style="width: 25px; position: absolute; top: 27%; left: 75%"
                  src="@/assets/cockpit/map_icon12.png"
                  alt
                />
                <!-- <img
                          style="width:25px;position:absolute;top:37%;left:64%;"
                          src="@/assets/cockpit/map_icon12.png"
                          alt
                />-->

                <img style="width: 560px" src="@/assets/login/ditu.png" alt />
              </div>
              <div
                style="width: 560px; position: relative"
                v-if="titleIndex == 13"
              >
                <img
                  style="width: 25px; position: absolute; top: 43%; left: 48%"
                  src="@/assets/cockpit/map_icon13.png"
                  alt
                />

                <img style="width: 560px" src="@/assets/login/ditu.png" alt />
              </div>

              <div
                style="width: 560px; position: relative"
                v-if="titleIndex == 14"
              >
                <img
                  style="width: 25px; position: absolute; top: 40%; left: 48%"
                  src="@/assets/cockpit/map_icon14.png"
                  alt
                />
                <img
                  style="width: 25px; position: absolute; top: 51%; left: 46%"
                  src="@/assets/cockpit/map_icon14.png"
                  alt
                />
                <img
                  style="width: 25px; position: absolute; top: 68%; left: 49%"
                  src="@/assets/cockpit/map_icon14.png"
                  alt
                />
                <img style="width: 560px" src="@/assets/login/ditu.png" alt />
              </div>

              <div
                style="width: 560px; position: relative"
                v-if="titleIndex == 15"
              >
                <img
                  style="width: 25px; position: absolute; top: 80%; left: 48%"
                  src="@/assets/cockpit/map_icon15.png"
                  alt
                />

                <img style="width: 560px" src="@/assets/login/ditu.png" alt />
              </div>
              <!-- @click="bindTitle('item.name', item.id)" -->
              <!-- { id: 12, name: "综改示范区" },
        { id: 13, name: "中北高新区" },
        { id: 15, name: "清徐开发区" },
        { id: 16, name: "阳曲示范区" },
        { id: 14, name: "西山示范区" }, -->
              <div
                style="width: 560px; position: relative"
                v-if="titleIndex == 16"
              >
                <img
                  style="width: 25px; position: absolute; top: 22%; left: 64%"
                  src="@/assets/cockpit/map_icon16.png"
                  alt
                />

                <img style="width: 560px" src="@/assets/login/ditu.png" alt />
              </div>
              <p
                class="note"
                style="
                  color: #fff;
                  font-size: 14px;
                  padding: 0 20px;
                  margin-top: 20px;
                  position:absolute;
                  bottom:30px;
                "
                @click="zhu"
              >
                <!-- 注：地区生产总值、社会消费品零售总额、居民可支配收入为2020年1-3季度数据，其他指标为1-11月数据生产总值、社会消费品零售总额、居民可支配 -->
                <!-- {{lunbodata[carousindex].desc}} -->
              </p>
            </div>
          </div>
        </div>
        <div class="c-right">
          <img class="jiao1" src="../../assets/cockpit/zuojiao.png" alt />
          <img class="jiao2" src="../../assets/cockpit/youjiao.png" alt />
          <img class="jiao3" src="../../assets/cockpit/xiazuojiao.png" alt />
          <img class="jiao4" src="../../assets/cockpit/xiayoujiao.png" alt />
          <h1>
            <!-- 太原市区县GDP经济排行榜（万元）（2019年） -->
            <!-- <span v-if="this.district == '太原市'"
              >{{gdpTitle}}</span
            >
            <span v-if="this.district != '太原市'"
              >{{ this.district }}区域简介</span
            > -->
            <span>{{ this.district }}区域简介</span>
          </h1>
          <div
            class="showvideo"
            style="display:flex;justify-content:flex-end;margin-bottom:10px;"
          >
            <!-- v-show="showbtnvideo" -->
            <span
              @click="showvideo"
              style="background-color:#fe6a00;color:#fff;padding:5px 10px;margin-right:30px;border-radius:50px;cursor:pointer"
              >隐藏/显示视频</span
            >
          </div>
          <video
            controlslist="nodownload"
            v-show="isvid"
            disablePictureInPicture="true"
            :src="url"
            width="100%;"
            controls
          >
            <!-- http://121.36.91.153:82/video/01.mp4 -->
          </video>
          <div v-show="false" style="width: 100%;height:90%">
            <div id="main2" style="width: 100%; height: 100%"></div>
          </div>
          <!--  width: calc(100% - 40px);  -->
          <div
            v-show="true"
            style="

              font-size: 16px;
              color: #fff;
              text-align: left;
              text-indent: 24px;
              padding: 0 20px;
              padding-top: 30px;
              overflow: auto;
            "
            :style="vioheight"
            class="infoData"
          >
            <div v-html="getInfoData"></div>
          </div>
        </div>
      </div>

      <div class="center-center" v-show="!titleFlag">
        <div class="c-left">
          <img class="jiao1" src="../../assets/cockpit/zuojiao.png" alt />
          <img class="jiao2" src="../../assets/cockpit/youjiao.png" alt />
          <img class="jiao3" src="../../assets/cockpit/xiazuojiao.png" alt />
          <img class="jiao4" src="../../assets/cockpit/xiayoujiao.png" alt />
          <h1>
            <span>{{ project_info }}</span>
            <!-- 太原市各县（市、区）、开发区招商引资作战图（2020年12月） -->
          </h1>
          <div id="main4" style="width: 100%; height: 90%"></div>
          <div class="sijn">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
        <div class="c-right">
          <img class="jiao1" src="../../assets/cockpit/zuojiao.png" alt />
          <img class="jiao2" src="../../assets/cockpit/youjiao.png" alt />
          <img class="jiao3" src="../../assets/cockpit/xiazuojiao.png" alt />
          <img class="jiao4" src="../../assets/cockpit/xiayoujiao.png" alt />
          <h1>
            <span>{{ project_rightinfo }}</span>
            <!-- 太原市各县（市、区）、开发区签约项目金额产业分布图（2020年11月） -->
          </h1>
          <div id="main3" style="width: 100%; height: 340px"></div>

          <ul class="iiye">
            <li v-for="(item, index) in project_industry" :key="index">
              <img :src="img[index]" alt />
              <span :class="index == hov_index ? 'light_main3' : ''"
                >{{ item.name }}，{{ item.value }}亿元</span
              >
            </li>
            <!--            <li><img src="../../assets/cockpit/fang2.png" alt />fdsfdfdfdf</li>-->
            <!--            <li><img src="../../assets/cockpit/fang3.png" alt />新材料产业8个项目91.5亿</li>-->
            <!--            <li><img src="../../assets/cockpit/fang4.png" alt />uoui.9亿</li>-->
            <!--            <li><img src="../../assets/cockpit/fang5.png" alt />新一代信息技术产业7个项目118.1亿</li>-->
            <!--            <li><img src="../../assets/cockpit/fang6.png" alt />高端装备制造业23个项目426.9亿</li>-->
            <!--            <li><img src="../../assets/cockpit/fang7.png" alt />节能环保产业7个项目47.6亿</li>-->
            <!--            <li><img src="../../assets/cockpit/fang8.png" alt />高端装备制造业23个项目426.9亿</li>-->
            <!--            <li><img src="../../assets/cockpit/fang9.png" alt />uoui.9亿</li>-->
            <!--            <li><img src="../../assets/cockpit/fang10.png" alt />新能源产业3个项目16亿</li>-->
            <!--            <li><img src="../../assets/cockpit/fang11.png" alt />高端装备制造业23个项目426.9亿</li>-->
            <!--            <li><img src="../../assets/cockpit/fang12.png" alt />生物产业12个项目267.6亿</li>-->
            <!--            <li><img src="../../assets/cockpit/fang13.png" alt />煤化工产业2个项目31.5亿</li>-->
            <!--            <li><img src="../../assets/cockpit/fang14.png" alt />uoui.9亿</li>-->
            <!--            <li><img src="../../assets/cockpit/fang15.png" alt />煤层气产业1个项目10亿</li>-->
            <!--            <li><img src="../../assets/cockpit/fang16.png" alt />高端装备制造业23个项目426.9亿</li>-->
          </ul>
        </div>
      </div>
      <div class="center-bottom">
        <div class="c-left">
          <img class="jiao1" src="../../assets/cockpit/zuojiao.png" alt />
          <img class="jiao2" src="../../assets/cockpit/youjiao.png" alt />
          <img class="jiao3" src="../../assets/cockpit/xiazuojiao.png" alt />
          <img class="jiao4" src="../../assets/cockpit/xiayoujiao.png" alt />
          <h1 style="margin-bottom: 38px">
            <span>重点招商项目列表</span>
          </h1>
          <ul class="nc-box">
            <li class="zhongdain">
              <span>重点招商项目列表</span>
            </li>
            <div>
              <vue-seamless-scroll class="seamless-warp">
                <ul>
                  <li
                    v-for="(item, index) in project"
                    :key="index"
                    :data-obj="item.id"
                    @click="bindList(item.id)"
                  >
                    <span :title="item.name" :id="index + 1">{{
                      item.name
                    }}</span>
                  </li>
                </ul>
                <!-- <ul style="float:right;width:50%;">
                  <li
                    v-for="(item, index) in project"
                    :key="index"
                    :data-obj="item.id"
                    @click="bindList(item.id)"
                  >
                    <span :id="index + 1">{{ item.name }}</span>
                  </li>
                </ul> -->
              </vue-seamless-scroll>
            </div>
          </ul>

          <div class="sijn">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
        <div class="c-right">
          <img class="jiao1" src="../../assets/cockpit/zuojiao.png" alt />
          <img class="jiao2" src="../../assets/cockpit/youjiao.png" alt />
          <img class="jiao3" src="../../assets/cockpit/xiazuojiao.png" alt />
          <img class="jiao4" src="../../assets/cockpit/xiayoujiao.png" alt />
          <h1 style="margin-bottom: 38px">
            <span>招商政策列表</span>
          </h1>
          <ul class="nc-box">
            <li class="zhongdain" style="background-color: #1d2f69">
              <span>招商政策列表</span>
            </li>
            <li class="zhongdain" v-show="!titleFlag">
              <span style="display: inline-block; width: 50%"
                >山西省招商政策列表</span
              >
              <span style="display: inline-block; width: 50%"
                >太原市招商政策列表</span
              >
            </li>
            <div v-show="!titleFlag">
              <vue-seamless-scroll
                class="seamless-warp"
                style="float: left; width: calc(50% - 10px); height: 228px"
              >
                <ul>
                  <li
                    v-for="(item, index) in policy_province"
                    :data-obj="item.id"
                    :class-option="classOption"
                    :key="index"
                    @click="bindList1(item.id)"
                    style="padding: 0 5px"
                  >
                    <span :title="item.name"
                      >{{ item.name }}（{{ item.distict }}）</span
                    >
                  </li>
                </ul>
              </vue-seamless-scroll>
              <vue-seamless-scroll
                class="seamless-warp"
                style="float: right; width: calc(50% - 10px); height: 228px"
              >
                <ul>
                  <li
                    v-for="(item, index) in policy"
                    :data-obj="item.id"
                    :class-option="classOption"
                    :key="index"
                    @click="bindList1(item.id)"
                    style="padding: 0 5px"
                  >
                    <span :title="item.name"
                      >{{ item.name }}（{{ item.distict }}）</span
                    >
                  </li>
                </ul>
              </vue-seamless-scroll>
            </div>
            <div v-show="titleFlag">
              <vue-seamless-scroll class="seamless-warp">
                <ul>
                  <li
                    v-for="(item, index) in policy"
                    :data-obj="item.id"
                    :class-option="classOption"
                    :key="index"
                    @click="bindList1(item.id)"
                  >
                    <span>{{ item.name }}（{{ item.distict }}）</span>
                  </li>
                </ul>
              </vue-seamless-scroll>
            </div>
          </ul>
          <div class="sijn">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      </div>
      <div class="clear"></div>
      <ul class="bottom-bottom">
        <li
          :class="{ active: item.id == titleIndex }"
          @click="bindTitle(item.name, item.id)"
          v-for="item in lidata"
          :key="item.id"
        >
          {{ item.name }}
        </li>
      </ul>
    </div>
    <div class="videobox" v-show="titleFlagvideo">
      <div class="close" @click="(titleFlagvideo = false), player.pause()">
        <img src="@/assets/close.png" alt="" />
      </div>
      <div class="prism-player" id="J_prismPlayer"></div>
    </div>
  </div>
</template>

<script>
import {
  industry,
  industrygdp,
  ceshi2,
  industrymenu,
  project,
  getInfo,
  industryvideo,
  indexlunbo,
} from "@/api/index";
export default {
  name: "Thecockpit",
  data() {
    return {
      timeout: null,
      isvid: true,
      showbtnvideo: true,
      // isvid:true,
      // showbtnvideo:true,

      url: "",
      hov_index: -1,
      vioheight: "height:253px",
      gdpTitle: "",
      echdata1: [
        "高端装备:30%",
        "邮件营销",
        "联盟广告",
        "视频广告",
        "搜索引擎",
        "dd",
      ],
      lidata: [
        { id: 1, name: "太原市" },
        {
          id: 6,
          name: "小店区",
          coords: [
            406,
            295,
            425,
            296,
            422,
            307,
            440,
            306,
            455,
            292,
            461,
            294,
            459,
            306,
            462,
            317,
            449,
            327,
            453,
            336,
            446,
            360,
            424,
            409,
            404,
            411,
            396,
            400,
            382,
            406,
            375,
            401,
            372,
            413,
            353,
            403,
            365,
            389,
            381,
            372,
            396,
            370,
            408,
            349,
            405,
            324,
          ],
        },
        {
          id: 5,
          name: "迎泽区",
          coords: [
            408,
            293,
            411,
            284,
            419,
            279,
            430,
            283,
            435,
            282,
            441,
            284,
            454,
            282,
            462,
            260,
            479,
            257,
            491,
            258,
            500,
            279,
            487,
            275,
            477,
            287,
            468,
            291,
            458,
            289,
            445,
            303,
            423,
            307,
            427,
            299,
            423,
            293,
          ],
        },
        {
          id: 4,
          name: "杏花岭区",
          coords: [
            436,
            233,
            432,
            237,
            436,
            245,
            425,
            249,
            423,
            277,
            431,
            284,
            436,
            281,
            452,
            280,
            458,
            270,
            464,
            257,
            496,
            257,
            499,
            250,
            485,
            238,
            476,
            243,
            455,
            234,
            447,
            232,
          ],
        },
        {
          id: 2,
          name: "尖草坪区",
          coords: [
            308,
            248,
            316,
            240,
            313,
            229,
            323,
            224,
            324,
            212,
            336,
            210,
            340,
            198,
            352,
            200,
            350,
            172,
            371,
            160,
            381,
            175,
            399,
            183,
            425,
            177,
            441,
            184,
            457,
            212,
            469,
            225,
            456,
            233,
            436,
            232,
            431,
            238,
            435,
            245,
            425,
            249,
            420,
            279,
            410,
            283,
            406,
            292,
            395,
            286,
            392,
            275,
            378,
            280,
            374,
            269,
            359,
            268,
            349,
            257,
            342,
            250,
            331,
            261,
          ],
        },
        {
          id: 3,
          name: "万柏林区",
          coords: [
            309,
            249,
            298,
            252,
            304,
            268,
            311,
            275,
            304,
            278,
            314,
            299,
            327,
            305,
            323,
            323,
            352,
            315,
            362,
            305,
            380,
            313,
            385,
            303,
            393,
            305,
            396,
            312,
            404,
            306,
            405,
            293,
            396,
            287,
            392,
            277,
            380,
            279,
            374,
            271,
            361,
            269,
            342,
            253,
            330,
            260,
          ],
        },
        {
          id: 7,
          name: "晋源区",
          coords: [
            324,
            322,
            354,
            314,
            362,
            304,
            372,
            309,
            379,
            310,
            386,
            314,
            387,
            302,
            394,
            306,
            398,
            314,
            404,
            308,
            404,
            325,
            407,
            351,
            396,
            370,
            382,
            372,
            363,
            390,
            346,
            407,
            340,
            401,
            337,
            379,
            325,
            370,
            322,
            361,
            323,
            348,
            329,
            339,
          ],
        },
        {
          id: 11,
          name: "古交市",
          coords: [
            213,
            128,
            229,
            130,
            243,
            139,
            271,
            140,
            277,
            156,
            287,
            171,
            286,
            176,
            292,
            188,
            302,
            189,
            317,
            213,
            322,
            224,
            313,
            227,
            311,
            241,
            298,
            250,
            306,
            273,
            312,
            297,
            324,
            303,
            321,
            317,
            293,
            361,
            276,
            367,
            259,
            375,
            226,
            360,
            207,
            356,
            181,
            356,
            154,
            318,
            124,
            318,
            102,
            297,
            93,
            278,
            112,
            267,
            166,
            270,
            179,
            267,
            212,
            246,
            205,
            234,
            213,
            223,
            197,
            153,
          ],
        },
        {
          id: 8,
          name: "清徐县",
          coords: [
            323,
            324,
            294,
            354,
            293,
            366,
            265,
            376,
            260,
            394,
            327,
            479,
            386,
            480,
            440,
            452,
            428,
            413,
            415,
            413,
            403,
            410,
            395,
            400,
            385,
            403,
            376,
            401,
            367,
            412,
            352,
            400,
            344,
            407,
            336,
            385,
            322,
            365,
            320,
            350,
            328,
            337,
          ],
        },
        {
          id: 10,
          name: "阳曲县",
          coords: [
            296,
            79,
            288,
            72,
            300,
            60,
            308,
            63,
            316,
            60,
            346,
            77,
            363,
            74,
            372,
            86,
            389,
            93,
            397,
            79,
            413,
            103,
            432,
            109,
            442,
            101,
            443,
            87,
            494,
            93,
            522,
            60,
            571,
            36,
            629,
            2,
            644,
            10,
            645,
            49,
            625,
            65,
            564,
            131,
            581,
            161,
            614,
            185,
            585,
            215,
            535,
            221,
            498,
            243,
            479,
            247,
            457,
            236,
            469,
            224,
            457,
            215,
            448,
            174,
            435,
            179,
            407,
            177,
            400,
            184,
            395,
            174,
            383,
            177,
            371,
            158,
            355,
            170,
            351,
            197,
            339,
            202,
            340,
            210,
            327,
            211,
            325,
            223,
            302,
            187,
            292,
            187,
            276,
            154,
            275,
            143,
            288,
            142,
            298,
            126,
            278,
            87,
          ],
        },
        {
          id: 9,
          name: "娄烦县",
          coords: [
            1,
            186,
            10,
            177,
            21,
            166,
            42,
            165,
            79,
            138,
            82,
            123,
            96,
            124,
            106,
            119,
            112,
            115,
            117,
            107,
            125,
            103,
            137,
            103,
            149,
            106,
            145,
            114,
            154,
            117,
            167,
            120,
            172,
            124,
            198,
            122,
            207,
            126,
            195,
            153,
            199,
            169,
            202,
            198,
            210,
            222,
            200,
            231,
            211,
            244,
            180,
            258,
            173,
            267,
            138,
            267,
            94,
            266,
            88,
            276,
            67,
            289,
            29,
            291,
            15,
            269,
          ],
        },
        { id: 12, name: "综改示范区" },
        { id: 13, name: "中北高新区" },
        { id: 15, name: "清徐开发区" },
        { id: 16, name: "阳曲示范区" },
        { id: 14, name: "西山示范区" },
      ],
      ListData: [],
      org_lidata: [
        { id: 1, name: "太原市" },
        {
          id: 6,
          name: "小店区",
          coords: [
            406,
            295,
            425,
            296,
            422,
            307,
            440,
            306,
            455,
            292,
            461,
            294,
            459,
            306,
            462,
            317,
            449,
            327,
            453,
            336,
            446,
            360,
            424,
            409,
            404,
            411,
            396,
            400,
            382,
            406,
            375,
            401,
            372,
            413,
            353,
            403,
            365,
            389,
            381,
            372,
            396,
            370,
            408,
            349,
            405,
            324,
          ],
        },
        {
          id: 5,
          name: "迎泽区",
          coords: [
            408,
            293,
            411,
            284,
            419,
            279,
            430,
            283,
            435,
            282,
            441,
            284,
            454,
            282,
            462,
            260,
            479,
            257,
            491,
            258,
            500,
            279,
            487,
            275,
            477,
            287,
            468,
            291,
            458,
            289,
            445,
            303,
            423,
            307,
            427,
            299,
            423,
            293,
          ],
        },
        {
          id: 4,
          name: "杏花岭区",
          coords: [
            436,
            233,
            432,
            237,
            436,
            245,
            425,
            249,
            423,
            277,
            431,
            284,
            436,
            281,
            452,
            280,
            458,
            270,
            464,
            257,
            496,
            257,
            499,
            250,
            485,
            238,
            476,
            243,
            455,
            234,
            447,
            232,
          ],
        },
        {
          id: 2,
          name: "尖草坪区",
          coords: [
            308,
            248,
            316,
            240,
            313,
            229,
            323,
            224,
            324,
            212,
            336,
            210,
            340,
            198,
            352,
            200,
            350,
            172,
            371,
            160,
            381,
            175,
            399,
            183,
            425,
            177,
            441,
            184,
            457,
            212,
            469,
            225,
            456,
            233,
            436,
            232,
            431,
            238,
            435,
            245,
            425,
            249,
            420,
            279,
            410,
            283,
            406,
            292,
            395,
            286,
            392,
            275,
            378,
            280,
            374,
            269,
            359,
            268,
            349,
            257,
            342,
            250,
            331,
            261,
          ],
        },
        {
          id: 3,
          name: "万柏林区",
          coords: [
            309,
            249,
            298,
            252,
            304,
            268,
            311,
            275,
            304,
            278,
            314,
            299,
            327,
            305,
            323,
            323,
            352,
            315,
            362,
            305,
            380,
            313,
            385,
            303,
            393,
            305,
            396,
            312,
            404,
            306,
            405,
            293,
            396,
            287,
            392,
            277,
            380,
            279,
            374,
            271,
            361,
            269,
            342,
            253,
            330,
            260,
          ],
        },
        {
          id: 7,
          name: "晋源区",
          coords: [
            324,
            322,
            354,
            314,
            362,
            304,
            372,
            309,
            379,
            310,
            386,
            314,
            387,
            302,
            394,
            306,
            398,
            314,
            404,
            308,
            404,
            325,
            407,
            351,
            396,
            370,
            382,
            372,
            363,
            390,
            346,
            407,
            340,
            401,
            337,
            379,
            325,
            370,
            322,
            361,
            323,
            348,
            329,
            339,
          ],
        },
        {
          id: 11,
          name: "古交市",
          coords: [
            213,
            128,
            229,
            130,
            243,
            139,
            271,
            140,
            277,
            156,
            287,
            171,
            286,
            176,
            292,
            188,
            302,
            189,
            317,
            213,
            322,
            224,
            313,
            227,
            311,
            241,
            298,
            250,
            306,
            273,
            312,
            297,
            324,
            303,
            321,
            317,
            293,
            361,
            276,
            367,
            259,
            375,
            226,
            360,
            207,
            356,
            181,
            356,
            154,
            318,
            124,
            318,
            102,
            297,
            93,
            278,
            112,
            267,
            166,
            270,
            179,
            267,
            212,
            246,
            205,
            234,
            213,
            223,
            197,
            153,
          ],
        },
        {
          id: 8,
          name: "清徐县",
          coords: [
            323,
            324,
            294,
            354,
            293,
            366,
            265,
            376,
            260,
            394,
            327,
            479,
            386,
            480,
            440,
            452,
            428,
            413,
            415,
            413,
            403,
            410,
            395,
            400,
            385,
            403,
            376,
            401,
            367,
            412,
            352,
            400,
            344,
            407,
            336,
            385,
            322,
            365,
            320,
            350,
            328,
            337,
          ],
        },
        {
          id: 10,
          name: "阳曲县",
          coords: [
            296,
            79,
            288,
            72,
            300,
            60,
            308,
            63,
            316,
            60,
            346,
            77,
            363,
            74,
            372,
            86,
            389,
            93,
            397,
            79,
            413,
            103,
            432,
            109,
            442,
            101,
            443,
            87,
            494,
            93,
            522,
            60,
            571,
            36,
            629,
            2,
            644,
            10,
            645,
            49,
            625,
            65,
            564,
            131,
            581,
            161,
            614,
            185,
            585,
            215,
            535,
            221,
            498,
            243,
            479,
            247,
            457,
            236,
            469,
            224,
            457,
            215,
            448,
            174,
            435,
            179,
            407,
            177,
            400,
            184,
            395,
            174,
            383,
            177,
            371,
            158,
            355,
            170,
            351,
            197,
            339,
            202,
            340,
            210,
            327,
            211,
            325,
            223,
            302,
            187,
            292,
            187,
            276,
            154,
            275,
            143,
            288,
            142,
            298,
            126,
            278,
            87,
          ],
        },
        {
          id: 9,
          name: "娄烦县",
          coords: [
            1,
            186,
            10,
            177,
            21,
            166,
            42,
            165,
            79,
            138,
            82,
            123,
            96,
            124,
            106,
            119,
            112,
            115,
            117,
            107,
            125,
            103,
            137,
            103,
            149,
            106,
            145,
            114,
            154,
            117,
            167,
            120,
            172,
            124,
            198,
            122,
            207,
            126,
            195,
            153,
            199,
            169,
            202,
            198,
            210,
            222,
            200,
            231,
            211,
            244,
            180,
            258,
            173,
            267,
            138,
            267,
            94,
            266,
            88,
            276,
            67,
            289,
            29,
            291,
            15,
            269,
          ],
        },
        { id: 12, name: "综改示范区" },
        { id: 13, name: "中北高新区" },
        { id: 15, name: "清徐开发区" },
        { id: 16, name: "阳曲示范区" },
        { id: 14, name: "西山示范区" },
      ],
      district: "太原市",
      policy: [],
      project: [],
      project_info: "",
      project_rightinfo: "",

      arr: [],
      gdpdata: [],
      gdpdatanum: [],
      citydata: [],
      tabledata: [
        { name: "", nums: "" },
        { name: "", nums: "" },
        { name: "", nums: "" },
        { name: "", nums: "" },
        { name: "", nums: "" },
        { name: "", nums: "" },
        { name: "", nums: "" },
        { name: "", nums: "" },
        { name: "", nums: "" },
        { name: "", nums: "" },
        { name: "", nums: "" },
        { name: "", nums: "" },
      ],
      img: [
        require("../../assets/cockpit/fang4.png"),
        require("../../assets/cockpit/fang8.png"),
        require("../../assets/cockpit/fang2.png"),
        require("../../assets/cockpit/fang6.png"),
        require("../../assets/cockpit/fang10.png"),
        require("../../assets/cockpit/fang14.png"),
        require("../../assets/cockpit/fang12.png"),
        require("../../assets/cockpit/fang15.png"),
        require("../../assets/cockpit/fang16.png"),
        require("../../assets/cockpit/fang5.png"),
        require("../../assets/cockpit/fang1.png"),
        require("../../assets/cockpit/fang9.png"),
        require("../../assets/cockpit/fang7.png"),
        require("../../assets/cockpit/fang13.png"),
        require("../../assets/cockpit/fang3.png"),
        require("../../assets/cockpit/fang11.png"),
      ],
      project_map: [],
      project_industry: [],
      titleName: "太原市",
      policy_province: "",
      getInfoData: "",
      titleFlag: false,
      titleFlagvideo: false,
      // titleFlag: true,
      titleIndex: 1,
      note: "",
      oneData: [
        // {
        //   color: "background-color:#72DCFE",
        //   value: "",
        //   name: "",
        // },
        // {
        //   color: "background-color:#0191FE",
        //   value: "",
        //   name: "",
        // },
        // {
        //   color: "background-color:#FCD469",
        //   value: "",
        //   name: "",
        // },
        // {
        //   color: "background-color:#FCB269",
        //   value: "",
        //   name: "",
        // },
        // {
        //   color: "background-color:#FC8569",
        //   value: "",
        //   name: "",
        // },
        // {
        //   color: "background-color:#FE6A00",
        //   value: "",
        //   name: "",
        // }
      ],
      colors: [
        "background-color:#72DCFE",
        "background-color:#0191FE",
        "background-color:#FCD469",
        "background-color:#FCB269",
        "background-color:#FC8569",
        "background-color:#FE6A00",

        "background-color:#72DCFE",
        "background-color:#0191FE",
        "background-color:#FCD469",
        "background-color:#FCB269",
        "background-color:#FC8569",
        "background-color:#FE6A00",
        "background-color:#72DCFE",
        "background-color:#0191FE",
        "background-color:#FCD469",
        "background-color:#FCB269",
        "background-color:#FC8569",
        "background-color:#FE6A00",
        "background-color:#72DCFE",
        "background-color:#0191FE",
        "background-color:#FCD469",
        "background-color:#FCB269",
        "background-color:#FC8569",
        "background-color:#FE6A00",
      ],
      player: null,
      tu2: 1,
      is_lock: false,
      lunbodata: [{}, {}],
      indexcurrent: 0,
      carousindex: 0,
    };
  },
  computed: {
    classOption() {
      return {
        singleHeight: 57,
        waitTime: 1000,
        step: 0.5,
        limitMoveNum: 5,
        hoverStop: false,
      };
    },
  },
  created() {
    this.getInfo();
    this.industryvideo();
    this.getlunbo();
    this.getData();
    this.ceshi();
    this.getindustrygdp();
    this.getindustrymenu();
    this.getProject();
    this.economic();
  },

  mounted() {
    this.picture();
    this.distribution();
    this.resize();
    window.onresize = () => {
      let that = this;
      if (this.is_lock) {
        return;
      }
      this.is_lock = true;

      setTimeout(function() {
        that.is_lock = false;
        that.resize();
      }, 800);
    };
    // this.play()
  },
  methods: {
    async getlunbo() {
      var data = {
        district: this.district,
      };
      var res = await indexlunbo(data);
      // console.log(res);
      // this.indexcurrent=0
      this.lunbodata = res;
    },
    zhu() {
      // console.log(88888888888888);
      // this.indexcurrent=0
      // this.lunbodata= []
      this.indexcurrent = 0;

      // console.log(this.lunbodata);
    },
    quexian() {
      this.$message("暂无权限");
    },
    jumpnewwww() {
      // console.log(9999);
      this.$router.push({ name: "alifuneng" });
      window.open("http://www.expocentralchina.org.cn/", "_blank");
      // window.open('http://gs.sxjx.wang/chuangke/',"_blank");
    },
    showvideo() {
      // console.log();
      this.isvid = !this.isvid;
      if (!this.isvid) {
        this.vioheight = "height:577px;";
      } else {
        this.vioheight = "height:253px;;";
      }
    },
    resize() {
      let this_width = this.$refs.image_map.clientWidth;
      let org_width = 657;
      let org_lidata_coords = this.org_lidata;
      let new_lidata = this.lidata;
      for (let i = 0; i < new_lidata.length; i++) {
        if (new_lidata[i].coords) {
          for (let ii = 0; ii < new_lidata[i].coords.length; ii++) {
            new_lidata[i].coords[ii] = (
              (this_width / org_width) *
              org_lidata_coords[i].coords[ii]
            ).toFixed(8);
          }
        }
      }
      let newData = [];
      new_lidata.map((item) => {
        if (item.id > 1 && item.id < 12) {
          newData.push(item);
        }
      });
      this.lidata = new_lidata;
      this.ListData = newData;
      this.$forceUpdate();
    },

    //获取MAP中元素属性
    adjust() {
      var map = document.getElementById("image-map");
      var element = map.childNodes;
      var itemNumber = element.length / 2;
      for (var i = 0; i < itemNumber - 1; i++) {
        var item = 2 * i + 1;
        var oldCoords = element[item].coords;
        var newcoords = adjustPosition(oldCoords);
        element[item].setAttribute("coords", newcoords);
      }
      var test = element;
    },
    //调整MAP中坐标
    adjustPosition(position) {
      var pageWidth = document.body.clientWidth; //获取页面宽度
      var pageHeith = document.body.clientHeight; //获取页面高度

      var imageWidth = 690; //图片的长宽
      var imageHeigth = 430;

      var each = position.split(",");
      //获取每个坐标点
      for (var i = 0; i < each.length; i++) {
        each[i] = Math.round(
          (parseInt(each[i]) * pageWidth) / imageWidth
        ).toString(); //x坐标
        i++;
        each[i] = Math.round(
          (parseInt(each[i]) * pageHeith) / imageHeigth
        ).toString(); //y坐标
      }
      //生成新的坐标点
      var newPosition = "";
      for (var i = 0; i < each.length; i++) {
        newPosition += each[i];
        if (i < each.length - 1) {
          newPosition += ",";
        }
      }
      return newPosition;
    },
    async industryvideo() {
      const res = await industryvideo({
        district: this.district,
      });
      this.url = res.url;
      // console.log(window.Aliplayer !== undefined);
      if (this.tu2 == 2) {
        // this.player.dispose();
      }
      this.play();
      // console.log(res, "123");
    },
    play() {
      // if (this.titleFlag) {
      //   player.dispose();
      // }
      this.tu2 = 2;
      // console.log(window.Aliplayer !== undefined);
      // 初始化播放器
      // this.player = new Aliplayer({
      //   id: "J_prismPlayer", // 容器id
      //   // source:"http://cloud.video.taobao.com/play/u/2554695624/p/1/e/6/t/1/fv/102/28552077.mp4", //视频地址
      //   source: this.url, //视频地址
      //   // cover: "http://cdn.img.mtedu.com/images/assignment/day_3.jpg", //播放器封面图
      //   width: "500px",
      //   height: "500px",
      //   autoplay: true,
      //   isLive: false,
      //   rePlay: false,
      //   playsinline: true,
      //   preload: true,
      //   controlBarVisibility: "hover",
      //   useH5Prism: true,
      //   skinLayout: [
      //     {
      //       name: "bigPlayButton",
      //       align: "blabs",
      //       x: 30,
      //       y: 80,
      //     },
      //     {
      //       name: "H5Loading",
      //       align: "cc",
      //     },
      //     {
      //       name: "errorDisplay",
      //       align: "tlabs",
      //       x: 0,
      //       y: 0,
      //     },
      //     {
      //       name: "infoDisplay",
      //     },
      //     {
      //       name: "tooltip",
      //       align: "blabs",
      //       x: 0,
      //       y: 56,
      //     },
      //     {
      //       name: "thumbnail",
      //     },
      //     {
      //       name: "controlBar",
      //       align: "blabs",
      //       x: 0,
      //       y: 0,
      //       children: [
      //         {
      //           name: "progress",
      //           align: "blabs",
      //           x: 0,
      //           y: 44,
      //         },
      //         {
      //           name: "playButton",
      //           align: "tl",
      //           x: 15,
      //           y: 12,
      //         },
      //         {
      //           name: "timeDisplay",
      //           align: "tl",
      //           x: 10,
      //           y: 7,
      //         },
      //         {
      //           name: "fullScreenButton",
      //           align: "tr",
      //           x: 10,
      //           y: 12,
      //         },
      //       ],
      //     },
      //   ],
      // });
    },
    comp_detail(e) {
      // console.log(e.target.dataset);
      var { classify, type } = e.target.dataset;
      // console.log(classify, type);
      var district = this.district;
      // let href = this.$router.resolve({
      //   // name: "chdetsils9",
      //   path: "/comp/comp",
      //   query: {
      //     classify,
      //     type,
      //     district,

      //     // tagnum: 5,
      //   },
      // });
      // window.open(href.href, "_blank");
      this.$router.push({
        // name: "chdetsils9",
        path: "/comp/comp",
        query: {
          classify,
          type,
          district,

          // tagnum: 5,
        },
      });
    },
    exitlogin() {
      localStorage.setItem("login", "");
      this.$router.replace({ path: "/" });
    },
    async getData() {
      try {
        const res = await industry({
          district: this.district,
        });
        this.policy = res.policy;
        this.policy_province = res.policy_province;
        this.project = res.project;
      } catch (err) {
        // this.$toast.success(err)
      }
    },
    async ceshi() {
      try {
        const res = await ceshi();
      } catch (err) {
        // this.$toast.success(err)
      }
    },
    async getindustrygdp() {
      try {
        const res = await industrygdp();
        this.gdpTitle = res.remind;
        for (var i = 0; i < res.gdp.length; i++) {
          let keys = Object.keys(res.gdp[i]);
          this.gdpdata.push(10 - i + "、" + keys[0]);
          this.gdpdatanum.push(res.gdp[i][keys[0]]);
          // console.log(res[i][keys[0]]);
        }
        // console.log(this.gdpdata);
        this.economic();
      } catch (err) {
        // this.$toast.success(err)
      }
    },
    async getindustrymenu() {
      try {
        const res = await industrymenu({
          district: this.district,
        });
        this.note = res.note;
        for (var i = 0; i < res.table_data.length; i++) {
          this.tabledata[i].name = res.table_data[i].name;
          this.tabledata[i].nums = res.table_data[i].nums;
        }
        // console.log(88888333333333333);
        this.oneData = [];
        for (var i = 0; i < res.industry.length; i++) {
          // console.log(res.industry.length);
          // console.log(res.industry[i].name);
          // console.log(res.industry[i].per);
          // console.log(res.industry[i].count);
          // console.log(i);
          // console.log(this.oneData);
          var obj = {
            // color: "background-color:#72DCFE",
            value: "",
            name: "",
          };
          obj.color = this.colors[i];
          obj.name = res.industry[i].name;
          obj.value = res.industry[i].per;
          obj.count = res.industry[i].count;
          this.oneData.push(obj);
          // this.oneData[i].name = res.industry[i].name;
          // this.oneData[i].value = res.industry[i].per;
          // this.oneData[i].count = res.industry[i].count;
        }

        // console.log(this.oneData, "bbb");
        this.radarmap();
        this.citydata = res.city_data;
      } catch (err) {}
    },
    async getProject() {
      try {
        const res = await project({
          district: this.district,
        });
        // console.log(res, "aaa");
        this.project_info = res.project_info;
        this.project_rightinfo = res.project_rightinfo;
        let data4 = [
          [
            "product",
            "签约项目投资额完成率",
            "开工项目计划投资额完成率",
            "开工项目到位金额完成率",
          ],
        ];
        var arr = [];
        res.project_map.map((item, index) => {
          let array = [];
          var ao = [];
          for (var key in item) {
            if (key == "name") array[0] = item[key];
            if (key == "add1") array[1] = item[key];
            if (key == "add2") array[2] = item[key];
            if (key == "add3") array[3] = item[key];
            if (key == "nums1") ao[0] = "签约项目投资额完成额： " + item[key];
            if (key == "nums2")
              ao[1] = "开工项目计划投资额完成额： " + item[key];
            if (key == "nums3") ao[2] = "开工项目到位金额完成额： " + item[key];
          }
          data4[index + 1] = array;
          arr[index] = ao;
        });
        // console.log(arr);
        this.arr = arr;
        this.project_map = data4;
        this.picture();
        let data3 = [];
        res.project_industry.map((item, index) => {
          data3.push({
            value: item.money,
            name: item.name,
          });
        });
        this.project_industry = data3;
        // console.log(this.project_industry);
        this.distribution();
      } catch (err) {}
    },
    async getInfo() {
      try {
        const res = await getInfo({
          district: this.district,
        });
        // console.log(res, 77787878);
        this.getInfoData = res;
      } catch (err) {}
    },
    bindTitle(name, index) {
      // console.log(index);
      this.titleFlagvideo = false;
      this.titleIndex = index;
      if (name !== "太原市") {
        this.district = name;
        this.titleFlag = true;
        this.isvid = true;
        this.showbtnvideo = true;

        // alert(this.district)
      } else {
        this.isvid = true;
        this.showbtnvideo = false;
        // console.log(this.isvid, 88888);
        this.district = name;
        this.titleFlag = false;
        this.economic();
      }
      this.getlunbo();
      this.getInfo();
      this.getData();
      this.getindustrymenu();
      this.industryvideo();
    },
    bindList(id) {
      // let href = this.$router.resolve({
      //   path: "/industrial/chdetsils2",
      //   query: { id: id },
      // });
      // window.open(href.href, "_blank");
      this.$router.push({
        path: "/industrial/chdetsils2",
        query: { id: id },
      });
    },
    bindList1(id) {
      // let href = this.$router.resolve({
      //   path: "/industrial/zhecedetails",
      //   query: { id: id, dis: "0" },
      // });
      // window.open(href.href, "_blank");
      this.$router.push({
        path: "/industrial/zhecedetails",
        query: { id: id, dis: "0" },
      });
    },
    // 分布统计
    radarmap() {
      // console.log(this.oneData, 888);
      let myChart = this.$echarts.init(document.getElementById("two"));
      var colorList = [
        "#72DCFE",
        "#0191FE",
        "#FCD469",
        "#FCB269",
        "#FC8569",
        "#FE6A00",
        "#72DCFE",
        "#0191FE",
        "#FCD469",
        "#FCB269",
        "#FC8569",
        "#FE6A00",
        "#72DCFE",
        "#0191FE",
        "#FCD469",
        "#FCB269",
        "#FC8569",
        "#FE6A00",
        "#72DCFE",
        "#0191FE",
        "#FCD469",
        "#FCB269",
        "#FC8569",
        "#FE6A00",
        "#72DCFE",
        "#0191FE",
        "#FCD469",
        "#FCB269",
        "#FC8569",
        "#FE6A00",
        "rgb(0,104,210)",
      ];
      // console.log(this.oneData);
      let option = {
        title: {
          text: "",
          x: "center",
          y: "center",
          textStyle: {
            fontSize: 14,
            color: "rgba(51, 51, 51, 1)",
            fontWeight: "400",
          },
        },
        tooltip: {
          trigger: "item",
          // formatter: "{b} :{d}%{c}",
          formatter: function(e) {
            return e.data.name + ":" + e.data.count;
          },
        },
        series: [
          {
            type: "pie",
            center: ["50%", "50%"],
            radius: ["38%", "80%"],
            clockwise: true,
            avoidLabelOverlap: true,
            hoverOffset: 15,
            itemStyle: {
              normal: {
                color: function(params) {
                  return colorList[params.dataIndex];
                },
              },
            },
            label: {
              show: false,
              position: "inside",
              formatter: "{d}%",
            },

            labelLine: {
              normal: {
                length: 20,
                length2: 30,
                lineStyle: {
                  width: 1,
                },
              },
            },
            data: this.oneData,
          },
        ],
      };
      myChart.setOption(option);
    },
    // 上面地图
    taiyMap() {},

    // //分布统计
    // structure() {
    //   // 用Dom获取上面已经创建的标签，初始化echarts实例
    //   let myChart = this.$echarts.init(document.getElementById("main"));
    //   // 指定图表的配置项和数据
    //   let option = {
    //     title: {
    //       text: "",
    //       left: 32,
    //       top: 19,
    //       textStyle: {
    //         fontWeight: "normal", //标题颜色
    //         color: "#FE6A00",
    //       },
    //     },
    //     legend: {
    //       type: "scroll",
    //       orient: "vertical",
    //       right: 60,
    //       top: 82,
    //       itemGap: 27,
    //       // data: this.echdata1,
    //     },

    //     series: [
    //       {
    //         type: "pie",
    //         radius: "55%",
    //         center: ["30%", "50%"],
    //         avoidLabelOverlap: false,
    //         label: {
    //           show: false,
    //           position: "left",
    //         },
    //         data: [
    //           // { value: 335, name: "高端装备:30%" },
    //           // { value: 310, name: "邮件营销" },
    //           // { value: 234, name: "联盟广告" },
    //           // { value: 135, name: "视频广告" },
    //           // { value: 1548, name: "搜索引擎" },
    //           // { value: 1548, name: "dd" },
    //         ],
    //       },
    //     ],
    //   };
    //   // 使用刚指定的配置项和数据显示图表的。
    //   myChart.setOption(option);
    // },
    // 右上柱状图
    economic() {
      return false;
      // console.log(this.gdpdata);
      // 用Dom获取上面已经创建的标签，初始化echarts实例
      let myChart = this.$echarts.init(document.getElementById("main2"));
      // 指定图表的配置项和数据
      let option = {
        title: {
          text: "",
          left: 32,
          top: 19,
          textStyle: {
            fontWeight: "normal", //标题颜色
            color: "#FE6A00",
          },
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        grid: {
          left: "-11%",
          top: 30,
          right: "6%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: {
          axisLine: {
            //这是x轴文字颜色

            show: false,
            lineStyle: {
              color: "#fff",
            },
          },
          axisLabel: {
            show: true,
            textStyle: {
              color: "#ffffff",
            },
            rotate: 40,
            margin: 10,
            align: "center",
            // padding:[10, 50, 0, 0],
            formatter: function(value) {
              return value;
            },
          },
        },

        yAxis: {
          type: "category",
          data: this.gdpdata,

          axisLine: {
            lineStyle: {
              color: "#2260DC",
              width: 1,
            },
          },
          // nameTextStyle: {
          //   align:"center",
          //   width:"50"
          // },
          axisLabel: {
            show: true,
            // align:"left",
            margin: 80,
            textStyle: {
              color: "#ffffff",
              align: "left",
              baseline: "middle",
            },
          },
        },

        series: [
          {
            type: "bar",
            data: this.gdpdatanum,
            barWidth: 10, //柱图宽度
            itemStyle: {
              normal: {
                //每个柱子的颜色即为colorList数组里的每一项，如果柱子数目多于colorList的长度，则柱子颜色循环使用该数组中的颜色
                color: function(params) {
                  var colorList = [
                    "rgb(30,164,250)",
                    "rgb(30,164,250)",
                    "rgb(30,164,250)",
                    "rgb(30,164,250)",
                    "rgb(30,164,250)",
                    "rgb(30,164,250)",
                    "rgb(30,164,250)",
                    "rgb(255,193,46)",
                    "rgb(255,193,46)",
                    "rgb(255,193,46)",
                  ];
                  return colorList[params.dataIndex];
                },
                barBorderRadius: [10, 10, 10, 10],
              },
            },
          },
        ],
      };
      // 使用刚指定的配置项和数据显示图表的。
      myChart.setOption(option);
    },

    // 中间柱状图
    picture() {
      // 用Dom获取上面已经创建的标签，初始化echarts实例
      let myChart = this.$echarts.init(document.getElementById("main4"));
      // 指定图表的配置项和数据
      let option = {
        color: ["#FBC609", "#87D370", "#2260DC"],
        // dataZoom: [
        //   {
        //     type: "slider", //有单独的滑动条，用户在滑动条上进行缩放或漫游。inside是直接可以是在内部拖动显示
        //     show: true, //是否显示 组件。如果设置为 false，不会显示，但是数据过滤的功能还存在。
        //     start: 100, //数据窗口范围的起始百分比0-100
        //     end: 0, //数据窗口范围的结束百分比0-100
        //     // xAxisIndex: [0], // 此处表示控制第一个xAxis，设置 dataZoom-slider 组件控制的 x轴 可是已数组[0,2]表示控制第一，三个；xAxisIndex: 2 ，表示控制第二个。yAxisIndex属性同理
        //     bottom: 30, //距离底部的距离
        //     height: 10,
        //      handleSize:'100%'
        //   },
        //   {
        //     type: "inside",
        //     // realtime: true,
        //     start: 0,
        //     end: 50,
        //   },
        // ],
        title: {
          // text: "太原市",
          left: 32,
          textStyle: {
            fontWeight: "normal", //标题颜色
            color: "#fff",
          },
        },
        legend: {
          type: "scroll",
          bottom: 0,
          left: 306,
          itemGap: 27,
          textStyle: {
            color: "#fff",
          },
        },

        tooltip: {
          // trigger: 'item',

          // axisPointer: { // 坐标轴指示器，坐标轴触发有效
          //     type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
          // }
          // trigger: 'axis',
          formatter: (e) => {
            var { dataIndex, componentIndex } = e;
            var str =
              e.name +
              "</br>" +
              e.seriesName +
              "： " +
              e.value[e.componentIndex + 1] +
              "%</br>" +
              this.arr[dataIndex][componentIndex];
            return str;
          },
        },
        dataset: {
          source: this.project_map,
        },
        xAxis: {
          type: "category",
          minInterval: 7,
          interval: 500, //每次增加几个
          axisLine: {
            lineStyle: {
              color: "#2260DC",
              width: 1,
            },
          },
          axisLabel: {
            show: true,
            textStyle: {
              color: "#ffffff",
              fontSize: 9,
            },
            interval: 0,
            rotate: 20,
            padding: [10, 0, 0, 0],
            formatter: function(value) {
              // if (value.length < 5) {
              var str = " " + value;
              // } else {
              //   var str =
              //     value.substr(0, 5) +
              //     "\n" +
              //     value.substr(5, 4) +
              //     "\n" +
              //     value.substr(8);
              // }

              return str;
            },
          },
        },
        yAxis: {
          type: "value",
          minInterval: 12,
          name: "%",
          // color:'#fff',/
          nameTextStyle: {
            color: "#fff",
          },
          // interval: 100, //每次增加几个
          axisLine: {
            lineStyle: {
              color: "#2260DC",
              width: 1,
            },
          },
          axisLabel: {
            show: true,

            textStyle: {
              color: "#ffffff",
            },
          },
        },
        series: [
          {
            type: "bar",
            barWidth: 10, //柱图宽度
            itemStyle: {
              normal: {
                //柱形图圆角，初始化效果
                barBorderRadius: [10, 10, 10, 10],
              },
              tooltip: {
                formatter: "{a1}:",
              },
            },
            markLine: {
              silent: true,
              symbol: "none",
              lineStyle: {
                type: "solid",
                width: 2,
              },
              data: [
                // {
                //   yAxis: 100,
                //   itemStyle: {
                //     color: "#fff",
                //   },
                // },
              ],
            },
          },
          {
            type: "bar",
            barWidth: 10, //柱图宽度
            itemStyle: {
              normal: {
                //柱形图圆角，初始化效果
                barBorderRadius: [10, 10, 10, 10],
              },
            },
          },
          {
            type: "bar",
            barWidth: 10, //柱图宽度
            itemStyle: {
              normal: {
                //柱形图圆角，初始化效果
                barBorderRadius: [10, 10, 10, 10],
              },
            },
          },
        ],
      };
      // 使用刚指定的配置项和数据显示图表的。
      myChart.setOption(option);
    },
    // 中间饼状图
    distribution() {
      // 用Dom获取上面已经创建的标签，初始化echarts实例
      let myChart = this.$echarts.init(document.getElementById("main3"));
      var that = this;
      // 用Dom获取上面已经创建的标签，初始化echarts实例
      // let myChart = this.$echarts.init(document.getElementById("main3"));
      myChart.on("mouseover", function(params) {
        // 控制台打印数据的名称
        that.project_industry.forEach((e, index) => {
          if (params.name == e.name) {
            // console.log(params.name);
            // console.log(e.name);
            that.hov_index = index;
            // console.log(e.name);
            return false;
          }
        });
      });
      myChart.on("mouseout", function(params) {
        that.hov_index = -1;
      });
      // 指定图表的配置项和数据
      let option = {
        color: [
          "rgb(26,186,116)",
          "rgb(137,195,63)",
          "rgb(59,211,145)",
          "rgb(93,219,164)",
          "rgb(147,220,188)",
          "rgb(238,172,14)",
          "rgb(255,188,29)",
          "rgb(250,206,101)",
          "rgb(254,222,153)",
          "rgb(8,122,180)",
          "rgb(14,140,206)",
          "rgb(15,151,223)",
          "rgb(19,162,236)",
          "rgb(64,178,255)",
          "rgb(82,196,255)",
          "rgb(120,209,255)",
        ],
        title: {
          text: "",
          left: 32,
          top: 19,
          textStyle: {
            fontWeight: "normal", //标题颜色
            color: "#FE6A00",
            //  color: "#FE6A00",
          },
        },
        tooltip: {
          trigger: "item",
          formatter: "{b} : {c} ({d}%)",
        },
        series: [
          {
            name: "",
            type: "pie",
            radius: ["25%", "55%"],
            center: ["50%", "50%"],
            data: this.project_industry,
            labelLine: {
              normal: {
                lineStyle: {
                  color: "#0B0BB2",
                },
                smooth: 0.2,
                length: 20,
                length2: 0,
              },
            },

            label: {
              normal: {
                formatter: "{a|{b}}",
                rich: {
                  a: {
                    color: "#fff",
                    fontSize: 11,
                    lineHeight: 20,
                    align: "center",
                  },
                },
              },
            },
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
          },
        ],
      };

      // 使用刚指定的配置项和数据显示图表的。
      myChart.setOption(option);
    },
  },
};
</script>

<style lang="less" scoped>
@import "../../style/cockpit";

video::-internal-media-controls-download-button {
  display: none;
}
video::-webkit-media-controls-enclosure {
  overflow: hidden;
}
video::-webkit-media-controls-panel {
  width: calc(100% + 30px);
}
.Thecockpit {
  .seamless-warp {
    width: 100%;
    height: 280px;
    overflow: auto;
    li {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      cursor: pointer;
    }
    .item li {
      height: 50px;
      display: flex;
      span {
        flex: 1;
        text-align: center;
        line-height: 50px;
        color: #fff;
      }
    }
    &::-webkit-scrollbar {
      width: 4px;
      height: 4px;
    }
    &::-webkit-scrollbar-track {
      background: #02044a;
      border-radius: 2px;
    }
    &::-webkit-scrollbar-thumb {
      background: #aaa;
      border-radius: 2px;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: #747474;
    }
    &::-webkit-scrollbar-corner {
      background: #02044a;
    }
  }
  li {
    list-style-type: none;
  }

  .head {
    position: relative;
    width: 100%;
    height: 87px;
    background: #02044a;

    .bnti {
      float: left;
      margin: 25px 0 0 29px;
      font-size: 24px;
      color: #aed7fe;
    }

    .centers {
      height: 48px;
      width: 62%;
      display: flex;
      justify-content: center;
      padding-top: 17px;
      margin-left: 1%;
      float: left;

      li {
        display: block;
        height: 48px;
        float: left;
        cursor: pointer;
        margin-right: 2.6%;
        &.hover {
          cursor: default;
          span {
            color: #fff;
          }
        }
        img {
          width: 20px;
          height: 20px;
          display: block;
          margin: 3px auto;
        }

        span {
          display: block;
          color: #aed7fe;
          font-size: 17px;
          text-align: center;
        }
      }

      span {
        color: #fff;
      }
    }

    .right {
      position: absolute;
      top: 0;
      right: 0;

      float: right;
      margin-top: 25px;
      position: absolute;
      right: 0;
      top: 0;

      span {
        line-height: 42px;
        font-size: 16px;
        font-weight: 400;
        color: #ffffff;
        padding-right: 20px;
        cursor: pointer;
        img {
          vertical-align: middle;
          margin-right: 8px;
        }
      }
    }
  }

  .img-top {
    background: #02044a url(../../assets/cockpit/kr.png) no-repeat center;
    background-size: 100%;
    height: 92px;
    font-size: 34px;
    line-height: 58px;
    color: #ffffff;
    text-align: center;
  }

  .zzon {
    padding-top: 13px;
    width: 100%;
    min-height: 100%;
    padding-bottom: 10px;
    background: #02044a;

    .center-top {
      overflow: hidden;
      zoom: 1;
      margin-bottom: 18px;

      .c-left {
        width: 24%;
        height: 686px;
        float: left;
        // margin: 0 22px 0 20px;
        margin: 0 1.5% 0 20px;
        .nc-box {
          overflow: hidden;
          ul {
            margin: 4%;
            border: 1px solid #0c166b;
          }
          li {
            list-style-type: none;
            display: block;
            height: 38px;
            width: 100%;
            color: #fff;
            span {
              line-height: 38px;
              height: 38px;
              overflow: hidden;
              display: block;
              width: 50%;
              float: left;
              cursor: pointer;
            }
          }
          .zhongdain {
            background: #1d2f69;
          }
          li:nth-child(odd) {
            background: #1d2f69;
          }
        }
        .top {
          position: relative;
          width: 100%;
          height: 308px;
          margin-bottom: 15px;
          border: 1px solid #0c166b;

          img.jiao1 {
            position: absolute;
            left: 0;
            top: 0;
          }
          img.jiao2 {
            position: absolute;
            right: 0;
            top: 0;
          }
          img.jiao3 {
            position: absolute;
            left: 0;
            bottom: 0;
          }
          img.jiao4 {
            position: absolute;
            right: 0;
            bottom: 0;
          }
        }

        .bottom {
          position: relative;
          width: 100%;
          height: 361px;
          border: 1px solid #0c166b;

          img.jiao1 {
            position: absolute;
            left: 0;
            top: 0;
          }
          img.jiao2 {
            position: absolute;
            right: 0;
            top: 0;
          }
          img.jiao3 {
            position: absolute;
            left: 0;
            bottom: 0;
          }
          img.jiao4 {
            position: absolute;
            right: 0;
            bottom: 0;
          }
        }
      }

      .c-center {
        position: relative;
        width: 40%;
        height: 686px;
        border: 1px solid #0c166b;
        float: left;

        img.jiao1 {
          position: absolute;
          left: 0;
          top: 0;
        }
        img.jiao2 {
          position: absolute;
          right: 0;
          top: 0;
        }
        img.jiao3 {
          position: absolute;
          left: 0;
          bottom: 0;
        }
        img.jiao4 {
          position: absolute;
          right: 0;
          bottom: 0;
        }

        .bnnti {
          // width: 716px;
          // height: 114px;
          background: #0e2674;
          padding-top: 10px;
          margin: 0 auto;
          margin-top: 24px;
          display: flex;
          flex-wrap: wrap;
          li {
            //line-height: 50px;
            display: block;
            // width: 120px;
            // height: 30px;
            // float: left;
            color: #fff;
            width: 33.33%;
            // margin-left: 40px;

            span {
              display: block;
              width: 170px;
              height: 20px;
              font-size: 16px;
              font-weight: 700;
              // float: left;
              text-align: center;
            }
            span:nth-child(2) {
              font-size: 12px;
              font-weight: 400;
            }
          }
        }
      }

      .c-right {
        position: relative;
        width: 30%;
        height: 686px;
        border: 1px solid #0c166b;
        float: left;
        margin: 0 14px 0 15px;
        padding-top: 6px;
        padding-left: 15px;

        img.jiao1 {
          position: absolute;
          left: 0;
          top: 0;
        }
        img.jiao2 {
          position: absolute;
          right: 0;
          top: 0;
        }
        img.jiao3 {
          position: absolute;
          left: 0;
          bottom: 0;
        }
        img.jiao4 {
          position: absolute;
          right: 0;
          bottom: 0;
        }

        h1 {
          background: url(../../assets/cockpit/biaotixian.png) no-repeat left
            bottom;
          font-weight: normal;
          height: 46px;
          line-height: 46px;
          span {
            background: url(../../assets/cockpit/yuandian.png) no-repeat left
              center;
            display: block;
            font-size: 14px;
            margin-left: 8px;
            color: #fe6a00;
            text-align: left;
            padding-left: 20px;
          }
        }
      }
    }

    .center-center {
      .c-left {
        position: relative;
        width: 65.5%;
        height: 598px;
        border: 1px solid #0c166b;
        float: left;
        margin: 0 16px 18px 20px;
        padding-top: 6px;
        padding-left: 15px;

        img.jiao1 {
          position: absolute;
          left: 0;
          top: 0;
        }
        img.jiao2 {
          position: absolute;
          right: 0;
          top: 0;
        }
        img.jiao3 {
          position: absolute;
          left: 0;
          bottom: 0;
        }
        img.jiao4 {
          position: absolute;
          right: 0;
          bottom: 0;
        }

        h1 {
          background: url(../../assets/cockpit/biaotixian.png) no-repeat left
            bottom;
          font-weight: normal;
          height: 46px;
          line-height: 46px;
          span {
            background: url(../../assets/cockpit/yuandian.png) no-repeat left
              center;
            display: block;
            font-size: 14px;
            margin-left: 8px;
            color: #fe6a00;
            text-align: left;
            padding-left: 20px;
          }
        }
      }

      .c-right {
        position: relative;
        width: 30%;
        height: 598px;
        border: 1px solid #0c166b;
        float: left;
        padding-top: 6px;
        padding-left: 15px;

        img.jiao1 {
          position: absolute;
          left: 0;
          top: 0;
        }
        img.jiao2 {
          position: absolute;
          right: 0;
          top: 0;
        }
        img.jiao3 {
          position: absolute;
          left: 0;
          bottom: 0;
        }
        img.jiao4 {
          position: absolute;
          right: 0;
          bottom: 0;
        }

        h1 {
          background: url(../../assets/cockpit/biaotixian.png) no-repeat left
            bottom;
          font-weight: normal;
          height: 46px;
          line-height: 46px;
          span {
            background: url(../../assets/cockpit/yuandian.png) no-repeat left
              18px;
            display: block;
            font-size: 14px;
            margin-left: 8px;
            color: #fe6a00;
            text-align: left;
            padding-left: 20px;
          }
        }

        .iiye {
          margin-left: 1%;

          li {
            font-size: 14px;
            font-weight: 400;
            // color: #0cb5fa;
            color: #fff;
            text-align: left;
            float: left;
            height: 24px;
            line-height: 24px;
            width: 50%;
            overflow: hidden;
            img {
              margin-right: 6px;
            }
          }
        }
      }
    }

    .center-bottom {
      .c-left {
        position: relative;
        width: 49%;
        height: 415px;
        border: 1px solid #0c166b;
        float: left;
        margin: 0 20px;
        padding-top: 6px;
        padding-left: 15px;
        img.jiao1 {
          position: absolute;
          left: 0;
          top: 0;
        }
        img.jiao2 {
          position: absolute;
          right: 0;
          top: 0;
        }
        img.jiao3 {
          position: absolute;
          left: 0;
          bottom: 0;
        }
        img.jiao4 {
          position: absolute;
          right: 0;
          bottom: 0;
        }

        h1 {
          background: url(../../assets/cockpit/biaotixian.png) no-repeat left
            bottom;
          font-weight: normal;
          height: 46px;
          line-height: 46px;
          span {
            background: url(../../assets/cockpit/yuandian.png) no-repeat left
              18px;
            display: block;
            font-size: 14px;
            margin-left: 8px;
            color: #fe6a00;
            text-align: left;
            padding-left: 20px;
          }
        }

        .nc-box {
          // background: #ccc;

          li {
            position: relative;
            list-style-type: none;
            display: block;
            height: 38px;
            color: #fff;
            margin-right: 15px;
            text-align: center;

            span {
              line-height: 38px;
              font-size: 14px;
            }

            span:nth-child(1) {
              font-size: 14px;
            }
          }

          li:nth-child(1) {
            // margin-top: 38px;

            img {
              width: 19px;
              height: 11px;
              position: absolute;
              top: 13px;
              right: 75px;
            }

            img:nth-child(2) {
              right: 31px;
            }
          }
          .zhongdain {
            background: #1d2f69;
          }
          li:nth-child(even) {
            background: #1d2f69;
          }
        }
      }
      .c-right {
        position: relative;
        width: 46.3%;
        height: 414px;
        border: 1px solid #0c166b;
        float: left;
        padding-top: 6px;
        padding-left: 15px;
        img.jiao1 {
          position: absolute;
          left: 0;
          top: 0;
        }
        img.jiao2 {
          position: absolute;
          right: 0;
          top: 0;
        }
        img.jiao3 {
          position: absolute;
          left: 0;
          bottom: 0;
        }
        img.jiao4 {
          position: absolute;
          right: 0;
          bottom: 0;
        }

        h1 {
          background: url(../../assets/cockpit/biaotixian.png) no-repeat left
            bottom;
          font-weight: normal;
          height: 46px;
          line-height: 46px;
          span {
            background: url(../../assets/cockpit/yuandian.png) no-repeat left
              18px;
            display: block;
            font-size: 14px;
            margin-left: 8px;
            color: #fe6a00;
            text-align: left;
            padding-left: 20px;
          }
        }

        .nc-box {
          // background: #ccc;
          li {
            position: relative;
            list-style-type: none;
            display: block;
            height: 38px;
            color: #fff;
            margin-right: 15px;
            text-align: center;

            span {
              line-height: 38px;
              font-size: 14px;
            }

            span:nth-child(1) {
              font-size: 14px;
            }
          }

          li:nth-child(1) {
            img {
              width: 19px;
              height: 11px;
              position: absolute;
              top: 13px;
              right: 75px;
            }

            img:nth-child(2) {
              right: 31px;
            }
          }
          div {
            li:nth-child(odd) {
              background: #1d2f69;
            }
          }
        }
      }
    }

    .bottom-bottom {
      height: 52px;
      margin: 20px 20px;
      margin-right: 50px;
      overflow: hidden;
      zoom: 1;
      display: flex;
      flex-wrap: nowrap;

      li {
        flex: 1;
        min-width: 73px;
        padding: 0 10px;
        height: 50px;
        line-height: 50px;
        background: url(../../assets/cockpit/weixuanzhong.png) no-repeat center;
        background-size: 100% 100%;
        float: left;
        display: block;
        font-size: 14px;
        font-weight: bold;
        color: #459cff;
        margin-right: 7px;
        cursor: pointer;
        &:hover {
          background: url(../../assets/cockpit/xuanzhong.png) no-repeat center;
          background-size: 100% 100%;
          color: #fff;
        }
        &.active {
          background: url(../../assets/cockpit/xuanzhong.png) no-repeat center;
          background-size: 100% 100%;
          color: #fff;
          cursor: default;
        }
      }

      span {
        width: 52px;
        height: 48px;
      }
    }
  }
  .clear {
    clear: both;
  }
  .sijn {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -100;

    div:nth-child(1) {
      position: absolute;
      width: 13px;
      height: 13px;
      border-top: 3px solid #2260dc;
      border-left: 3px solid #2260dc;
    }

    div:nth-child(2) {
      float: right;
      width: 13px;
      height: 13px;
      border-top: 3px solid #2260dc;
      border-right: 3px solid #2260dc;
    }

    div:nth-child(3) {
      position: absolute;
      bottom: 0;
      width: 13px;
      height: 13px;
      border-left: 3px solid #2260dc;
      border-bottom: 3px solid #2260dc;
    }

    div:nth-child(4) {
      position: absolute;
      bottom: 0;
      right: 0;
      width: 13px;
      height: 13px;
      border-bottom: 3px solid #2260dc;
      border-right: 3px solid #2260dc;
    }
  }
  .sixbox {
    width: 100%;
    height: 100%;
    margin-top: 6px;
    margin-left: 15px;
    position: relative;
    flex: 1;
    h1 {
      background: url(../../assets/cockpit/biaotixian.png) no-repeat left bottom;
      font-weight: normal;
      height: 46px;
      line-height: 46px;
      span {
        background: url(../../assets/cockpit/yuandian.png) no-repeat left center;
        display: block;
        font-size: 14px;
        margin-left: 8px;
        color: #fe6a00;
        text-align: left;
        padding-left: 20px;
      }
    }
    #two {
      margin-top: 20px;
      width: 220px;
      height: 220px;
    }
    .one-orinet {
      position: absolute;
      right: 50px;
      top: 0px !important;
      z-index: 99999;
      top: 0;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: auto;
      ul {
        li {
          display: flex;
          align-items: center;
          font-size: 14px;
          font-weight: 400;
          color: #fff;
          line-height: 24px;
          list-style: none;
          i {
            display: inline-block;
            width: 20px;
            height: 14px;
            background: #72dcfe;
            margin-right: 5px;
          }
        }
      }
    }
  }
}

// .light_main3 {
//   // color:#fe6a00;
// }
.infoData {
  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }
  &::-webkit-scrollbar-track {
    background: #02044a;
    border-radius: 2px;
  }
  &::-webkit-scrollbar-thumb {
    background: #aaa;
    border-radius: 2px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #747474;
  }
  &::-webkit-scrollbar-corner {
    background: #02044a;
  }
}
.videobox {
  position: fixed;
  width: 500px;
  height: 400px;
  background-color: #fff;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 99999;
}
.close {
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 9999;
  cursor: pointer;
}

@media (max-width: 1366px) {
  body {
    zoom: 0.8;
  }
  .Thecockpit .zzon .center-top .c-center .bnnti {
    width: 100% !important;
  }
  .Thecockpit .head .centers li span {
    font-size: 13px !important;
  }
  .Thecockpit .head .centers {
    margin-left: 1%;
  }
  .Thecockpit .zzon .center-top .c-right h1 span {
    font-size: 16px;
  }
  .Thecockpit .head .right span {
    font-size: 12px;
    padding-right: 6px;
  }
}
</style>

<template>
  <div class="login">
    <div class="wfbox">
      <img class="left-t" src="../../assets/login/zot.png" alt />

      <div v-if="ddlu1" class="right-log">
        <p>
          <span @click="vmhm(0)" style="color:#FE6A00">访客</span>
          <span @click="vmhm(1)">内部</span>
        </p>

        <div class="ipt1">
          <span class="yiba">+186</span>
          <input placeholder="手机号码" v-model="mobile" style="padding-left:80px;width:295px" />
        </div>
        <div class="ipt2">
          <span class="yzm" v-if="timeshow==0" @click="getyzm">获取验证码</span>
          <span class="yzm" v-if="timeshow==1" @click="getyzm">{{time}}秒后可发送</span>
          <span class="yzm" v-if="timeshow==2" @click="getyzm">重新发送</span>
          <input v-model="smsCode" placeholder="请输入验证码" />
        </div>
        <el-button class="btn" @click="gotologin">登录</el-button>
        <div class="wxfg">
          <img @click="gotoewm" src="../../assets/login/weixin.png" />
          <img src="../../assets/login/dingding.png" />
          <!-- window.open('',"_blank") -->
        </div>
      </div>
      <div v-if="ddlu4" class="right-log">
        <p>
          <span style="margin-left:165px">绑定手机号</span>
        </p>

        <div class="ipt1">
          <span class="yiba">+186</span>
          <input placeholder="手机号码" v-model="mobile" style="padding-left:80px;width:295px" />
        </div>
        <div class="ipt2">
          <span class="yzm" v-if="timeshow==0" @click="getyzm">获取验证码</span>
          <span class="yzm" v-if="timeshow==1" @click="getyzm">{{time}}秒后可发送</span>
          <span class="yzm" v-if="timeshow==2" @click="getyzm">重新发送</span>
          <input v-model="smsCode" placeholder="请输入验证码" />
        </div>
        <el-button class="btn" @click="gotologin">登录</el-button>
      </div>
      <div v-if="ddlu2" class="right-log">
        <p>
          <span @click="vmhm(0)">访客</span>
          <span style="color:#FE6A00" @click="vmhm(1)">内部</span>
        </p>

        <div class="ipt1">
          <input v-model="pwdmobile" placeholder="请输入手机号" />
        </div>
        <div class="ipt2">
          <input v-model="password" placeholder="请输入密码" />
        </div>
        <el-button class="btn" @click="passlogin">登录</el-button>
        <div class="wuzh">
          暂无账号 ?
          <span style="color:#FE6A00;" @click="$router.push({name:'registered'})">
            <strong>立即注册</strong>
          </span>
        </div>
      </div>
      <div v-if="ddlu3" class="right-log">
        <p>
          <span style="margin-left:165px">管理员登录</span>
        </p>

        <div class="ipt1">
          <input v-model="name" placeholder="请输入用户名" />
        </div>
        <div class="ipt2">
          <input v-model="passwordgld" placeholder="请输入密码" />
        </div>
        <el-button class="btn" @click="glylogin()">登录</el-button>
      </div>
    </div>

    <div class="cc0" @click="vmhm(2)">管理员登录</div>
    <!-- <img class="bjt" src="../../assets/login/log.png" alt=""> -->
  </div>
</template>

<script>
import { getyzm, gotologin, bypwdlogin, administratorlogin } from "@/api/index";
export default {
  name: "login",
  data() {
    return {
      ddlu1: true,
      ddlu2: false,
      ddlu3: false,
      ddlu4: false,

      time: "60",
      timeshow: 0,
      mobile: "",
      smsCode: "",
      pwdmobile: "",
      password: "",
      passwordgld: "",
      name: ""
    };
  },
  created() {
    localStorage.setItem("login", "");
  },
  methods: {
    vmhm(a) {
      if (a == 0) {
        (this.ddlu1 = true), (this.ddlu2 = false), (this.ddlu3 = false);
      }
      if (a == 1) {
        (this.ddlu1 = false), (this.ddlu2 = true), (this.ddlu3 = false);
      }
      if (a == 2) {
        (this.ddlu1 = false), (this.ddlu2 = false), (this.ddlu3 = true);
      }
    },
    // 获取验证码
    async getyzm() {
      this.time = 60;
      this.countdown();
      this.timeshow = 1;
      console.log(this.mobile);
      if (this.mobile == "") {
        this.$message({
          message: "请输入手机号",
          type: "error"
        });
      }
      const res = await getyzm({
        mobile: this.mobile
      });
      if (res.code == 4900) {
        this.$message.error(res.msg);
      } else if (res.code == 0) {
        this.$message({
          message: "发送成功",
          type: "success"
        });
        this.timeshow = 1;
      }
      // console.log(res);
    },
    // 验证码登录
    async gotologin() {
      if (this.smsCode == "") {
        this.$message({
          message: "请输入验证码",
          type: "error"
        });
      }
      const res = await gotologin({
        mobile: this.mobile,
        sms_code: this.smsCode
      });
      if (res.code == 4004) {
        this.$message.error(res.msg);
      } else if (res.code == 0) {
        this.$message({
          message: "登录成功",
          type: "success",
          duration: 1000,
          showClose: true,
        });
        localStorage.setItem("login", res.token);
        this.$router.push({ name: "Thecockpit" });
      }
    },
    gotoewm() {
      let encodeurl = encodeURI("http://localhost:8080/#/");
      window.open(
        "https://open.weixin.qq.com/connect/qrconnect?appid=wxb44559f2cbe1efcf&redirect_uri=" +
          encodeurl +
          "&response_type=code&scope=snsapi_login&state=STATE#wechat_redirect",
        "_blank"
      );
    },
    // 18230104816
    // 密码登录
    async passlogin() {
      if (this.pwdmobile == "") {
        this.$message({
          message: "请输入手机号",
          type: "error"
        });
        return false;
      }
      if (this.password == "") {
        this.$message({
          message: "请输入密码",
          type: "error"
        });
        return false;
      }
      const res = await bypwdlogin({
        mobile: this.pwdmobile,
        password: this.password
      });
      if (res.code == 4004) {
        this.$message.error(res.msg);
      } else if (res.code == 0) {
        this.$message({
          message: "登录成功",
          type: "success",
          duration: 1000,
          showClose: true,
        });
        localStorage.setItem("login", res.token);
        this.$router.push({ name: "Thecockpit" });
      } else {
        this.$message.error(res.msg);
      }
    },
    // 管理员
    async glylogin() {
      if (this.name == "") {
        this.$message({
          message: "请输入用户名",
          type: "error"
        });
        return false;
      }
      if (this.passwordgld == "") {
        this.$message({
          message: "请输入密码",
          type: "error"
        });
        return false;
      }
      const res = await administratorlogin({
        name: this.name,
        password: this.passwordgld
      });
      if (res.code == 4004) {
        this.$message.error(res.msg);
      } else if (res.code == 0) {
        this.$message({
          message: "登录成功",
          type: "success",
          duration: 1000,
          showClose: true,
        });
        localStorage.setItem("login", res.token);
        this.$router.push({ name: "Thecockpit" });
      } else {
        this.$message.error(res.msg);
      }
    },
    countdown() {
      var that = this;
      setInterval(function() {
        if (that.time > 0) {
          that.time--;
        } else {
          that.timeshow = 2;
        }
      }, 1000);
    }
  }
};
</script>

<style lang="less" scoped>
.login {
  position: relative;
  padding-top: 276px;
  width: 100%;
  height: calc(100% - 276px);
  background: url("../../assets/login/log.png") no-repeat;
  background-size: cover;
  .wfbox {
    margin: auto;
    width: 908px;
    height: 434px;
    background: #ffffff;
    border-radius: 10px;

    .left-t {
      width: 389px;
      height: 226px;
      float: left;
      margin: 104px 0 0 47px;
    }

    .right-log {
      float: right;
      width: 427px;
      height: 100%;

      p {
        margin-top: 51px;
        position: absolute;
        z-index: 100;

        span {
          float: left;
          font-size: 18px;
          color: #666666;
          font-weight: bold;
          line-height: 16px;
          margin-left: 121px;
          margin-bottom: 35px;
          cursor: pointer;
        }
      }

      .ipt1 {
        position: relative;
        margin-top: 104px;

        .yiba {
          position: absolute;
          top: 18px;
          left: 52px;
          font-size: 14px;
          font-weight: 400;
          color: #333333;
          z-index: 10;
        }
      }

      .ipt2 {
        position: relative;

        .yzm {
          position: absolute;
          top: 18px;
          right: 52px;
          font-size: 14px;
          font-weight: 400;
          color: #fe6a00;
          cursor: pointer;
        }
      }

      input {
        width: 349px;
        height: 50px;
        background: #f0f8ff;
        box-shadow: 2px 3px 7px 0px rgba(0, 132, 255, 0.05);
        border-radius: 25px;
        outline: none;
        border: none;
        padding-left: 26px;
        margin-bottom: 18px;
      }

      .btn {
        margin-top: 26px;
        width: 375px;
        height: 50px;
        background: #fe6a00;
        border-radius: 25px;
        outline: none;
        border: none;
        font-size: 14px;
        font-weight: bold;
        color: #ffffff;
      }

      .wxfg {
        margin-top: 42px;

        img {
          width: 39px;
          height: 39px;
          margin-left: 25px;
          margin-right: 25px;
        }
      }

      .wuzh {
        margin-top: 36px;
        font-size: 14px;
        font-weight: 400;
        color: #999999;
        span {
          cursor: pointer;
        }
      }
    }
  }

  .cc0 {
    position: absolute;
    top: 40px;
    right: 100px;
    display: block;
    width: 151px;
    height: 50px;
    // background:transparent;
    cursor: pointer;
  }
  .bjt {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -100;
  }
}
</style>

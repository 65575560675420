<template>
  <div class="favq">
    <heand :tagnum="tagnum"></heand>
    <div class="top">{{details.name}}</div>
    <div class="box">
      <div class="base">
        <div class="base_img">
          <!-- <img src="../../assets/industr/detail.jpg" /> -->
          <img :src="details.image" />
        </div>
        <div class="base_right">
          <div class="base_li">
            <div class="base_t">园区级别</div>
            <div class="base_i">{{details.level}}</div>
          </div>
          <div class="base_li">
            <div class="base_t">园区负责人</div>
            <div class="base_i">{{details.charge}}</div>
          </div>
          <div class="base_li">
            <div class="base_t">园区所在地</div>
            <div class="base_i">{{details.address}}</div>
          </div>
          <div class="base_li">
            <div class="base_t">园区集群</div>
            <div class="base_i">{{details.cluster}}</div>
          </div>
          <div class="base_li">
            <div class="base_t">园区类型</div>
            <div class="base_i">{{details.cate}}</div>
          </div>
          <div class="base_li">
            <div class="base_t">联系电话</div>
            <div class="base_i">{{details.phone}}</div>
          </div>
        </div>
      </div>
      <div class="data">
        <div class="data_head">
          <!-- <span class="data_head_1">园区详情</span>
          <span class="data_head_2 active">园区信息</span>
          <span class="data_head_2">投资成本</span>
          <span class="data_head_2">环境配套</span>
          <span class="data_head_2">产业发展</span>
          <span class="data_head_2">园区介绍</span>
          <span class="data_head_2">产业政策</span> -->
        </div>
        <div class="data_box">
          <div class="data_box_head">园区信息</div>
          <div class="data_box_table">
            <div class="table_tr">
              <div class="table_td table_th table_td_w_1">占地面积（平方公里）:</div>
              <div class="table_td table_td_w_2">{{details.detail.acreage}}</div>
              <!-- <div class="table_td table_th table_td_w_1">建成面积:</div>
              <div class="table_td table_td_w_3">{{details.detail.actarea}}</div> -->
               <div class="table_td table_th table_td_w_1">GDP:</div>
              <div class="table_td table_td_w_3">{{details.detail.gdp}}</div>
            </div>
            <div class="table_tr">
              <div class="table_td table_th table_td_w_1">税收要求（万元/亩）:</div>
              <div class="table_td table_td_w_2">{{details.detail.tax}}</div>
              <div class="table_td table_th table_td_w_1">产出强度（万元/亩）:</div>
              <div class="table_td table_td_w_3">{{details.detail.out_power}}</div>
            </div>
            <div class="table_tr">
              <div class="table_td table_th table_td_w_1">投资强度（万元/亩）:</div>
              <div class="table_td table_td_w_2">{{details.detail.invest_power}}</div>
              <div class="table_td table_th table_td_w_1">产业定位:</div>
              <div class="table_td table_td_w_3">{{details.detail.indu_need}}</div>
            </div>
            <!-- <div class="table_tr">
              <div class="table_td table_th table_td_w_1">GDP:</div>
              <div class="table_td table_td_w_2">{{details.detail.gdp}}</div>
              <div class="table_td table_th table_td_w_1">财政收入:</div>
              <div class="table_td table_td_w_3">{{details.detail.fiscal}}</div>
            </div> -->
            <div class="table_tr">
              <div class="table_td table_th table_td_w_1">工业土地均价:</div>
              <div class="table_td table_td_w_2">{{details.detail.indu_land}}</div>
              <!-- <div class="table_td table_th table_td_w_1">工业土地供应量:</div>
              <div class="table_td table_td_w_3">{{details.detail.indu_sup}}</div> -->
              <div class="table_td table_th table_td_w_1">商办土地均价:</div>
              <div class="table_td table_td_w_3">{{details.detail.comm_land}}</div>
            </div>
            <!-- <div class="table_tr">
              <div class="table_td table_th table_td_w_1">商办土地均价:</div>
              <div class="table_td table_td_w_2">{{details.detail.comm_land}}</div>
              <div class="table_td table_th table_td_w_1">商办土地供应量:</div>
              <div class="table_td table_td_w_3">{{details.detail.comm_sup}}</div>
            </div> -->
            <div class="table_tr spacial">
              <div class="table_td table_th table_td_w_1 spacial">代表企业:</div>
              <div class="table_td table_td_w_3 spacial" v-html="details.detail.represent"></div>
            </div>
            <!-- <div v-html="details.detail.represent" class="data_box_content">
              
            </div> --> 
          </div>
        </div>
        <div class="data_box">
          <div class="data_box_head">投资成本</div>
          <div class="data_box_table">
            <div class="table_tr">
              <div class="table_td table_th table_td_w_4">居民用电(一档):</div>
              <div class="table_td table_td_w_5">{{details.invest.resident_ele_one}}</div>
              <div class="table_td table_th table_td_w_4">居民用电(二档):</div>
              <div class="table_td table_td_w_5">{{details.invest.resident_ele_two}}</div>
              <div class="table_td table_th table_td_w_4">居民用电(三档):</div>
              <div class="table_td table_td_w_5">{{details.invest.resident_ele_thr}}</div>
            </div>
            <div class="table_tr">
              <div class="table_td table_th table_td_w_4">商业用电(峰段):</div>
              <div class="table_td table_td_w_5">{{details.invest.comm_ele_one}}</div>
              <div class="table_td table_th table_td_w_4">商业用电(平段):</div>
              <div class="table_td table_td_w_5">{{details.invest.comm_ele_two}}</div>
              <div class="table_td table_th table_td_w_4">商业用电(谷段):</div>
              <div class="table_td table_td_w_5">{{details.invest.comm_ele_thr}}</div>
            </div>
            <div class="table_tr">
              <div class="table_td table_th table_td_w_4">工业电价(峰段):</div>
              <div class="table_td table_td_w_5">{{details.invest.indu_ele_one}}</div>
              <div class="table_td table_th table_td_w_4">工业电价(平段):</div>
              <div class="table_td table_td_w_5">{{details.invest.indu_ele_two}}</div>
              <div class="table_td table_th table_td_w_4">工业电价(谷段):</div>
              <div class="table_td table_td_w_5">{{details.invest.indu_ele_thr}}</div>
            </div>
            <div class="table_tr">
              <div class="table_td table_th table_td_w_4">居民用水(一档):</div>
              <div class="table_td table_td_w_5">{{details.invest.resident_water_one}}</div>
              <div class="table_td table_th table_td_w_4">居民用水(二档):</div>
              <div class="table_td table_td_w_5">{{details.invest.resident_water_two}}</div>
              <div class="table_td table_th table_td_w_4">居民用水(三档):</div>
              <div class="table_td table_td_w_5">{{details.invest.resident_water_thr}}</div>
            </div>
            <div class="table_tr">
              <div class="table_td table_th table_td_w_4">商业用水:XX元/吨</div>
              <div class="table_td table_td_w_5">{{details.invest.comm_water}}</div>
              <div class="table_td table_th table_td_w_4">工业用水:</div>
              <div class="table_td table_td_w_5">{{details.invest.indu_water}}</div>
              <div class="table_td table_th table_td_w_4">特种用水:</div>
              <div class="table_td table_td_w_5">{{details.invest.special_water}}</div>
            </div>
            <div class="table_tr">
              <div class="table_td table_th table_td_w_4">居民用气(一档):</div>
              <div class="table_td table_td_w_5">{{details.invest.resident_natgas_one}}</div>
              <div class="table_td table_th table_td_w_4">居民用气(二档):</div>
              <div class="table_td table_td_w_5">{{details.invest.resident_natgas_two}}</div>
              <div class="table_td table_th table_td_w_4">居民用气(三档):</div>
              <div class="table_td table_td_w_5">{{details.invest.resident_natgas_thr}}</div>
            </div>
            <div class="table_tr">
              <div class="table_td table_th table_td_w_4">污水处理:</div>
              <div class="table_td table_td_w_5">{{details.invest.sewage}}</div>
              <div class="table_td table_th table_td_w_4">最低工资标准:</div>
              <div class="table_td table_td_w_5">{{details.invest.wagelevel}}</div>
              <div class="table_td table_th table_td_w_4">高层管理人员:</div>
              <div class="table_td table_td_w_5">{{details.invest.highmag}}</div>
            </div>
            <div class="table_tr">
              <div class="table_td table_th table_td_w_4">普通员工:</div>
              <div class="table_td table_td_w_5">{{details.invest.worker}}</div>
              <div class="table_td table_th table_td_w_4">中级管理人员:</div>
              <div class="table_td table_td_w_5">{{details.invest.middlemag}}</div>
            </div>
          </div>
        </div>
        <div class="data_box">
          <div class="data_box_head">环境配套</div>
          <div class="data_box_table">
            <div class="table_tr">
              <div class="table_td table_th table_td_w_4">距货运站距离：</div>
              <div class="table_td table_td_w_6">{{details.config.dis_freight}}</div>
            </div>
            <!-- <div class="table_tr">
              <div class="table_td table_th table_td_w_4">距港口距离：</div>
              <div class="table_td table_td_w_6">{{details.config.dis_port}}</div>
            </div> -->
            <div class="table_tr">
              <div class="table_td table_th table_td_w_4">距高铁距离：</div>
              <div class="table_td table_td_w_6">{{details.config.dis_rail}}</div>
            </div>
            <div class="table_tr">
              <div class="table_td table_th table_td_w_4">距机场距离：</div>
              <div class="table_td table_td_w_6">{{details.config.dis_air}}</div>
            </div>
            <div class="table_tr">
              <div class="table_td table_th table_td_w_4">公路运输：</div>
              <div class="table_td table_td_w_6">{{details.config.road_trans}}</div>
            </div>
            <div class="table_tr">
              <div class="table_td table_th table_td_w_4">铁路运输：</div>
              <div class="table_td table_td_w_6">{{details.config.rail_trans}}</div>
            </div>
            <div class="table_tr">
              <div class="table_td table_th table_td_w_4">园区生活配套：</div>
              <div class="table_td table_td_w_6">{{details.config.live_facility}}</div>
            </div>
            <div class="table_tr">
              <div class="table_td table_th table_td_w_4">百货商场：</div>
              <div class="table_td table_td_w_6">{{details.config.market}}</div>
            </div>
            <div class="table_tr">
              <div class="table_td table_th table_td_w_4">酒店商务：</div>
              <div class="table_td table_td_w_6">{{details.config.hotel_bus}}</div>
            </div>
            <div class="table_tr">
              <div class="table_td table_th table_td_w_4">医疗：</div>
              <div class="table_td table_td_w_6">{{details.config.medical}}</div>
            </div>
            <div class="table_tr">
              <div class="table_td table_th table_td_w_4">教育：</div>
              <div class="table_td table_td_w_6">{{details.config.education}}</div>
            </div>
          </div>
        </div>
        <!-- <div class="data_box">
          <div class="data_box_head">产业发展</div>
          <div class="data_box_table">
            <div class="table_tr">
              <div class="table_td table_th table_td_w_4">规模以上工业企业</div>
              <div class="table_td table_td_w_6">{{details.indevo.induenterprise}}</div>
            </div>
            <div class="table_tr">
              <div class="table_td table_th table_td_w_4">创新资源</div>
              <div class="table_td table_td_w_6">{{details.indevo.induenterprise}}</div>
            </div>
            <div class="table_tr">
              <div class="table_td table_th table_td_w_4">双创基地</div>
              <div class="table_td table_td_w_6">{{details.indevo.base}}</div>
            </div>
            <div class="table_tr">
              <div class="table_td table_th table_td_w_4">产业载体</div>
              <div class="table_td table_td_w_6">{{details.indevo.carrier}}</div>
            </div>
          </div>
        </div> -->
        <div class="data_box">
          <div class="data_box_head">园区介绍</div>
          <div class="data_box_content">{{details.intriduce}}</div>
        </div>
        <div class="data_box" style="display:none">
          <div class="data_box_head">产业政策</div>
          <div class="data_box_table">
            <el-table
              :data="tableData"
              header-row-class-name="table_th_bj"
              :header-row-style="back"
              style="width: 100%"
            >
              <el-table-column prop="title" label="名称" width="700"></el-table-column>
              <el-table-column prop="time" label="发文时间"></el-table-column>
              <el-table-column prop="org" label="发文机构"></el-table-column>
            </el-table>
          </div>
        </div>
      </div>
      <div class="map">
        <div class="map_head">地图</div>
        <div class="map_box">
          <baidu-map
            class="bm-view"
            :center="details.jwd"
            :zoom="zoom"
            :double-click-zoom="double"
            :dragging="dragging"
          >
            <bm-marker :position="details.jwd" :dragging="false"></bm-marker>
          </baidu-map>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Carrierdetails } from "@/api/index";
export default {
  name: "zaiti",
  data() {
    return {
      tagnum: 1,
      tableData: [],
      id: "",
      back: { background: "#f3f6fb" },
      details: [],
      zoom: 15,
      double: false,
      dragging: false,
      // center: { lat: "", lng: "" }
      image:""
    };
  },
  components: {},
  mounted() {},
  created() {
    this.id = this.$route.query.id;
    this.getCarrierdetails();
  },
  methods: {
    async getCarrierdetails() {
      const res = await Carrierdetails({
        id: this.id
      });
      console.log(res)
      this.details = res;
    }
  }
};
</script>

<style lang="less" scoped>
.favq {
  width: 100%;
  background: rgb(243, 243, 243);

  li {
    list-style: none;
  }

  .c-top {
    width: 100%;
    height: 72px;
    background: #fff;

    li {
      display: block;
      width: 100px;
      height: 38px;
      background: #eee;
      margin-top: 19px;
      border-radius: 6px;
      margin-right: 19px;
      font-size: 17px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #666666;
      text-align: center;
      line-height: 38px;
      float: left;
      &:nth-child(1) {
        margin-left: 52px;
      }
      &.active {
        background: rgb(254, 147, 34);
        color: #ffffff;
      }
    }
  }
  .top {
    width: calc(100% - 32px);
    height: 72px;
    line-height: 72px;
    background: #fff;
    text-align: left;
    font-size: 24px;
    color: #222222;
    font-weight: 700;
    padding-left: 32px;
  }
  .box {
    padding: 10px 30px 20px 30px;
    .base {
      padding: 20px;
      background: #fff;
      display: flex;
      .base_img {
        width: 43%;
        height: 347px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .base_right {
        width: calc(57% - 60px);
        height: 347px;
        margin-left: 60px;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        .base_li {
          width: 50%;
          padding: 10px 0;
          text-align: left;
          .base_t {
            height: 30px;
            line-height: 30px;
            color: #666;
          }
          .base_i {
            height: 30px;
            line-height: 30px;
            color: #333;
            font-weight: 700;
          }
        }
      }
    }
    .data {
      margin-top: 10px;
      padding: 0 20px 20px 20px;
      background: #fff;
      .data_head {
        height: 74px;
        line-height: 74px;
        display: flex;
        text-align: left;
        font-weight: bold;
        .data_head_1 {
          color: #222;
          font-size: 18px;
          margin-right: 30px;
        }
        .data_head_2 {
          color: #666;
          font-size: 18px;
          margin-right: 40px;
          &.active {
            color: #fe6a00;
          }
        }
      }
      .data_box {
        border: 1px solid #eee;
        margin-bottom: 20px;
        .data_box_head {
          height: 54px;
          line-height: 54px;
          text-align: left;
          color: #fe6a00;
          font-size: 16px;
          font-weight: bold;
          padding-left: 30px;
          position: relative;
          &::after {
            content: "";
            position: absolute;
            left: 17px;
            top: 20px;
            width: 2px;
            height: 13px;
            background: #fe6a00;
          }
        }
        .data_box_table {
          margin: 0 20px 20px 20px;
          border-top: 1px solid #eee;
          /deep/ .table_th_bj {
            background-color: #f3f6fb;
          }
          .table_tr {
            border-bottom: 1px solid #eee;
            width: 100%;
            min-height: 40px;
            line-height: 40px;
            display: flex;
            align-items: center;
            /*align-content: center;*/
            text-align: left;
            &.spacial {
              position: relative;
              justify-content: flex-end;
            }
            .table_td {
              color: #333;
              font-size: 14px;
              box-sizing: border-box;
              padding-left: 26px;
            }
            .table_th {
              background: #f3f6fb;
              color: #333;
              font-size: 14px;
              overflow: hidden;

              &.spacial {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                display: flex;
                align-items: center;
              }
            }
            .table_td_w_1 {
              width: 15%;
            }
            .table_td_w_2 {
              width: 18%;
            }
            .table_td_w_3 {
              width: 52%;
              &.spacial {
                width: 85%;
                padding-top: 14px;
                padding-bottom: 14px;
                line-height: 24px;
              }
            }
            .table_td_w_4 {
              width: 14%;
            }
            .table_td_w_5 {
              width: 18.33%;
            }

            .table_td_w_6 {
              width: 90%;
            }
          }
        }
        .data_box_content {
          line-height: 24px;
          padding: 10px 50px 30px 50px;
          color: #333;
          font-size: 16px;
          text-align: justify;
          text-indent: 2em;
        }
      }
    }
    .map {
      margin-top: 14px;
      padding: 0 20px 20px 20px;
      background: #fff;
      .map_head {
        height: 57px;
        line-height: 57px;

        text-align: left;
        font-weight: bold;

        color: #333;
        font-size: 18px;
      }
      .map_box {
        width: 100%;
        height: 460px;
        border: 1px solid #eee;
      }
    }
  }
}
.bm-view {
  width: 100%;
  height: 100%;
}
</style>

<template>
  <div class="yhhuvhxs">
    <heand></heand>
    <div class="user clearfix">
      <div class="left">
        <li @click="usernav = true" :class="usernav ? 'active' : ''">
          <img
            v-if="usernav == false"
            src="../../assets/user/gerenzhongxinxt.png"
            alt
          />
          <img
            v-if="usernav == true"
            src="../../assets/user/gerenzhongxin.png"
            alt
          />
          个人中心
        </li>
        <li @click="usernav = false" :class="usernav ? '' : 'active'">
          <img
            v-if="usernav == true"
            src="../../assets/user/shoucang1.png"
            alt
          />
          <img
            v-if="usernav == false"
            src="../../assets/user/shoucang1xz.png"
            alt
          />
          我的收藏
        </li>
      </div>
      <div class="right">
        <div v-if="usernav" class="right-yhhv">
          <li>
            <span>姓名</span>
            <el-input v-model="real_name"></el-input>
          </li>
          <li>
            <span>年龄</span>
            <el-input type="text" v-model="age" />
          </li>
          <li>
            <span>性别</span>
            <el-select v-model="sex" placeholder="请选择">
              <el-option label="男" value="1"></el-option>
              <el-option label="女" value="2"></el-option>
            </el-select>
          </li>
          <li>
            <span>手机号</span>
            <el-input type="text" disabled v-model="mobile" />
            <img
              src="../../assets/home/chakan.png"
              @click="(dialogFormVisible = true), (timeshow = 0)"
              class="chakan"
              alt
            />
            <el-dialog title="更改手机号码" :visible.sync="dialogFormVisible">
              <li>
                <span>新手机号:</span>
                <el-input style="width: 80%" type="text" v-model="mobile1" />
              </li>
              <li class="yzm-box">
                <span>验证码:</span>
                <el-input style="width: 80%" type="text" v-model="yzm" />
                <span class="yzm" v-if="timeshow == 0" @click="ser_code"
                  >获取验证码</span
                >
                <span class="yzm" v-if="timeshow == 1" @click="ser_code"
                  >{{ time }}秒后可发送</span
                >
                <span class="yzm" v-if="timeshow == 2" @click="ser_code"
                  >重新发送</span
                >
                <!-- <span class="yzm" @click="ser_code">获取验证码</span> -->
              </li>
              <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="phoneChange">确 定</el-button>
              </div>
            </el-dialog>
          </li>
          <li>
            <span>用户名</span>
            <el-input type="text" v-model="username" />
          </li>
          <li v-if="yxyixia == 2">
            <span>密码</span>
            <el-input
              type="password"
              disabled
              v-model="password"
              placeholder="***********"
            />
            <img
              src="../../assets/home/chakan.png"
              @click="(dialogTableVisible2 = true), (timeshow = 0)"
              class="chakan"
              alt
            />

            <el-dialog title="修改密码" :visible.sync="dialogTableVisible2">
              <li>
                初始密码:
                <el-input
                  type="password"
                  style="width: 80%"
                  v-model="password1"
                />
              </li>
              <li>
                新密码:
                <el-input
                  type="password"
                  style="width: 80%"
                  v-model="password2"
                />
              </li>
              <li>
                确认密码:
                <el-input
                  type="password"
                  style="width: 80%"
                  v-model="password3"
                />
              </li>
              <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="changepassword"
                  >确 定</el-button
                >
              </div>
            </el-dialog>
          </li>
          <li>
            <span>邮箱</span>
            <el-input type="text" disabled v-model="email" />
            <img
              src="../../assets/home/chakan.png"
              @click="(dialogTableVisible1 = true), (timeshow = 0)"
              class="chakan"
              alt
            />

            <el-dialog title="更改邮箱" :visible.sync="dialogTableVisible1">
              <li>
                邮箱:
                <el-input style="width: 80%" type="text" v-model="yxtext" />
              </li>
              <li class="yzm-box">
                验证码:
                <el-input style="width: 80%" type="text" v-model="yxyzm" />
                <span class="yzm" v-if="timeshow == 0" @click="email_code"
                  >获取验证码</span
                >
                <span class="yzm" v-if="timeshow == 1" @click="email_code"
                  >{{ time }}秒后可发送</span
                >
                <span class="yzm" v-if="timeshow == 2" @click="email_code"
                  >重新发送</span
                >
                <!-- <span class="yzm" @click="email_code">获取验证码</span> -->
              </li>
              <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="changeEmail">确 定</el-button>
              </div>
            </el-dialog>
          </li>
          <template v-if="yxyixia == 2">
            <li>
              <span>所在机构</span>
              <el-input type="text" disabled v-model="unit" />
            </li>
            <li>
              <span>职务</span>
              <el-input type="text" disabled v-model="position" />
            </li>
            <li>
              <span>职级</span>
              <el-input type="text" disabled v-model="level" />
            </li>
            <li>
              <span>工作职能</span>
              <el-input
                type="textarea"
                autosize
                placeholder="请输入内容"
                disabled
                v-model="funct"
              ></el-input>
            </li>
          </template>

          <el-button v-if="bianji" @click="bianji = false" class="bgji"
            >编辑</el-button
          >
          <el-button v-if="!bianji" @click="personal" class="bgji"
            >提交</el-button
          >
        </div>
        <div v-else class="right-ubcj">
          <div class="r-top">
            <span :class="fivshow == 2 ? 'active' : ''" @click="xuanzeqiye(2)"
              >行业</span
            >
            <span :class="fivshow == 1 ? 'active' : ''" @click="xuanzeqiye(1)"
              >企业</span
            >
            <el-select
              @change="collect"
              v-if="fivshow == 1"
              v-model="qiye"
              placeholder="请选择"
            >
              <el-option label="太原企业" value="1"></el-option>
              <el-option label="全国企业" value="2"></el-option>
            </el-select>
          </div>
          <template v-if="fivshow == 1">
            <div class="table">
              <li>
                <span>企业名称</span>
                <span>收藏时间</span>
                <span>成立时间</span>
                <span style="color: #222222">操作</span>
              </li>
              <ul v-for="i in list1" :key="i.id">
                <li>
                  <span>
                    <router-link
                      target="_blank"
                      :to="{ name: 'chdetsils9', query: { id: i.id } }"
                      >{{ i.company_name }}</router-link
                    >
                  </span>
                  <span>{{ i.create_time.substr(0, 10) }}</span>
                  <span>{{ i.build_date }}</span>
                  <span @click="ddelete(i.id)">取消收藏</span>
                </li>
              </ul>
            </div>
          </template>
          <template v-if="fivshow == 2">
            <div class="table">
              <li>
                <span>行业名称</span>
                <span>收藏时间</span>
                <span>实体数</span>
                <span style="color: #222222">操作</span>
              </li>
              <ul v-for="i in list1" :key="i.id">
                <li>
                  <span>{{ i.industry_name }}</span>
                  <span>{{ i.create_time.substr(0, 10) }}</span>
                  <span>{{ i.count }}</span>
                  <span @click="ddelete(i.id)">取消收藏</span>
                </li>
              </ul>
            </div>
          </template>

          <div class="block">
            <el-pagination
              @current-change="handleCurrentChange"
              layout="prev, pager, next"
              :total="allpage"
            ></el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  userdetail,
  collect,
  ddelete,
  ser_code,
  phoneChange,
  email_code,
  changeEmail,
  changepassword,
  personal,
} from "@/api/index";

export default {
  data() {
    return {
      dialogFormVisible: false,
      yzm: "",
      dialogTableVisible1: false,
      dialogTableVisible2: false,
      yxyzm: "",
      usernav: true,
      yxtext: "",
      password1: "",
      password2: "",
      password3: "",
      fivshow: 2,
      age: "",
      email: "",
      flag: "",
      funct: "",
      level: "",
      mobile: "",
      mobile1: "",
      position: "",
      real_name: "",
      section: "",
      sex: "",
      unit: "",
      username: "",
      page: 1,
      qiye: "2",
      list1: "",
      allpage: 1,
      yxyixia: 1,
      bianji: true,
      password: "",
      sexid: "",
      timeshow: 0,
      time: "60",
    };
  },
  created() {
    this.getuserdetail();
    this.collect();
  },
  methods: {
    async collect() {
      const ser = await collect({
        page: this.page,
        per_page: 10,
        classify: this.fivshow,
        choice: this.qiye,
      });
      this.allpage = ser.maxnum;
      this.list1 = ser.ob_list;
    },

    handleCurrentChange(val) {
      this.page = val;
      this.collect();
    },

    async ddelete(id) {
      const ser = await ddelete({
        id: id,
        classify: this.fivshow,
        choice: this.qiye,
      });
      this.collect();
    },
    // 密码验证
    allSame(value) {
      let data = value.split("");
      let chartCode = []; // 存放ASCII码
      let same = true;
      for (let i in data) {
        chartCode.push(value.charCodeAt(i));
        if (chartCode.length === data.length) {
          for (let i = 1; i <= chartCode.length; i++) {
            if (Math.abs(Number(chartCode[i]) - Number(chartCode[i - 1])) > 1) {
              same = false;
              break;
            }
          }
        }
      }
      return same;
    },
    // 密码验证
    passMessage(s) {
      var i = /[0-9]+/;
      var str = /[A-Za-z]/;
      var spec = /[,.<>{}~!@#$%^&*]/;
      if (!str.test(s)) {
        return "必须包含字母";
      }
      if (!i.test(s)) {
        return "必须包含数字";
      }
      if (!spec.test(s)) {
        return "必须包含特殊字符。例如：!@#$%^&* 其中一个或多个";
      }
    },
    // 更改密码
    async changepassword() {
      if (this.password1 == "") {
        this.$message({
          message: "请填写原密码",
          type: "error",
        });
        return false;
      }
      if (this.password2 == "") {
        this.$message({
          message: "请填写新密码",
          type: "error",
        });
        return false;
      }
      if (this.password3 == "") {
        this.$message({
          message: "请填写确认新密码",
          type: "error",
        });
        return false;
      }
      let msg = this.passMessage(this.password2);
      if (msg) {
        this.$message({
          message: msg,
          type: "error",
        });
        return false;
      }
      if (this.password2.length < 8) {
        this.$message({
          message: "密码长度应8位以上",
          type: "error",
        });
        return false;
      }
      if (this.allSame(this.password2)) {
        this.$message({
          message: "不能使用连续性字母，数字作为密码",
          type: "error",
        });
        return false;
      }
      if (this.password3 != this.password2) {
        this.$message({
          message: "新密码与确认密码不一致",
          type: "error",
        });
        return false;
      }
      const ser = await changepassword({
        password: this.password1,
        repassword: this.password2,
        newpass: this.password3,
      });
      if (ser.code == 0) {
        this.$message({
          message: ser.msg,
          type: "success",
        });
      } else {
        this.$message({
          message: ser.msg,
          type: "error",
        });
      }
      this.getuserdetail();
    },

    //提交
    async personal() {
      if (this.sex == "1" || this.sex == "男") {
        this.sexid = 1;
      } else {
        this.sexid = 2;
      }
      const ser = await personal({
        real_name: this.real_name,
        age: this.age,
        sex: this.sexid,
        name: this.username,
      });

      if (ser.code == 0) {
        this.$message({
          message: ser.msg,
          type: "success",
        });
      } else {
        this.$message({
          message: ser.msg,
          type: "error",
        });
      }
      this.getuserdetail();

      if (ser.code == "0") {
        this.bianji = true;
      }
    },

    //获取手机验证码
    async ser_code() {
      this.time = 60;
      const ser = await ser_code({
        mobile: this.mobile1,
      });
      if (ser.code == 0) {
        this.$message({
          message: ser.msg,
          type: "success",
        });
        this.timeshow = 1;
        this.countdown();
      } else {
        this.timeshow = 0;
        this.$message({
          message: ser.msg,
          type: "error",
        });
      }
    },

    // 确定更改手机号
    async phoneChange() {
      if (this.mobile1 == "") {
        this.$message({
          message: "请填写手机号",
          type: "error",
        });
        return false;
      }
      const ser = await phoneChange({
        mobile: this.mobile1,
        sms_code: this.yzm,
      });

      if (ser.code == 0) {
        this.$message({
          message: ser.msg,
          type: "success",
        });
      } else {
        this.$message({
          message: ser.msg,
          type: "error",
        });
      }
      this.getuserdetail();
    },

    //获取邮箱验证码
    async email_code() {
      this.time = 60;
      if (this.yxtext == "") {
        this.$message({
          message: "请输入邮箱",
          type: "error",
        });
        return false;
      }
      const ser = await email_code({
        email: this.yxtext,
      });
      if (ser.code == 0) {
        this.$message({
          message: ser.msg,
          type: "success",
        });
        this.timeshow = 1;
        this.countdown();
      } else {
        this.timeshow = 0;
        this.$message({
          message: ser.msg,
          type: "error",
        });
      }
    },
    countdown() {
      var that = this;
      setInterval(function() {
        if (that.time > 0) {
          that.time--;
        } else {
          that.timeshow = 2;
        }
      }, 1000);
    },
    // 确定更改邮箱号
    async changeEmail() {
      if (this.yxtext == "") {
        this.$message({
          message: "请输入邮箱",
          type: "error",
        });
        return false;
      }
      if (this.yxyzm == "") {
        this.$message({
          message: "请输入验证码",
          type: "error",
        });
        return false;
      }
      const ser = await changeEmail({
        email: this.yxtext,
        email_code: this.yxyzm,
      });

      if (ser.code == 0) {
        this.$message({
          message: ser.msg,
          type: "success",
        });
        this.dialogTableVisible1 = true;
      } else {
        this.$message({
          message: ser.msg,
          type: "error",
        });
      }
      this.getuserdetail();
    },

    xuanzeqiye(e) {
      if (e == 1) {
        this.fivshow = 1;
      }
      if (e == 2) {
        this.fivshow = 2;
      }
      this.collect();
    },

    async getuserdetail() {
      const res = await userdetail();
      this.age = res.age;
      this.email = res.email;
      this.flag = res.flag;
      this.funct = res.funct;
      this.level = res.level;
      this.mobile = res.mobile;
      this.position = res.position;
      this.real_name = res.real_name;
      this.section = res.section;
      if (res.sex == 1) {
        this.sex = "男";
      } else {
        this.sex = "女";
      }
      this.unit = res.unit;
      this.username = res.username;
      this.yxyixia = res.flag;
    },
  },
};
</script>

<style lang="less" scoped>
.user {
  width: 100%;
  min-height: 100%;
  padding: 0 30px;
}

.yhhuvhxs {
  min-height: 100%;
  width: 100%;
  background: rgb(243, 243, 243);
  padding-bottom: 30px;

  li {
    list-style-type: none;
  }

  .left {
    width: 170px;
    height: 312px;
    float: left;
    margin: 20px 12px 0 0px;
    background: #fff;
    font-size: 18px;

    li {
      display: flex;
      width: 100%;
      list-style-type: none;
      height: 70px;
      color: #222222;
      cursor: default;
      align-items: center;
      padding: 0 24px;

      &.active {
        color: #fe6a00;
      }

      img {
        vertical-align: middle;
        margin-right: 3px;
      }
    }
  }

  /deep/ .el-dialog {
    width: 50%;
    min-width: 550px;
  }

  .right {
    background: #fff;
    width: calc(100% - 170px - 15px);
    float: left;
    margin-top: 20px;
    padding: 0 10px;
    padding-bottom: 20px;

    .right-yhhv {
      padding-top: 47px;

      li {
        list-style-type: none;
        width: 500px;
        height: 50px;
        line-height: 50px;
        margin-bottom: 25px;

        span {
          display: block;
          float: left;
          width: 125px;
          height: 50px;
          font-size: 14px;
          line-height: 50px;
          padding-left: 65px;
          text-align: left;
        }

        /deep/ .el-input {
          width: 375px;
          height: 50px;
          background: #ffffff;
        }

        /deep/ .el-input__inner {
          border-radius: 25px;
          margin-left: 15px;
        }
      }

      .bgji {
        width: 160px;
        float: left;
        height: 50px;
        background: #fe6a00;
        border-radius: 25px;
        line-height: 30px;
        font-size: 14px;
        font-weight: bold;
        color: #ffffff;
        margin-left: 138px;
      }
    }

    .right-ubcj {
      padding-top: 19px;

      .r-top {
        text-align: left;

        span {
          text-align: center;
          display: block;
          width: 114px;
          height: 34px;
          border-radius: 4px;
          font-size: 18px;
          font-weight: 400;
          color: #fe6a00;
          background: #ffffff;
          border: 1px solid #fe6a00;
          line-height: 34px;
          float: left;
          cursor: pointer;

          &.active {
            background-color: #fe6a00;
            color: #fff;
          }
        }

        span:nth-child(1) {
          margin: 0 19px 0 34px;
        }
      }

      .table {
        width: 100%;
        margin-top: 68px;

        li:nth-child(odd) {
          background: #f3f6fb;
        }

        li {
          width: 97%;
          height: 40px;
          margin-left: 1.5%;
          line-height: 40px;
          color: #222222;

          span {
            display: block;
            width: 25%;
            float: left;
          }

          span:nth-child(4) {
            color: orange;
          }
        }
      }
    }
  }
}

.chakan {
  position: absolute;
  margin-top: 10px;
  margin-left: 25px;
}

/deep/ .el-select {
  float: right;
  // margin-right: 10px;
}

/deep/ .el-textarea {
  width: 70%;

  textarea {
    height: 70px !important;
  }
}
/deep/.el-dialog__footer {
  text-align: center;
}
/deep/.el-dialog__body {
  li {
    margin: 0 auto;
    span {
      width: 80px !important;
      padding: 0 !important;
    }
  }
}
.yzm-box {
  position: relative;
  .yzm {
    position: absolute;
    top: 1%;
    right: 20px;
    display: inline-block;
    width: 90px !important;
    cursor: pointer;
  }
}
</style>

<template>
  <div class="enterprise clearfix">
    <div class="left">
      <navleft v-on:cateid="getcateid" />
    </div>
    <div class="right">
      <div class="check_box">
        <!-- <div class="check_box_li">
          <div class="check_box_title">政策类型：</div>
          <div class="check_box_list">
            <span
              :class="check_type == item.id ? 'active' : ''"
              @click="checktype(item.id, item.title)"
              :key="item.id"
              v-for="item in list_type"
              >{{ item.title }}</span
            >
          </div>
        </div> -->
        <!-- <div class="check_box_li">
          <div class="check_box_title">发布地区：</div>
          <div class="check_box_list">
            <el-select
              class="iipp"
              v-model="Thelevel"
              placeholder="请选择"
              @change="labelchange($event)"
            >
              <el-option
                v-for="(item,index) in optionslevel"
                :key="index"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </div>
        </div> -->
        <div class="check_box_li">
          <div class="check_box_title">发布机构：</div>
          <div class="check_box_list">
            <span
              :class="check_org == item.value ? 'active' : ''"
              @click="checktype2(item.value, item.label)"
              v-for="item in list_org"
              :key="item.id"
              >{{ item.label }}</span
            >
          </div>
        </div>
        <div class="check_box_li" v-if="check_org!=-1">
          <div class="check_box_title"></div>
          <div class="check_box_list">
            <span
              :class="check_orgkid == item.value ? 'active' : ''"
              @click="checktype3(item.value, item.label)"
              v-for="item in orgkid"
              :key="item.id"
              >{{ item.label }}</span
            >
          </div>
        </div>
        <div class="check_box_li">
          <div class="check_box_title">发文时间：</div>
          <div class="check_box_list">
            <el-date-picker
              v-model="valueyear"
              value-format="yyyy"
              @change="startpo"
              type="year"
              placeholder="选择年"
            ></el-date-picker>
          </div>
        </div>
        <div class="check_box_li">
          <div class="check_box_title">搜索：</div>
          <div class="check_box_list">
            <el-input
              placeholder="请输入内容,按回车键确认"
              v-model="input"
              @change="getpolicy"
              @click="getpolicy"
              clearable
            ></el-input>
          </div>
        </div>
      </div>

      <div class="data_box">
        <div
          class="data_li"
          v-for="item in data_list"
          @click="goDetails(item.id)"
        >
          <div class="data_img">
            <img src="../../assets/industr/zhengce.png" alt />
          </div>
          <div class="data_info">
            <div class="data_title">{{ item.name }}</div>
            <div class="data_author">发布机构：{{ item.org }}</div>
            <div class="data_time">{{ item.pubdate }}</div>
          </div>
        </div>

        <div class="fenye">
          <el-pagination
            small
            background
            layout="prev, pager, next"
            :page-size="perpage"
            :total="Number(total)"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          ></el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { policy, certificate, selectArea,areaCategory } from "@/api/index";
export default {
  name: "zhengceBox",
  data() {
    return {
      valueyear: "",
      defaultProps: {
        children: "children",
        label: "label",
      },
      check_type: "",
      check_org: -1,
      check_orgkid: 0,
      check_year: "",
      list_type: [
        {
          id: "1",
          title: "产业规划计划类",
        },
        {
          id: "2",
          title: "产业政策意见类",
        },
        {
          id: "3",
          title: "投资促进政策类",
        },
      ],
      list_org: [
        // {
        //   id: "1",
        //   title: "山西省人民政府"
        // },
        // {
        //   id: "2",
        //   title: "山西省工信厅"
        // },
        // {
        //   id: "3",
        //   title: "山西省投资局"
        // },
        // {
        //   id: "4",
        //   title: "山西省转型综合改革示范管理委员会"
        // }
      ],
      orgkid:[],
      data_list: [],
      category: "",
      input: "",
      perpage: 7,
      page: 1,
      org: "",
      total: "",
      level: "",
    };
  },
  components: {},
  mounted() {},
  created() {
    this.getpolicy();
    this.getcertificate();
    // this.inid = Number(localStorage.getItem("inid"));
  },
  methods: {
    goDetails(id) {
      // let href = this.$router.resolve({
      //   path: "/industrial/zhecedetails",
      //   query: { id: id },
      // });
      // window.open(href.href, "_blank");
      this.$router.push({
        path: "/industrial/zhecedetails",
        query: { id: id },
      });
    },
    // 获取发布机构
    async getcertificate() {
      const res = await selectArea();
      this.list_org = res;
    },
    startpo(val) {
      this.getpolicy();
    },
    handleSizeChange(val) {
      this.page = val;
      this.getpolicy();
    },
    handleCurrentChange(val) {
      this.page = val;
      this.getpolicy();
    },
    getcateid(data) {
      this.cate = data.cate;
      this.inid = data.id;
      this.getpolicy();
    },
    checktype(index, value) {
      if (this.check_type == index) {
        this.check_type = "";
        this.category = "";
      } else {
        this.check_type = index;
        this.category = value;
      }
      this.getpolicy();
    },
    async checktype2(index, value) {
      const res = await areaCategory({
        vau: index,
      });
      
      this.page = 1
      this.check_orgkid = 0;
     
      this.orgkid = res
      if (this.check_org == index) {
        this.check_org = -1;
        this.org = "";
        this.level=""
      } else {
        this.check_org = index;
        this.level = value;
        this.org = res[0].label;

      }
       this.getpolicy();
    },
    checktype3(index, value) {
      if (this.check_orgkid == index) {
        this.check_orgkid = -1;
        this.org = "";
      } else {
        this.check_orgkid = index;
        this.org = value;
      }
      this.getpolicy();
    },
    async getpolicy() {
      const res = await policy({
        inid: this.inid,
        page: this.page,
        perpage: this.perpage,
        // category: this.category,
        org: this.org,
        year: this.valueyear,
        keyword: this.input,
        level: this.level
      });
      this.data_list = res.data;
      this.total = res.size;
    },
  },
};
</script>

<style lang="less" scoped>
.enterprise {
  padding: 0 30px;
  background: rgb(243, 243, 243);
  padding-bottom: 30px;
}

.left {
  float: left;
  margin-top: 20px;
  background: #fff;
  min-width: 170px;

  .dxhj {
    padding-top: 13px;
    width: 100%;
    height: 38px;

    img {
      float: left;
      width: 38px;
      height: 38px;
      margin-left: 13px;
    }

    span {
      float: left;
      line-height: 31px;
      height: 18px;
      font-size: 18px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #222222;
    }
  }

  /deep/ .el-collapse-item__header {
    height: 40px;
    padding-left: 40px;
    font-size: 14px;
  }

  /deep/ .el-collapse-item__header.is-active {
    color: #fe6a00;
  }
}

.right {
  background: #fff;
  /*height: 1196px;*/
  float: right;
  margin-top: 18px;
  width: calc(100% - 256px - 20px);
  min-height: 865px;

  .check_box {
    padding: 20px;
    .check_box_li {
      margin-bottom: 10px;
      text-align: left;
      display: flex;
      .check_box_title {
        width: 90px;
        height: 30px;
        line-height: 30px;
        font-size: 14px;
        color: #666;
      }
      /deep/.el-input {
        margin-left: 10px;
      }
      /deep/.el-input__inner {
        height: 30px;
        line-height: 30px;
      }
      /deep/.el-input__icon {
        line-height: 30px;
      }
      .check_box_list {
        width: calc(100% - 90px);
        display: flex;
        flex-wrap: wrap;
        span {
          display: inline-block;
          height: 30px;
          line-height: 30px;
          font-size: 14px;
          color: #333;
          display: inline-block;
          margin-right: 10px;
          padding: 0 10px;
          border: 1px solid transparent;
          cursor: pointer;
          &.active {
            color: #fe6a00;
            border: 1px solid #fe6a00;
            border-radius: 14px;
          }
        }
        /deep/.el-input__inner {
          width: 240px;
        }
      }
    }
  }

  .data_box {
    padding: 0 20px 20px 20px;
    .data_li {
      border: 1px solid #dddddd;
      height: 94px;
      padding: 7px 15px;
      margin-bottom: 18px;
      box-sizing: border-box;
      display: flex;
      cursor: pointer;
      .data_img {
        width: 80px;
        height: 80px;
        img {
          width: 80px;
          height: 80px;
        }
      }
      .data_info {
        margin-left: 20px;
        width: 100%;
        height: 80px;
        position: relative;
        .data_title {
          height: 40px;
          line-height: 40px;
          text-align: left;
          font-size: 16px;
          font-weight: 400;
          color: #333;
          overflow: hidden;
        }
        .data_author {
          height: 40px;
          line-height: 40px;
          text-align: left;
          font-size: 14px;
          font-weight: 400;
          color: #999;
          overflow: hidden;
        }
        .data_time {
          position: absolute;
          right: 0;
          bottom: 0;
          height: 40px;
          line-height: 40px;
          text-align: left;
          font-size: 14px;
          font-weight: 400;
          color: #999;
        }
      }
    }
  }
  /deep/.el-pagination.is-background .el-pager li:not(.disabled).active {
    border: 1px solid #fe6a00;
    color: #fe6a00;
  }
  /deep/.el-pagination.is-background.el-pagination--small .btn-next,
  /deep/.el-pagination.is-background.el-pagination--small .btn-prev,
  /deep/.el-pagination.is-background.el-pagination--small .el-pager li {
    width: 28px;
    height: 28px;
    line-height: 28px;
    background-color: #fff;
    border: 1px solid #666666;
  }
}
</style>

<template>
  <div class="enterprise_tub">
    <p>机构类型分布统计图</p>
    <div id="main0" style="width:100%;height:300px;"></div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    picture() {
      // 用Dom获取上面已经创建的标签，初始化echarts实例
      let myChart = this.$echarts.init(document.getElementById("main0"));
      // 指定图表的配置项和数据
      let option = {
        tooltip: {
          trigger: "item",
          formatter: "{a} <br/>{b}: {c} ({d}%)"
        },
        legend: {
          bottom:20,
          itemGap: 10,
          itemWidth:8,
          itemHeight:8,
          icon: "rect",
          data: ["机构类型", "企业类型", "叶其类型", "图片类型"]
        },

        series: [
          {
            name: "访问来源",
            type: "pie",
            radius: ["40%", "70%"],
            center: ["50%", "45%"],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: "center"
            },
            emphasis: {
              label: {
                show: true,
                fontSize: "16",
                fontWeight: "bold"
              }
            },
            labelLine: {
              show: false
            },
             itemStyle: {
        normal:{
          color:function(params) {
          //自定义颜色
          var colorList = [          
              '#72DCFE', '#0191FE', '#FCD469', '#9D86FE'
            ];
            return colorList[params.dataIndex]
          }
        }
      },
            data: [
              { value:50, name: "机构类型" },
              { value:15, name: "企业类型" },
              { value:20, name: "叶其类型" },
              { value:20, name: "图片类型" }
            ]
          }
        ],
        backgroundColor: "rgb(243,246,251)"
      };

      // 使用刚指定的配置项和数据显示图表的。
      myChart.setOption(option);
    }
  },
  mounted() {
    this.picture();
  }
};
</script>

<style lang="less" scoped>
.enterprise_tub {
  text-align: left;
  font-size: 18px;
  font-weight: bold;
  color: #333333;
  p{margin-bottom:15px;}
}
</style>
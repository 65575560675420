<template>
  <!--    载体推介-->
  <div class="Industrycarrier clearfix">
    <div class="left">
      <navleft v-on:cateid="getcateid" />
    </div>
    <div class="right">
      <div class="top">
       &nbsp;  园区级别 : &nbsp;&nbsp;
        <el-select class="iipp" style="width:160px;margin:0 10px;" v-model="Thelevel" placeholder="请选择" @change="labelchange($event)">
          <el-option
            v-for="(item,index) in optionslevel"
            :key="index"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
       <!-- &nbsp;&nbsp;   区域类型:  &nbsp;&nbsp;
        <el-select class="iipp" style="width:160px;margin:0 10px;" v-model="clasify" placeholder="请选择" @change="labelchange2($event)">
          <el-option
            v-for="(item,index) in optionsclasify"
            :key="index"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select> -->
        <input v-model="keyword" placeholder=" 搜索园区内容或名称" type="text" />
        <span @click="(page = 1,gotosearch())">搜索</span>
      </div>
      <div class="centerx">
        <div class="zuo">
          <baidu-map
            v-if="showmap"
            class="bm-view"
            :center="center"
            :double-click-zoom="double"
            :zoom="zoom"
          >
            <bm-control>
              <button @click="addZoom()">还原</button>
            </bm-control>
            <bm-label
              v-for="(item,index) in district"
              :key="index"
              :content="item.name"
              :position="item.jwd"
              :labelStyle="{color: 'red', fontSize : '12px'}"
            />
            <bm-marker
              v-for="(item,index) in alilte"
              :key="'one'+ index"
              :position="item.jwd"
              :dragging="false"
              @mouseout="out"
              @mouseover="hover"
              :title="item.name"
            ></bm-marker>
            <bm-boundary
              v-for="item in district"
              :key="item.id"
              :name="item.name"
              :strokeWeight="1"
              strokeColor="blue"
              fillColor="#1F61F8"
              @click="gotocenter(item.jwd,item.name)"
            ></bm-boundary>
          </baidu-map>

          <baidu-map v-if="!showmap" class="bm-view" :center="center2" :zoom="zoom2">
            <bm-control>
              <button @click="addZoom()">还原</button>
            </bm-control>
            <bm-marker
              v-for="item in alilte"
              :key="'alilte'+item.id"
              :position="item.jwd"
              :dragging="false"
              @mouseout="out"
              @mouseover="hover"
            ></bm-marker>
          </baidu-map>
        </div>
        <div class="you">
          <!-- <div class="you-top">
            <span>{{levelnum.count_x}}个</span>
            <span>{{levelnum.count_y}}个</span>
            <span>行政区</span>
            <span>产业园区</span>
          </div> -->
          <div class="you-top2" style="" >
            <div @click="labelchange2" :class="clasify==='行政区'?'active':''"  data-clasify="行政区">
              <span>{{levelnum.count_x}}个</span>
              <span>行政区</span>
            </div>
            <div @click="labelchange2" :class="clasify === '产业园区' ? 'active':''" data-clasify="产业园区">
              <span>{{levelnum.count_y}}个</span>
               <span>产业园区</span>
            </div>
          </div>
          <ul>
            <li v-for="(item,index) in leveldata" :key="index">
              <p class="p1">{{item.name}}</p>
              <p class="p2">园区级别：{{item.level}}</p>
              <p class="p2">园区类型：{{item.cate}}</p>
              <p class="p2">产业集群：{{item.cluster}}</p>
              <p class="p2">地址：{{item.address}}</p>
              <p class="p2">面积：{{item.area}}</p>
              <div style="cursor: pointer;" @click="goDetails(item.id)">详情 ></div>
            </li>
          </ul>
          <div class="fenye">
            <el-pagination
              background
              layout="prev, pager, next"
              :current-page="page"
              :page-size="3"
              :total="Number(total)"
              @current-change="handleCurrentChange"
            ></el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { garden2, map, zonemap } from "@/api/index";
export default {
  name: "Industrycarrier",
  data() {
    return {
      Thelevel: "",
      optionslevel: [
        {
          label: "全部",
          value: ""
        },
        {
          label: "省级",
          value: "省级"
        },
        {
          label: "市级",
          value: "市级"
        },
        {
          label: "区县级",
          value: "区县级"
        },
        {
          label: "企业自建",
          value: "企业自建"
        }
      ],
      clasify: "行政区",
      optionsclasify: [
        {
          label: "行政区",
          value: "行政区"
        },
        {
          label: "产业园区",
          value: "产业园区"
        }
      ],
      leveldata: [],
      levelnum: [],
      inid: "",
      cate: "",
      keyword: "",
      level: "",
      page: 1,
      perpage: 3,
      cate: "1",
      inid: "",
      center: { lng: 112.549, lat: 37.957 }, //经纬度
      zoom: 10, //地图展示级别
      color: "blue",
      num: 130,
      showmap: true,
      district: [],
      show: false,
      center2: "",
      zoom2: "",
      double: false,
      alilte: [],
      total: ""
    };
  },
  created() {
    this.inid = Number(localStorage.getItem("inid"));
    this.gotosearch();
    this.getmap();
    this.getzonemap();
  },
  methods: {
    goDetails(id) {
      // let href = this.$router.resolve({
      //   name: "zaitiDetail",
      //   query: { id: id }
      // });
      // window.open(href.href, "_blank");
      this.$router.push({
        name: "zaitiDetail",
        query: { id: id }
      });
    },
    getcateid(data) {
      this.page = 1
      this.cate = data.cate;
      this.inid = data.id;
      this.getzonemap();
      this.gotosearch();
    },
    labelchange(e) {
      this.page = 1
      this.level = e;
      this.getzonemap();
      this.gotosearch();
    },
     labelchange2(e) {
      //  console.log(e.currentTarget.dataset.clasify)
      //  return false;
      if(this.clasify === e.currentTarget.dataset.clasify ){
        return false;
      }
      this.page = 1
      this.clasify =e.currentTarget.dataset.clasify;
      this.getzonemap();
      this.gotosearch();
    },
    async gotosearch() {
      
      const res = await garden2({
        inid: this.inid,
        cate: this.cate,
        keyword: this.keyword,
        level: this.level,
        page: this.page,
        perpage: this.perpage,
        clasify: this.clasify
      });
      this.leveldata = res.garden;
      this.levelnum = res.df;
      this.total = res.size;
      this.getzonemap();

    },
    async getmap() {
      const res = await map({
        inid: 1,
        cate: "1"
      });
      this.district = res.df;
    },
    async getzonemap(name) {
      const res = await zonemap({
       inid: this.inid,
        cate: this.cate,
        keyword: this.keyword,
        level: this.level,
        page: this.page,
        perpage: this.perpage,
        clasify: this.clasify
      });
      this.alilte = res;
    },
    gotocenter(index, name) {
      this.showmap = false;
      this.center2 = index;
      this.color = "transparent !important";
      this.zoom2 = 13;
      this.getzonemap(name);
    },
    hover() {
      this.show = true;
    },
    out() {
      this.show = false;
    },
    addZoom() {
      this.showmap = true;
      this.zoom = 10;
      this.getzonemap();
    },
    handleCurrentChange(val) {
      this.page = val;
           this.getzonemap();
      this.gotosearch();
    }
  }
};
</script>

<style lang="less" scoped>
.Industrycarrier {
  padding: 0px 30px;
  .left {
    min-width: 170px;
    height: 15%;
    float: left;
    background: #fff;
    min-width: 170px;
    margin-top: 20px;
    .dxhj {
      padding-top: 13px;
      width: 100%;
      height: 38px;
      img {
        float: left;
        width: 38px;
        height: 38px;
        margin-left: 13px;
      }

      span {
        float: left;
        line-height: 31px;
        height: 18px;
        font-size: 18px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #222222;
      }
    }

    /deep/ .el-collapse-item__header {
      height: 40px;
      padding-left: 40px;
      font-size: 14px;
    }

    /deep/ .el-collapse-item__header.is-active {
      color: #fe6a00;
    }
  }

  .right {
    background: #fff;
    width: calc(100% - 256px - 20px);
    float: right;
    margin-top: 20px;
    // min-width: 1125px;
    min-height: 890px;
    .top {
      // width: 80%;
      height: 79px;
      text-align: left;
      font-size: 14px;
      font-weight: 400;
      color: #333333;
      line-height: 79px;
      padding-left: 2%;
      display: flex;
      align-items: center;
      select {
        width: 15%;
        min-height: 31px;
        outline: none;
      }

      input {
        margin-left: 3%;
        // width: 30%;
        outline: none;
        // width: 320px;
        width:250px;
        height: 35px;
        padding: 0 5px;
        background: #ffffff;
        border: 1px solid #dddddd;
      }

      span {
        display: block;
        width: 54px;
        height: 30px;
        background: #fe6a00;
        border-radius: 4px;
        background: #fe6a00;
        color: #fff;
        line-height: 30px;
        text-align: center;
        cursor: pointer;
        margin-left: 5px;
      }
    }

    .centerx {
      width: 100%;
      height: 783px;

      .zuo {
        width: 66%;
        height: 783px;
        float: left;
        padding: 0 10px;

        img {
          width: 100%;
        }
      }

      .you {
        float: left;
        width: 28%;
        // height: 783px;
        height:940px;
        margin-left: 26px;
        position: relative;

        .you-top2{
          display:flex;justify-content:space-around;
           height: 84px;
           margin-bottom: 21px;
          // align-items: center;
          div{
            width:50%;
            text-align:center;
            // background-color:red;
            display:flex;
            flex-direction: column;
            border:1px solid #17a8fe;
            // color: #ffffff;
            font-size: 20px;
            font-family: Source Han Sans CN;
            font-weight: bold;
            align-items:center;
            justify-content: center;
            cursor:pointer;
            &:first-child{
              border-top-left-radius: 10px;
              border-bottom-left-radius: 10px;
            }
            &:last-child{
              border-top-right-radius: 10px;
              border-bottom-right-radius: 10px;
            }
          }
          .active{
            background-color:#17a8fe;
            color: #ffffff;
            cursor:default;

          }

        }
        .you-top {
          width: 100%;
          height: 84px;
          background: #17a8fe;
          border-radius: 10px;
          margin-bottom: 21px;

          span {
            float: left;
            display: block;
            width: 30%;
            height: 20px;
            color: #ffffff;
            font-size: 20px;
            font-family: Source Han Sans CN;
            font-weight: bold;
            text-align: left;
          }

          span:nth-child(1),
          span:nth-child(3) {
            margin-left: 10%;
          }

          span:nth-child(2),
          span:nth-child(4) {
            width: 55%;
          }

          span:nth-child(1),
          span:nth-child(2) {
            margin-bottom: 20px;
            padding-top: 15px;
          }

          span:nth-child(3),
          span:nth-child(4) {
            font-size: 15px;
          }
        }

        ul {
          li {
            list-style-type: none;
            width: 98%;
            margin-left: 1%;
            min-height: 189px;
            border: 1px solid #eeeeee;
            margin-bottom: 9px;
            p {
              display: block;
              width: 90%;
              min-height: 17px;
              font-size: 14px;
              line-height: 17px;
              font-weight: bold;
              text-align: left;
              margin-left: 3%;
              padding-left: 3%;
              color: #333333;
              padding-left: 3%;
              overflow: hidden;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 2;
              display: -webkit-box;
              margin-bottom: 8px;
            }

            .p1 {
              height: 17px;
              font-size: 16px;
              line-height: 17px;
              font-family: Microsoft YaHei;
              font-weight: bold;
              color: #fe6a00;
              text-align: left;
              border-left: 2px solid #fe6a00;
              margin: 17px 0 19px 3%;
              padding-left: 3%;
            }
            div {
              width: 84%;
              height: 33px;
              background: #fff3eb;
              margin-left: 6%;
              margin-top: 23px;
              line-height: 33px;
            }
          }
        }
      }
    }
  }
}
.bm-view {
  width: 100%;
  height: 100%;
  /deep/.BMapLabel {
    background-color: transparent !important;
    border: 0 !important;
    color: rgb(70, 17, 17) !important;
  }
}
.fenye {
  position: absolute;
  bottom: 0px;
  left: 50%;
  transform: translateX(-50%);
}
</style>

<template>
  <div>
    <div class="head">
      <div class="bnti">太原市重点产业招商图谱平台</div>
      <div class="center">
        <ul>
          <li
            :class="tagnum == 0 ? 'active' : ''"
            @click="$router.push({ name: 'Thecockpit' })"
          >
            <div>
              <img src="../assets/header/nav1.png" alt />
            </div>
            <span>招商驾驶舱</span>
          </li>
          <li
            :class="tagnum == 1 ? 'active' : ''"
            @click="$router.push({ name: 'favq' })"
          >
            <div>
              <img src="../assets/header/nav2.png" alt />
            </div>
            <span>产业现状图谱</span>
          </li>
          <li
            :class="tagnum == 2 ? 'active' : ''"
            @click="$router.push({ name: 'dynamic' })"
          >
            <div>
              <img src="../assets/header/nav3.png" alt />
            </div>
            <span>产业招商雷达</span>
          </li>
          <li
            :class="tagnum == 3 ? 'active' : ''"
            @click="$router.push({ name: 'investmentmap' })"
          >
            <div>
              <img src="../assets/header/nav4.png" alt />
            </div>
            <span>产业招商地图</span>
          </li>
          <li
            :class="tagnum == 4 ? 'active' : ''"
            @click="$router.push({ name: 'selectionaddpinggu' })"
          >
            <div>
              <img src="../assets/header/nav5.png" alt />
            </div>
            <span>智能选址评估</span>
          </li>
          <li
            :class="tagnum == 5 ? 'active' : ''"
            @click="$router.push({ name: 'portrait' })"
          >
            <div>
              <img src="../assets/header/nav6.png" alt />
            </div>
            <span>360企业画像</span>
          </li>
          <!-- @click="$router.push({ name: 'quyugaunliindex' })" -->

          <li
            :class="tagnum == 7 ? 'active' : ''"
            @click="$router.push({ name: 'projectgl' })"
          >
            <div>
              <img src="../assets/header/nav8.png" alt />
            </div>
            <span>招商资源管理</span>
          </li>
          <!-- <li
            :class="tagnum == 6 ? 'active' : ''"
            @click="$router.push({ name: 'alifuneng' })"
          >
            <div>
              <img src="../assets/header/nav7.png" alt />
            </div>
            <span>阿里云赋能</span>
          </li> -->
          <!-- <li
            :class="tagnum == 6 ? 'active' : ''"
           @click="flag == 2 || flag == 3?jumpnewwww():quexian()"
          >
            <div>
              <img src="../assets/header/nav7.png" alt />
            </div>
            <span>中博会</span>
          </li> -->
        </ul>
      </div>

      <div class="right">
        <div @click="$router.push({ name: 'yhhuvhxs' })">
          <img src="../assets/login/yhhu.png" alt />
          <span>用户中心</span>
        </div>
        <div @click="exitlogin()">
          <img src="../assets/login/tkdd.png" alt />
          <span>退出登录</span>
        </div>
      </div>
    </div>
    <!-- <p style="background: #02044a;text-align:left;color:#fff;font-size:18px;position: absolute;
    left: 52px;top: 73px;">投资服务热线：96301转0351</p> -->
    <div class="head2"></div>
  </div>
</template>

<script>
import store from "../store";
export default {
  name: "heandtop",
  data() {
    return {};
  },
  props: ["tagnum"],
  created() {},
  methods: {
    quexian() {
      this.$message("暂无权限");
    },
    jumpnewwww() {
      console.log(9999);
      this.$router.push({ name: "alifuneng" });
      // window.open('http://gs.sxjx.wang/chuangke/',"_blank");
      window.open("http://www.expocentralchina.org.cn/", "_blank");
    },
    exitlogin() {
      localStorage.setItem("login", "");
      // console.log(this.$store);
      this.$store.commit("setFlag", 0);
      this.$router.replace({ path: "/" });
    },
  },
};
</script>

<style lang="less" scoped>
.head2 {
  height: 100px;
}
.head {
  position: fixed;
  z-index: 1999;
  width: 100%;
  height: 100px;
  min-width: 1290px;
  background: #fe6a00;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .bnti {
    font-size: 24px;
    color: #ffffff;
    width: 230px;
    flex-wrap: nowrap;
    white-space: nowrap;
    margin-left: 30px;
  }

  .center {
    // width: 1000px;
    height: 100%;
    ul {
      height: 100%;
      display: flex;
      align-items: center;
    }

    li {
      display: block;
      width: 110px;
      list-style-type: none;
      float: left;
      cursor: default;
      div {
        width: 100%;
        height: 50px;
        display: flex;
        align-items: flex-end;
        justify-content: center;
      }
      img {
        // width: 20px;
        // height: 20px;
        // padding-left: 39px;
      }

      span {
        display: block;
        width: 100%;
        height: 35px;
        color: #ffefe4;
        font-size: 16px;
        margin-top: 10px;
      }
    }
    li {
      cursor: pointer;
    }
    li.active {
      cursor: default;
      span {
        font-weight: bold;
      }
      &:after {
        display: block;
        content: "";
        width: 28px;
        height: 4px;
        background: #ffffff;
        border-radius: 2px;
        margin: 0 auto;
        // margin-top: 10px;
      }
    }
  }
  .right {
    margin-right: 30px;
    width: 240px;
    height: 42px;
    background: rgb(254, 121, 26);
    flex-wrap: nowrap;
    flex-shrink: 0;
    display: flex;
    cursor: pointer;
    div {
      width: 50%;
    }
    img {
      width: 15px;
      height: 15px;
      line-height: 42px;
    }
    span {
      line-height: 42px;
      font-size: 12px;
      font-weight: 400;
      color: #ffffff;
      padding-right: 6px;
      white-space: nowrap;
    }
  }
}
@media (max-width: 1366px) {
  .head .bnti {
    font-size: 20px !important;
  }
}
</style>
